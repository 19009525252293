const initialState = {
  isData: false,
  vehiculo: [],
  selVehiculo: null,
  pagination: null,
};

const initialVehiculoState = {
  selVehiculo: null,
};

export const vehiculo = (state = initialState, action) => {
  switch (action.type) {
    case 'setVehiculo': {
      if (action.value.status === 200) {
        const stateData = {
          vehiculo: action.value.data,
          isData: true,
          pagination: action.value.pagination,
        };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setVehiculoInit': {
      return initialState;
    }
    case 'setSelVehiculo': {
      const obj = {
        ...action.value,
      };

      const stateData = { selVehiculo: obj };

      return { ...state, ...stateData };
    }
    case 'setSelVehiculoInit': {
      return { ...state, ...initialVehiculoState };
    }

    default:
      //
      return state;
  }
};

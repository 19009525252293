import React, { useState, useEffect } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import { CustomToast, OkToast } from '../../Toast';
import { fixDateForBE } from '../../../../utils/format';
import { ButtonTable } from '../../../global/Styles/buttons';
import Select from 'react-select';
import { hideLoadingAnimation, showLoadingAnimation } from '../../../../actions';
import { inputsSettingsTrue, inputsSettingsFalse, inputSelectOk, inputSelectError } from '../../../global/Styles/inputs';

export const ModalAgregarCentroOperacion = (props) => {
  const { setModalModalAgregarCentroOperacion } = props;
  const { validate } = props.agregarCentroOperacion;
  const { createCentroOperacion, updateCentroOperacion } = props.endPoint;
  const storeState = store.getState();
  const { post, get, put } = useApiClient();

  const editMode = storeState.CentroOperacion.selCentroOperacion === null ? false : true;

  const proxyTask = async (form) => {
    if (editMode) updateCentroOperacion(form);
    else createCentroOperacion(form);
  };

  const [lugarOptions, setLugarOptions] = useState([]);

  const getLugar = async () => {
    store.dispatch(showLoadingAnimation());

    try {
      const response = await get(`/municipio`);
      const lst = response.data.map((row) => ({
        label: `${row.MUNICIPIO_DESCRIPCION}`,
        value: row.MUNICIPIO_CODIGO,
      }));

      //
      setLugarOptions(lst);
    } catch (error) {}

    store.dispatch(hideLoadingAnimation());
  };

  const [regional, setRegional] = useState([]);

  const getRegional = async () => {
    store.dispatch(showLoadingAnimation());

    try {
      const response = await get(`/regional`);

      const lst = response.data.map((row) => ({
        label: `${row.REGIONAL_NOMBRE}`,
        value: row.REGIONAL_ID,
      }));

      //
      setRegional(lst);
    } catch (error) {}

    store.dispatch(hideLoadingAnimation());
  };

  const setREGIONAL_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['REGIONAL_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setMUNICIPIO_CODIGO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['MUNICIPIO_CODIGO'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  useEffect(() => {
    getLugar();
    getRegional();
  }, []);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        onSubmit={proxyTask}
        validate={validate}
        initialValues={editMode ? storeState.CentroOperacion.selCentroOperacion : { CENTRO_ESTADO: 1 }}
        subscription={{ submitting: true, pristine: true }}
        mutators={{ setREGIONAL_ID, setMUNICIPIO_CODIGO }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <h2 className="py-4 text-xl ">{editMode ? 'Editar' : 'Nuevo'} Centro de operaciones</h2>

                  <FormStateToRedux form="frmAgregarRol" />
                  <div>
                    <div>
                      <Field name="CENTRO_ID">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Código</p>
                            <input
                              {...input}
                              type="text"
                              placeholder=""
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <p className="mt-3 text-sm text-gray-800">Regional</p>

                      {/* +++ SELECT +++ */}

                      <Field name="REGIONAL_ID">
                        {({ input, meta }) => (
                          <>
                            {regional.length > 0 ? (
                              <Select
                                options={regional}
                                defaultValue={regional.find((i) => i.value === storeState.CentroOperacion.selCentroOperacion?.REGIONAL_ID)}
                                name="selectRegional"
                                onChange={form.mutators.setREGIONAL_ID}
                                className={meta.error && meta.touched ? `${inputSelectError}` : `${inputSelectOk}`}
                              />
                            ) : (
                              <div className=" flex items-center justify-center ">
                                <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                              </div>
                            )}
                            <input
                              {...input}
                              type="hidden"
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                      {/* --- SELECT --- */}
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <p className="mt-3 text-sm text-gray-800">Municipio</p>

                      {/* +++ SELECT +++ */}

                      <Field name="MUNICIPIO_CODIGO">
                        {({ input, meta }) => (
                          <>
                            {lugarOptions.length > 0 ? (
                              <Select
                                options={lugarOptions}
                                defaultValue={lugarOptions.find((i) => i.value === storeState.CentroOperacion.selCentroOperacion?.MUNICIPIO_CODIGO)}
                                name="selectMUNICIPIO_CODIGO"
                                onChange={form.mutators.setMUNICIPIO_CODIGO}
                                className={meta.error && meta.touched ? `${inputSelectError}` : `${inputSelectOk}`}
                              />
                            ) : (
                              <div className=" flex items-center justify-center ">
                                <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                              </div>
                            )}
                            <input
                              {...input}
                              type="hidden"
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                      {/* --- SELECT --- */}
                    </div>
                  </div>
                  <div>
                    <div>
                      <Field name="CENTRO_NOMBRE">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Centro</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="nombre"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Field name="CENTRO_DIRECCION">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Dirección</p>
                            <input
                              {...input}
                              type="text"
                              placeholder=""
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalModalAgregarCentroOperacion(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    {editMode ? 'Actualizar' : 'Agregar'}
                  </ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = function (state) {
  return { CentroOperacion: state.CentroOperacion };
};

//export default Projects;
export default connect(mapStateToProps)(ModalAgregarCentroOperacion);

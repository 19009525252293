const initialState = {
  isData: false,
  data: [],
  selCliente: null,
  pagination: null,
};

const initialClienteState = {
  selCliente: null,
};

export const Cliente = (state = initialState, action) => {
  switch (action.type) {
    case 'setCliente': {
      if (action.value.status === 200) {
        const stateData = {
          data: action.value.data,
          isData: true,
          pagination: action.value.pagination,
        };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setClienteInit': {
      return initialState;
    }
    case 'setSelCliente': {
      const obj = {
        ...action.value,
      };

      const stateData = { selCliente: obj };

      return { ...state, ...stateData };
    }
    case 'setSelClienteInit': {
      return { ...state, ...initialClienteState };
    }

    default:
      return state;
  }
};

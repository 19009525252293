import React from 'react';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/Contrato/Filter';
import { ModalAgregarContrato } from '../../../components/specific/Contrato/Modales/agregarContrato';
import TableContrato from '../../../components/specific/Contrato/Table';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';
import { store } from '../../../store';
import { setSelContratoInit } from '../../../actions';
import PageHeading from '../../../components/global/PageHeading';
import { ButtonTable } from '../../../components/global/Styles/buttons';

const ViewContrato = (props) => {
  const { modalAgregarContrato, setModalAgregarContrato } = props.modal;
  const { createContrato, validate, updateContrato } = props.endPoint;
  const { getRoles } = props;
  const { loadingTables } = props.actions;

  const handleClick = () => {
    store.dispatch(setSelContratoInit());
    setModalAgregarContrato(true);
  };

  return (
    <div>
      <Nav />
      <Toaster />
      <div className="sm:px-6 lg:px-4 mx-6 py-12">
        {/* <Filter setModalAgregarContrato={setModalAgregarContrato} data={props.data} endPoint={props.endPoint} /> */}
        <PageHeading title="CLIENTE CONTRATOS / MODO DE OPERACIÓN">
          <ButtonTable onClick={handleClick}>Agregar</ButtonTable>
        </PageHeading>
        <div className="mt-3 overflow-hidden rounded border-b border-gray-200 shadow">
          {loadingTables ? (
            <LoadingTables />
          ) : (
            <TableContrato
              actions={props.actions}
              endPoint={props.endPoint}
            />
          )}
        </div>
      </div>
      {modalAgregarContrato ? (
        <ModalAgregarContrato
          endPoint={{ createContrato, validate, updateContrato }}
          setModalAgregarContrato={setModalAgregarContrato}
          getRoles={getRoles}
        />
      ) : null}
    </div>
  );
};

export default ViewContrato;

import React, { useState } from 'react';

import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';

import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import TableManifiesto from './TableManifiesto';

import { ModalTitle } from '../../../global/Styles/titles';
import { PanelVehiculoTarifa } from './ModalSeguimiento/PanelVehiculoTarifa';
import { formatterPeso, roundWithDecimals, getFormatedNumberFromInput, removeMoneyFormat } from '../../../../utils/format';
import { PanelVehiculoAnticipos } from './ModalSeguimiento/PanelVehiculoAnticipos';
import { ModalAnticipoSiesaLog } from './ModalAnticipoSiesaLog';

const VehiculoLabel = ({ data }) => {
  const onlyOwnerData = {
    propietario: data.V_TERCERO_PROPIETARIO_NOMBRE,
    tenedor: data.V_TERCERO_TENEDOR_NOMBRE,
    propio: data.V_VEHICULO_PROPIO,
  };

  const vehiculoData = {
    isOurAsset: onlyOwnerData.propio === 1,
    isOurAssetText: onlyOwnerData.propio === 1 ? 'Propio' : 'Ext.',
    propietario: onlyOwnerData.propietario,
    tenedor: onlyOwnerData.tenedor,
    externoText: onlyOwnerData.tenedor ? '(T)' : '(P)',
    externoName: onlyOwnerData.tenedor ? onlyOwnerData.tenedor : onlyOwnerData.propietario,
  };

  return (
    <span className={`${vehiculoData.isOurAsset ? 'text-green-600' : 'text-red-600'} `}>
      {`${vehiculoData.isOurAssetText || '...'} ${(!vehiculoData.isOurAsset && vehiculoData.externoText) || ''} ${
        (!vehiculoData.isOurAsset && vehiculoData.externoName) || ''
      }`}
    </span>
  );
};

const TrailerLabel = ({ data }) => {
  const onlyOwnerData = {
    propietario: data.T_TERCERO_PROPIETARIO_NOMBRE,
    tenedor: data.T_TERCERO_TENEDOR_NOMBRE,
    propio: data.T_VEHICULO_PROPIO,
  };

  const vehiculoData = {
    isOurAsset: onlyOwnerData.propio === 1,
    isOurAssetText: onlyOwnerData.propio === 1 ? 'Propio' : 'Ext.',
    propietario: onlyOwnerData.propietario,
    tenedor: onlyOwnerData.tenedor,
    externoText: onlyOwnerData.tenedor ? '(T)' : '(P)',
    externoName: onlyOwnerData.tenedor ? onlyOwnerData.tenedor : onlyOwnerData.propietario,
  };

  return (
    <span className={`${vehiculoData.isOurAsset ? 'text-green-600' : 'text-red-600'} `}>
      {`${vehiculoData.isOurAssetText || '...'} ${(!vehiculoData.isOurAsset && vehiculoData.externoText) || ''} ${
        (!vehiculoData.isOurAsset && vehiculoData.externoName) || ''
      }`}
    </span>
  );
};

const ConductorLabel = ({ data }) => {
  const employeeData = {
    isEmployee: data.CONDUCTOR_ES_EMPLEADO === 1,
    isEmployeeMsg: data.CONDUCTOR_ES_EMPLEADO === 1 ? 'Empleado' : 'Externo',
  };
  return <span className={`${employeeData.isEmployee ? 'text-green-600' : 'text-red-600'} `}>{`${employeeData.isEmployeeMsg || '...'} `}</span>;
};

const isConductorEmployee = (data) => {
  return data.CONDUCTOR_ES_EMPLEADO === 1;
};
const AparejadorLabel = ({ data }) => {
  const employeeData = {
    isEmployee: data.APAREJADOR_ES_EMPLEADO === 1,
    isEmployeeMsg: data.APAREJADOR_ES_EMPLEADO === 1 ? 'Empleado' : 'Externo',
  };
  return <span className={`${employeeData.isEmployee ? 'text-green-600' : 'text-red-600'} `}>{`${employeeData.isEmployeeMsg || '...'} `}</span>;
};

const isAparejadorEmployee = (data) => {
  return data.APAREJADOR_ES_EMPLEADO === 1;
};

export const ModalAnticipoConsulta = (props) => {
  const { setIsModalAnticipoConsultaVisible } = props;

  const { registrarAnticipo } = props.endPoint;
  const { handleChangeTarifaUnidad, handleAddTarifa } = props.actions;

  const selTipoVehiculo = props.data.tipoVehiculo.selTipoVehiculo;

  const handleClickCerrar = () => {
    setIsModalAnticipoConsultaVisible(false);
  };

  const [isModalAnticipoSiesaLogVisible, setIsModalAnticipoSiesaLogVisible] = useState(false);
  const [siesaModalData, setSiesaModalData] = useState({});

  const handleShowSiesaLog = (row, isSendFail, refrestVehiculoAnticipos) => {
    setSiesaModalData({
      row,
      isSendFail,
      refrestVehiculoAnticipos,
    });
    setIsModalAnticipoSiesaLogVisible(true);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>ANTICIPOS - CONSULTA</ModalTitle>
        <div className="flex-auto justify-center p-1 text-left">
          <TableManifiesto />
        </div>

        <div className="px-5">
          <hr className="mt-1" />
          <PanelVehiculoAnticipos
            handleAddTarifa={handleAddTarifa}
            handleChangeTarifaUnidad={handleChangeTarifaUnidad}
            handleShowSiesaLog={handleShowSiesaLog}
            data={selTipoVehiculo}
            endPoint={props.endPoint}
          />
        </div>
        <div className="w-full space-x-4">
          <div className="md:block mx-16 mt-2 space-x-4 text-center">
            <ButtonTable onClick={handleClickCerrar}>Cerrar</ButtonTable>
          </div>
        </div>
      </div>
      {isModalAnticipoSiesaLogVisible && (
        <ModalAnticipoSiesaLog
          data={siesaModalData}
          setIsModalAnticipoSiesaLogVisible={setIsModalAnticipoSiesaLogVisible}
          endPoint={props.endPoint}
        />
      )}
    </div>
  );
};

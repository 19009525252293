import React from 'react';
import TbodyTableParametroGeneral from './tbody';
import TheadTableParametroGeneral from './thead';
const TableParametroGeneral = (props) => {
  const { loadingTables } = props.data;
  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="w-full table-auto text-sm">
          <TheadTableParametroGeneral />
          <TbodyTableParametroGeneral
            actions={props.actions}
            data={props.data}
          />
        </table>
      )}
    </div>
  );
};
export default TableParametroGeneral;

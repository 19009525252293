import React from 'react';
import './styles.css';
const LoadingBoard = () => {
  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-gray-800"></div>
      <span className="loader"></span>
    </div>
  );
};
export default LoadingBoard;

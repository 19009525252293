import React, { useState, useEffect } from 'react';
import ViewCentroOperacion from './ViewCentroOperacion';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setCentroOperacion, setSelCentroOperacion, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const CentroOperacion = (props) => {
  const { get, post, put, DELETE } = useApiClient();
  const [loadingTables, setLoadingTables] = useState(false);
  const [modalAgregarCentroOperacion, setModalModalAgregarCentroOperacion] = useState(false);

  const getCentroOperacion = async () => {
    setLoadingTables(true);
    try {
      const result = await get(`/centroOperacion`);

      store.dispatch(setCentroOperacion(result));
    } catch (error) {}
    setLoadingTables(false);
  };

  const createCentroOperacion = async (form) => {
    try {
      const result = await post(
        '/centroOperacion',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalModalAgregarCentroOperacion(false);
        OkToast({ result, message: 'Centro de Operación Agregada!' });
        getCentroOperacion();
      }
    } catch (error) {}
  };

  const updateCentroOperacion = async (form) => {
    try {
      const result = await put(
        `/centroOperacion/${form.CENTRO_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalModalAgregarCentroOperacion(false);
        OkToast({ result, message: 'Centro de Operación Actualizada!' });
        getCentroOperacion();
      }
    } catch (error) {}
  };

  const updateEstadoCentroOperacion = async (form) => {
    try {
      const result = await put(
        `/centroOperacion/${form.CENTRO_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );
      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        getCentroOperacion();
      }
    } catch (error) {}
  };

  function handleEdit(obj) {
    console.log('handleEdit: ', obj);

    store.dispatch(setSelCentroOperacion(obj));
    setModalModalAgregarCentroOperacion(true);
  }
  const deleteCentroOperacion = async (rowID) => {
    store.dispatch(showLoadingAnimation());
    try {
      const result = await DELETE(`/centroOperacion/${rowID}`);
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Centro de Operación Eliminado!' });
        getCentroOperacion();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }
    store.dispatch(hideLoadingAnimation());
  };

  function handleDeleteCentroOperacion(rowID) {
    toast((t) => (
      <span>
        <b>¿Está seguro de querer eliminar CentroOperacion?</b>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => {
            toast.dismiss(t.id);
            deleteCentroOperacion(rowID);
          }}>
          Sí
        </button>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </span>
    ));
  }

  function handleEstado(obj) {
    obj.CENTRO_ESTADO = obj.CENTRO_ESTADO == 1 ? 0 : 1;
    updateEstadoCentroOperacion(obj);
  }

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['CENTRO_NOMBRE', 'CENTRO_ID', 'MUNICIPIO_CODIGO', 'REGIONAL_ID'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  useEffect(() => {
    getCentroOperacion();
  }, []);

  return (
    <ViewCentroOperacion
      endPoint={{ getCentroOperacion, createCentroOperacion, updateCentroOperacion }}
      modal={{
        modalAgregarCentroOperacion,
        setModalModalAgregarCentroOperacion,
        validate,
        createCentroOperacion,
      }}
      actions={{ loadingTables, handleDeleteCentroOperacion, handleEdit, handleEstado }}
    />
  );
};

const mapStateToProps = function (state) {
  return { CentroOperacion: state.CentroOperacion };
};

//export default Projects;
export default connect(mapStateToProps)(CentroOperacion);

import { TableVehiculoAnticipoTbody } from './TableVehiculoAnticipoTbody';

import { TableVehiculoAnticipoThead } from './TableVehiculoAnticipoThead';

export const TableVehiculoAnticipo = (props) => {
  return (
    <table className="w-full table-auto text-sm">
      <TableVehiculoAnticipoThead
        modals={props.modals}
        actions={props.actions}
      />
      <TableVehiculoAnticipoTbody
        data={props.data}
        actions={props.actions}
        endPoint={props.endPoint}
      />
    </table>
  );
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Trash } from '../../../assets/svg/Trash.svg';
import { ReactComponent as IconOn } from '../../../assets/svg/switch-on.svg';
import { ReactComponent as IconOff } from '../../../assets/svg/switch-off.svg';
import { fixOnlyDateForTable, moneyFormat } from '../../../../utils/format';
const TbodyTableRoles = (props) => {
  const infoContrato = props.contrato.contrato;
  const { handleEdit, handleDelete, handleEstado } = props.actions;

  useEffect(() => {}, []);

  return (
    <tbody className="text-gray-700">
      {infoContrato.map((contrato, index) => (
        <tr key={index}>
          <td className="px-4 py-3 text-left">{contrato.CONTRATO_ID}</td>
          <td className="px-4 py-3 text-right">{contrato.CONTRATO_NUMERO}</td>
          <td className="px-4 py-3 text-left">{contrato.OPERACION_MODO_NOMBRE}</td>
          <td className="px-4 py-3 text-right">{moneyFormat(contrato.CONTRATO_PRESUPUESTO)}</td>

          <td className=" px-4 py-3 text-left">{fixOnlyDateForTable(contrato.CONTRATO_FECHA_INICIO)}</td>
          <td className=" px-4 py-3 text-left">{fixOnlyDateForTable(contrato.CONTRATO_FECHA_FINAL)}</td>

          <td className="px-4 py-3 text-left">
            <a className="hover:text-blue-500">{fixOnlyDateForTable(contrato.UPDATED_AT)}</a>
          </td>
          <td className="px-4 py-3 text-left">{fixOnlyDateForTable(contrato.CREATED_AT)}</td>
          <td className="px-4 py-3 text-left">
            {contrato.CONTRATO_ESTADO === 1 ? (
              <IconOn
                className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
                onClick={() => handleEstado(contrato)}
              />
            ) : (
              <IconOff
                className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
                onClick={() => handleEstado(contrato)}
              />
            )}
          </td>
          <td className="flex space-x-2 px-4 py-3 text-left">
            <Edit
              className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleEdit(contrato)}
            />
            <Trash
              className="hover:fill-blue-500 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleDelete(contrato.CONTRATO_ID)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { contrato: state.contrato };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableRoles);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const TbodyTableSolicitudLogsResumen = (props) => {
  const data = props.Solicitud.selSolicitudLog;
  if (data && data[0]) {
    const rowTipoVehiculo = data[0].vehiculo_tipo;

    return (
      data && (
        <tbody className="rounded-lg text-gray-700">
          {[data[0]].map((row) => {
            return (
              <tr>
                <td className=" px-2 py-2 text-left">{row?.SOLICITA}</td>
                <td className=" px-2 py-2 text-left">{row?.APRUEBA}</td>
                <td className=" px-2 py-2 text-left">{row?.SOLICITUD_DESCRIPCION}</td>
                <td className=" px-2 py-2 text-left">
                  <td className="px-2 py-2 text-left">{`${row?.SOLICITUD_IZAJE === 1 ? 'Izaje' : ''} ${
                    row?.SOLICITUD_TRANSPORTE === 1 ? ' Transporte' : ''
                  }`}</td>
                </td>
                <td className=" px-2 py-2 text-left">
                  {rowTipoVehiculo.map((tipo) => (
                    <div
                      className={
                        tipo.pivot.SOLICITUD_VEHICULO_TIPO_CONFIRMACION === 1 ? 'cursor-pointer text-green-600' : 'text-red-600' + ` w-full cursor-pointer `
                      }>
                      {tipo.VEHICULO_TIPO_DESCRIPCION}
                    </div>
                  ))}
                </td>

                <td className=" px-2 py-2 text-left">{row?.ORIGEN}</td>
                <td className=" flex py-2  text-center">{row?.DESTINO}</td>
              </tr>
            );
          })}
        </tbody>
      )
    );
  }

  return <></>;
};

const mapStateToProps = function (state) {
  return {
    Solicitud: state.Solicitud,
    tipoVehiculo: state.tipoVehiculo,
  };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableSolicitudLogsResumen);

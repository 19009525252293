import React from 'react';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';
import Nav from '../../../components/specific/Navigation';

import ModalUsuarios from '../../../components/specific/Roles/Modales/ModalUsuarios/ModalUsuarios';
import TableRoles from '../../../components/specific/Roles/Table';
import { TitlesTables } from '../../../components/global/Styles/titles';

const ViewRoles = (props) => {
  const { modalAgregarRol, setModalAgregarRol, modalAgregarRolPersonas, setModalAgregarRolPersonas } = props.modal;
  const { getRoles } = props;
  const { createRolinTercero } = props;
  const { loadingTables } = props.actions;
  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'w-1/2',
        }}
      />

      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>ROLES DE USUARIO</TitlesTables>
            <div className="flex-auto"></div>
          </div>

          {loadingTables && <LoadingTables />}
          <TableRoles
            setModalAgregarRolPersonas={setModalAgregarRolPersonas}
            endPoint={props.endPoint}
            actions={props.actions}
          />
        </div>
      </div>

      {modalAgregarRolPersonas && (
        <ModalUsuarios
          setModalAgregarRolPersonas={setModalAgregarRolPersonas}
          getRoles={getRoles}
          createRolinTercero={createRolinTercero}
          actions={props.actions}
          data={props.data}
        />
      )}
    </div>
  );
};

export default ViewRoles;

import { TableNovedadTbody } from './TableNovedadTbody';
import { TableNovedadThead } from './TableNovedadThead';

export const TableNovedad = (props) => {
  return (
    <table className="w-full table-auto text-sm">
      <TableNovedadThead
        modals={props.modals}
        actions={props.actions}
      />
      <TableNovedadTbody
        data={props.data}
        actions={props.actions}
      />
    </table>
  );
};

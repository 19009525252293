import { CheckCircleIcon } from '@heroicons/react/24/solid';

const CheckedCell = ({ id, selId }) => {
  if (id === selId)
    return (
      <CheckCircleIcon
        width={18}
        height={18}
      />
    );
};

export const TableVehiculosTbody = (props) => {
  const rowVehiculo = props.data?.solicitudVehiculoTipos?.map((row) => ({
    placa: `${row.VEHICULO_PLACA} [${row.VEHICULO_TIPO_DESCRIPCION}]`,
    trailer: row.TRAILER_PLACA,
    conductor: `${row.CONDUCTOR_NOMBRE} C.C.  ${row.CONDUCTOR_DOC} Cel: ${row.CONDUCTOR_CEL}`,
    provedor: row.PROVEEDOR_NOMBRE ? `${row.PROVEEDOR_NOMBRE} C.C.  ${row.PROVEEDOR_DOC} Cel: ${row.PROVEEDOR_CEL}` : '...',
    aparejador: row.APAREJADOR_NOMBRE ? `${row.APAREJADOR_NOMBRE} C.C.  ${row.APAREJADOR_DOC} Cel: ${row.APAREJADOR_CEL}` : '...',
    ...row,
  }));

  return (
    <tbody className="">
      {rowVehiculo?.map((obj) => (
        <tr
          key={obj.nombre}
          className="border-b text-xs">
          <td className=" whitespace-nowrap   text-left">{obj.VEHICULO_TIPO_DESCRIPCION}</td>
          <td className=" text-green-400">
            <CheckedCell
              id={obj.SOLICITUD_VEHICULO_TIPO_ID}
              selId={props.data.selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID}
            />
          </td>
          <td
            className="cursor-pointer text-left"
            onClick={() => props.actions.handleVehiculoSelect(obj)}>
            {obj.VEHICULO_PLACA}
          </td>
          <td className="text-left">{obj.trailer}</td>
          <td className="cursor-pointer text-left">{obj.conductor}</td>
          <td className="cursor-pointer text-left">{obj.aparejador}</td>
        </tr>
      ))}
    </tbody>
  );
};

import React from 'react';

import { connect } from 'react-redux';

import { ButtonTable } from '../../../global/Styles/buttons';

export const ModalManifiestOReporte = (props) => {
  const { setModalSeleccionarManifestoOReporte, setModalManifiesto, setModalReporte } = props.modals;

  const handleManifiesto = () => {
    setModalSeleccionarManifestoOReporte(false);
    setModalManifiesto(true);
  };

  const handleReporte = () => {
    setModalSeleccionarManifestoOReporte(false);
    setModalReporte(true);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
        <div className="">
          <div className="flex justify-end">
            <div
              className="cursor-pointer"
              onClick={() => setModalSeleccionarManifestoOReporte(false)}>
              X
            </div>{' '}
          </div>
          <div className="flex-auto justify-center p-5 text-center">
            <h2 className="py-4 text-xl ">Selecciona la acción que deseas hacer</h2>
          </div>
          <div className="flex w-full text-center">
            <div className="w-full">
              <ButtonTable onClick={handleManifiesto}>Manifiesto</ButtonTable>
            </div>
            <div className="w-full">
              <ButtonTable onClick={handleReporte}>Reporte</ButtonTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return { roles: state.roles };
};

//export default Projects;
export default connect(mapStateToProps)(ModalManifiestOReporte);

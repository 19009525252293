const initialState = {
  isData: false,
  centroOperacion: [],
  selCentroOperacion: null,
  pagination: null,
};

const initialCentroOperacionState = {
  selCentroOperacion: null,
};

export const CentroOperacion = (state = initialState, action) => {
  switch (action.type) {
    case 'setCentroOperacion': {
      if (action.value.status === 200) {
        const stateData = { CentroOperacion: action.value.data, isData: true, pagination: action.value.pagination };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setCentroOperacionInit': {
      return initialState;
    }
    case 'setSelCentroOperacion': {
      const obj = {
        ...action.value,
      };

      const stateData = { selCentroOperacion: obj };

      return { ...state, ...stateData };
    }
    case 'setSelCentroOperacionInit': {
      return { ...state, ...initialCentroOperacionState };
    }

    default:
      //
      return state;
  }
};

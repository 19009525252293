import React, { useState, useEffect } from 'react';

import ViewTipoVehiculo from './ViewTipoVehiculo';
import { useApiClient, validateSuccess2xx } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setSelTipoVehiculo, setTipoVehiculo, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const TipoVehiculo = (props) => {
  const { get, post, put, DELETE } = useApiClient();
  const [modalAgregarTipoVehiculo, setModalAgregarTipoVehiculo] = useState(false);
  const [loadingTables, setLoadingTables] = useState(false);

  const getTipoVehiculo = async () => {
    setLoadingTables(true);
    try {
      const result = await get(`/vehiculoTipo`);

      store.dispatch(setTipoVehiculo(result));
    } catch (error) {}
    setLoadingTables(false);
  };

  const createTipoVehiculo = async (form) => {
    try {
      const result = await post(
        '/vehiculoTipo',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarTipoVehiculo(false);
        OkToast({ result, message: 'Tipo de vehículo Agregado!' });
        getTipoVehiculo();
      }
    } catch (error) {}
  };

  const updateTipoVehiculo = async (form) => {
    const result = await put(
      `/vehiculoTipo/${form.VEHICULO_TIPO_ID}`,
      {
        json: JSON.stringify(form),
      },
      'application/json'
    );

    if (!validateSuccess2xx(result)) return CustomToast({ result });

    setModalAgregarTipoVehiculo(false);

    OkToast({ result, message: 'Tipo de vehículo actualizado!' });

    getTipoVehiculo();
  };

  function handleEdit(obj) {
    store.dispatch(setSelTipoVehiculo(obj));
    setModalAgregarTipoVehiculo(true);
  }

  const deleteTipoVehiculo = async (rowID) => {
    store.dispatch(showLoadingAnimation());
    try {
      const result = await DELETE(`/vehiculoTipo/${rowID}`);
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Tipo vehiculo eliminado!' });
        getTipoVehiculo();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }
    store.dispatch(hideLoadingAnimation());
  };

  function handleDeleteTipoVehiculo(rowID) {
    toast((t) => (
      <span>
        <b>¿Está seguro de querer eliminar el tipo de vehículo?</b>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => {
            toast.dismiss(t.id);
            deleteTipoVehiculo(rowID);
          }}>
          Sí
        </button>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </span>
    ));
  }

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['VEHICULO_TIPO_DESCRIPCION'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  useEffect(() => {
    getTipoVehiculo();
  }, []);

  return (
    <ViewTipoVehiculo
      endPoint={{ createTipoVehiculo, updateTipoVehiculo }}
      modal={{ modalAgregarTipoVehiculo, setModalAgregarTipoVehiculo, validate }}
      actions={{ handleDeleteTipoVehiculo, handleEdit }}
      data={{ loadingTables }}
    />
  );
};

const mapStateToProps = function (state) {
  return { tipoVehiculo: state.tipoVehiculo };
};

//export default Projects;
export default connect(mapStateToProps)(TipoVehiculo);

import React, { useState } from 'react';

import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';

import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import TableManifiesto from './TableManifiesto';

import { ModalTitle } from '../../../global/Styles/titles';
import { PanelVehiculoTarifa } from './ModalSeguimiento/PanelVehiculoTarifa';
import { formatterPeso, roundWithDecimals, getFormatedNumberFromInput, removeMoneyFormat } from '../../../../utils/format';

const VehiculoLabel = ({ data }) => {
  const onlyOwnerData = {
    propietario: data.V_TERCERO_PROPIETARIO_NOMBRE,
    tenedor: data.V_TERCERO_TENEDOR_NOMBRE,
    propio: data.V_VEHICULO_PROPIO,
  };

  const vehiculoData = {
    isOurAsset: onlyOwnerData.propio === 1,
    isOurAssetText: onlyOwnerData.propio === 1 ? 'Propio' : 'Ext.',
    propietario: onlyOwnerData.propietario,
    tenedor: onlyOwnerData.tenedor,
    externoText: onlyOwnerData.tenedor ? '(T)' : '(P)',
    externoName: onlyOwnerData.tenedor ? onlyOwnerData.tenedor : onlyOwnerData.propietario,
  };

  return (
    <span className={`${vehiculoData.isOurAsset ? 'text-green-600' : 'text-red-600'} `}>
      {`${vehiculoData.isOurAssetText || '...'} ${(!vehiculoData.isOurAsset && vehiculoData.externoText) || ''} ${
        (!vehiculoData.isOurAsset && vehiculoData.externoName) || ''
      }`}
    </span>
  );
};

const TrailerLabel = ({ data }) => {
  const onlyOwnerData = {
    propietario: data.T_TERCERO_PROPIETARIO_NOMBRE,
    tenedor: data.T_TERCERO_TENEDOR_NOMBRE,
    propio: data.T_VEHICULO_PROPIO,
  };

  const vehiculoData = {
    isOurAsset: onlyOwnerData.propio === 1,
    isOurAssetText: onlyOwnerData.propio === 1 ? 'Propio' : 'Ext.',
    propietario: onlyOwnerData.propietario,
    tenedor: onlyOwnerData.tenedor,
    externoText: onlyOwnerData.tenedor ? '(T)' : '(P)',
    externoName: onlyOwnerData.tenedor ? onlyOwnerData.tenedor : onlyOwnerData.propietario,
  };

  return (
    <span className={`${vehiculoData.isOurAsset ? 'text-green-600' : 'text-red-600'} `}>
      {`${vehiculoData.isOurAssetText || '...'} ${(!vehiculoData.isOurAsset && vehiculoData.externoText) || ''} ${
        (!vehiculoData.isOurAsset && vehiculoData.externoName) || ''
      }`}
    </span>
  );
};

const ConductorLabel = ({ data }) => {
  const employeeData = {
    isEmployee: data.CONDUCTOR_ES_EMPLEADO === 1,
    isEmployeeMsg: data.CONDUCTOR_ES_EMPLEADO === 1 ? 'Empleado' : 'Externo',
  };
  return <span className={`${employeeData.isEmployee ? 'text-green-600' : 'text-red-600'} `}>{`${employeeData.isEmployeeMsg || '...'} `}</span>;
};

const isConductorEmployee = (data) => {
  return data.CONDUCTOR_ES_EMPLEADO === 1;
};
const AparejadorLabel = ({ data }) => {
  const employeeData = {
    isEmployee: data.APAREJADOR_ES_EMPLEADO === 1,
    isEmployeeMsg: data.APAREJADOR_ES_EMPLEADO === 1 ? 'Empleado' : 'Externo',
  };
  return <span className={`${employeeData.isEmployee ? 'text-green-600' : 'text-red-600'} `}>{`${employeeData.isEmployeeMsg || '...'} `}</span>;
};

const isAparejadorEmployee = (data) => {
  return data.APAREJADOR_ES_EMPLEADO === 1;
};

export const ModalReporte = (props) => {
  const { setModalReporte } = props;

  const { createReporte } = props.endPoint;
  const { handleChangeTarifaUnidad, handleAddTarifa } = props.actions;

  const selTipoVehiculo = props.tipoVehiculo.selTipoVehiculo;

  const hasAparejador = selTipoVehiculo.APAREJADOR_DOC ? true : false;
  const hasTrailer = selTipoVehiculo.TRAILER_PLACA ? true : false;

  const [conductorPercent, setConductorPercent] = useState(0);
  const [conductorStartPercent, setConductorStartPercent] = useState(0);
  const [aparejadorPercent, setAparejadorPercent] = useState(0);
  const [aparejadorStartPercent, setAparejadorStartPercent] = useState(0);

  const setSOLICITUD_VEHICULO_TIPO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['SOLICITUD_VEHICULO_TIPO'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setVEHICULO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['VEHICULO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setTRAILER_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TRAILER_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setCONDUCTOR_TERCERO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONDUCTOR_TERCERO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };
  const setPROVEEDOR_TERCERO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['PROVEEDOR_TERCERO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };
  const setAPAREJADOR_TERCERO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['APAREJADOR_TERCERO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setTARIFA_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TARIFA_ID'];
    field.change(args[0].value);

    state.formState.submitFailed = true;
  };

  const setCONDUCTOR_VALOR = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONDUCTOR_VALOR'];

    const formattedValue = getFormatedNumberFromInput(args[0].target.value);

    const value = removeMoneyFormat(formattedValue);

    field.change(formattedValue);

    const total = Number(props.tipoVehiculo.selTipoVehiculo.SOLICITUD_DETALLE_TOTAL);

    const percent = 100 - (value * 100) / total;

    setConductorPercent(total === 0 ? 0 : roundWithDecimals(percent, 2));

    state.formState.submitFailed = true;
  };

  const setAPAREJADOR_VALOR = (args, state, { setIn, changeValue }) => {
    const field = state.fields['APAREJADOR_VALOR'];

    const formattedValue = getFormatedNumberFromInput(args[0].target.value);
    const value = removeMoneyFormat(formattedValue);

    field.change(formattedValue);

    const total = Number(props.tipoVehiculo.selTipoVehiculo.SOLICITUD_DETALLE_TOTAL);

    const percent = 100 - (value * 100) / total;

    setAparejadorPercent(total === 0 ? 0 : roundWithDecimals(percent, 2));

    state.formState.submitFailed = true;
  };

  const setCONDUCTOR_ANTICIPO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONDUCTOR_ANTICIPO'];
    const fieldTotal = state.fields['CONDUCTOR_VALOR'];

    const formattedValue = getFormatedNumberFromInput(args[0].target.value);
    const value = removeMoneyFormat(formattedValue);

    field.change(formattedValue);

    const total = Number(removeMoneyFormat(fieldTotal.lastFieldState.value) || props.tipoVehiculo.selTipoVehiculo.SOLICITUD_DETALLE_TOTAL);

    const percent = (value * 100) / total;

    setConductorStartPercent(roundWithDecimals(percent || 0, 2));

    state.formState.submitFailed = true;
  };

  const setAPAREJADOR_ANTICIPO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['APAREJADOR_ANTICIPO'];
    const fieldTotal = state.fields['APAREJADOR_VALOR'];

    const formattedValue = getFormatedNumberFromInput(args[0].target.value);
    const value = removeMoneyFormat(formattedValue);

    field.change(formattedValue);

    const total = Number(removeMoneyFormat(fieldTotal.lastFieldState.value) || props.tipoVehiculo.selTipoVehiculo.SOLICITUD_DETALLE_TOTAL);

    const percent = (value * 100) / total;

    setAparejadorStartPercent(roundWithDecimals(percent || 0, 2));

    state.formState.submitFailed = true;
  };

  const setTARIFA_UNIDAD = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TARIFA_UNIDAD'];
    field.change(args[0].value);
    state.formState.submitFailed = true;

    handleChangeTarifaUnidad(props.tipoVehiculo.selTipoVehiculo.VEHICULO_TIPO_ID, args[0].value);
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = [];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });

    values['CONDUCTOR_VALOR'] = removeMoneyFormat('' + values['CONDUCTOR_VALOR']);
    values['APAREJADOR_VALOR'] = removeMoneyFormat('' + values['APAREJADOR_VALOR']);

    const checkConductorValue = isConductorEmployee(selTipoVehiculo) ? Number(values['CONDUCTOR_VALOR']) === 0 : Number(values['CONDUCTOR_VALOR']) > 0;

    const checkAparejadorValue = isAparejadorEmployee(selTipoVehiculo) ? Number(values['APAREJADOR_VALOR']) === 0 : Number(values['APAREJADOR_VALOR']) > 0;

    if (!checkConductorValue) errors['CONDUCTOR_VALOR'] = '*';

    if (!checkAparejadorValue && hasAparejador) errors['APAREJADOR_VALOR'] = '*';

    return errors;
  };

  const handleFormSubmit = async (form) => {
    const withAllValues = {
      ...form,
      SOLICITUD_VEHICULO_P1: conductorPercent,
      SOLICITUD_VEHICULO_P2: conductorStartPercent,
      SOLICITUD_VEHICULO_P3: aparejadorPercent,
      SOLICITUD_VEHICULO_P4: aparejadorStartPercent,
    };

    withAllValues['CONDUCTOR_VALOR'] = removeMoneyFormat('' + withAllValues['CONDUCTOR_VALOR']);
    withAllValues['APAREJADOR_VALOR'] = removeMoneyFormat('' + withAllValues['APAREJADOR_VALOR']);
    withAllValues['CONDUCTOR_ANTICIPO'] = removeMoneyFormat('' + withAllValues['CONDUCTOR_ANTICIPO']);
    withAllValues['APAREJADOR_ANTICIPO'] = removeMoneyFormat('' + withAllValues['APAREJADOR_ANTICIPO']);
    createReporte(withAllValues);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>REPORTE DE TRABAJO</ModalTitle>
        <div className="flex-auto justify-center p-1 text-left">
          <TableManifiesto />
        </div>

        <div className="px-5">
          <hr className="mt-1" />
          <PanelVehiculoTarifa
            handleAddTarifa={handleAddTarifa}
            handleChangeTarifaUnidad={handleChangeTarifaUnidad}
            data={{ selSolicitudVehiculoTipo: props.tipoVehiculo.selTipoVehiculo }}
          />
        </div>

        <Form
          onSubmit={handleFormSubmit}
          validate={validate}
          mutators={{
            setSOLICITUD_VEHICULO_TIPO,
            setVEHICULO_ID,
            setTRAILER_ID,
            setCONDUCTOR_TERCERO_ID,
            setTARIFA_UNIDAD,
            setTARIFA_ID,
            setPROVEEDOR_TERCERO_ID,
            setAPAREJADOR_TERCERO_ID,
            setCONDUCTOR_VALOR,
            setAPAREJADOR_VALOR,
            setCONDUCTOR_ANTICIPO,
            setAPAREJADOR_ANTICIPO,
          }}
          initialValues={{
            SOLICITUD_ID: props.Solicitud.selSolicitud.SOLICITUD_ID,
            SOLICITUD_VEHICULO_TIPO_ID: props.tipoVehiculo.selTipoVehiculo.SOLICITUD_VEHICULO_TIPO_ID,
            CONDUCTOR_VALOR: 0,
            CONDUCTOR_ANTICIPO: 0,
            SOLICITUD_PESO: selTipoVehiculo.SOLICITUD_PESO,
            PROVEEDOR_VALOR: selTipoVehiculo.PROVEEDOR_VALOR,
            PROVEEDOR_ANTICIPO: selTipoVehiculo.PROVEEDOR_ANTICIPO,
            APAREJADOR_VALOR: 0,
            APAREJADOR_ANTICIPO: 0,
            VEHICULO_PLACA: selTipoVehiculo.VEHICULO_PLACA,
            TRAILER_PLACA: selTipoVehiculo.TRAILER_PLACA,
            CONDUCTOR_NOMBRE: selTipoVehiculo.CONDUCTOR_NOMBRE,
            APAREJADOR_NOMBRE: selTipoVehiculo.APAREJADOR_NOMBRE,
            TERCERO_AUDITORIA: props.data.auth.data.selCliente.TERCERO_ID,
          }}
          subscription={{ submitting: true, pristine: true }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <FormStateToRedux form="frmReporteAnticipos" />
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  {/* <h2 className="text-xl py-4 ">Reporte</h2> */}
                  <div className="flex space-x-4">
                    <div className="mt-1 w-1/3">
                      <Field name="VEHICULO_PLACA">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">
                              Vehículo <VehiculoLabel data={selTipoVehiculo} />
                            </p>
                            <input
                              {...input}
                              readOnly
                              type="text"
                              placeholder="Peso"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 w-1/3">
                      {hasTrailer && (
                        <Field name="TRAILER_PLACA">
                          {({ input, meta }) => (
                            <>
                              <p className="text-sm text-gray-800">
                                Trailer <TrailerLabel data={selTipoVehiculo} />
                              </p>
                              <input
                                {...input}
                                type="text"
                                readOnly
                                placeholder="Peso"
                                className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                              />
                            </>
                          )}
                        </Field>
                      )}
                    </div>
                    <div className="mt-1 w-1/3">
                      <Field name="SOLICITUD_PESO">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Peso</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Peso"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="flex space-x-4">
                    <div className="mt-1 w-1/3">
                      <Field name="CONDUCTOR_NOMBRE">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">
                              Conductor / Operador <ConductorLabel data={selTipoVehiculo} />
                            </p>
                            <input
                              {...input}
                              type="text"
                              readOnly
                              placeholder="Peso"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 w-1/3">
                      <Field name="CONDUCTOR_VALOR">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">
                              Costo Servicio <span className=" text-green-500"> {conductorPercent}%</span>
                            </p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Costo Servicio"
                              readOnly={isConductorEmployee(selTipoVehiculo)}
                              onChange={form.mutators.setCONDUCTOR_VALOR}
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 w-1/3">
                      <Field name="CONDUCTOR_ANTICIPO">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">
                              Anticipo <span className=" text-green-500"> {conductorStartPercent}% </span>
                            </p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Anticipo"
                              onChange={form.mutators.setCONDUCTOR_ANTICIPO}
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  {hasAparejador && (
                    <div className="flex space-x-4">
                      <div className="mt-1 w-1/3">
                        <Field name="APAREJADOR_NOMBRE">
                          {({ input, meta }) => (
                            <>
                              <p className="text-sm text-gray-800">
                                Aparejador / Auxiliar <AparejadorLabel data={selTipoVehiculo} />
                              </p>
                              <input
                                {...input}
                                type="text"
                                readOnly
                                className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                              />
                            </>
                          )}
                        </Field>
                      </div>
                      <div className="mt-1 w-1/3">
                        <Field name="APAREJADOR_VALOR">
                          {({ input, meta }) => (
                            <>
                              <p className="text-sm text-gray-800">
                                Costo Servicio <span className=" text-green-500">{aparejadorPercent}%</span>
                              </p>
                              <input
                                {...input}
                                type="text"
                                readOnly={isAparejadorEmployee(selTipoVehiculo)}
                                placeholder="Costo Servicio"
                                onChange={form.mutators.setAPAREJADOR_VALOR}
                                className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                              />
                            </>
                          )}
                        </Field>
                      </div>
                      <div className="mt-1 w-1/3">
                        <Field name="APAREJADOR_ANTICIPO">
                          {({ input, meta }) => (
                            <>
                              <p className="text-sm text-gray-800">
                                Anticipo <span className=" text-green-500">{aparejadorStartPercent} %</span>
                              </p>
                              <input
                                {...input}
                                type="text"
                                placeholder="Anticipo"
                                onChange={form.mutators.setAPAREJADOR_ANTICIPO}
                                className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                              />
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                  )}
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalReporte(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Generar
                  </ButtonTable>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    roles: state.roles,
    tipoVehiculo: state.tipoVehiculo,
    frm: state.finalForm.frmReporte,
    Solicitud: state.Solicitud,
  };
};

//export default Projects;
export default connect(mapStateToProps)(ModalReporte);

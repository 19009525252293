import React, { useState, useEffect } from 'react';

import ViewTrailer from './ViewTrailer';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setSelTrailer, setTrailer, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const Trailer = (props) => {
  const { get, post, DELETE, put } = useApiClient();
  const [modalAgregarTrailer, setModalAgregarTrailer] = useState(false);
  const [loadingTables, setLoadingTables] = useState(false);
  const getTrailer = async () => {
    setLoadingTables(true);
    try {
      const result = await get(`/trailer`);

      store.dispatch(setTrailer(result));
    } catch (error) {}
    setLoadingTables(false);
  };

  const createTrailer = async (form) => {
    try {
      const result = await post(
        '/trailer',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarTrailer(false);
        OkToast({ result, message: 'Trailer Agregado!' });
        getTrailer();
      }
    } catch (error) {}
  };

  const updateTrailer = async (form) => {
    try {
      const result = await put(
        `/trailer/${props.trailer.selTrailer.TRAILER_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarTrailer(false);
        OkToast({ result, message: 'Trailer actualizado!' });
        getTrailer();
      }
    } catch (error) {}
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['MARCA_ID', 'TRAILER_PLACA', 'TRAILER_PROPIO'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  function handleEdit(obj) {
    store.dispatch(setSelTrailer(obj));
    setModalAgregarTrailer(true);
  }

  function handleDelete(rowID) {
    //const projectsResult = (`/projects/${projectID}/task-managements/${taskManagementID}/tasks/${rowID}`);
    //

    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar el trailer?</b>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => {
            toast.dismiss(t.id);
            deleteTrailer(rowID);
          }}>
          Si
        </button>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </span>
    ));
  }

  const deleteTrailer = async (rowID) => {
    store.dispatch(showLoadingAnimation());

    try {
      const result = await DELETE(`/trailer/${rowID}`);

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Trailer eliminado!' });

        getTrailer();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }

    store.dispatch(hideLoadingAnimation());
  };

  useEffect(() => {
    getTrailer();
  }, []);

  return (
    <ViewTrailer
      modal={{ modalAgregarTrailer, setModalAgregarTrailer }}
      endPoint={{ createTrailer, updateTrailer }}
      actions={{ handleEdit, handleDelete, loadingTables, validate }}
    />
  );
};

const mapStateToProps = function (state) {
  return { trailer: state.trailer };
};

//export default Projects;
export default connect(mapStateToProps)(Trailer);

import React from 'react';
import TbodyUsuariosEnRoles from './tbody';
import TheadUsuariosEnRoles from './thead';
const TableUsuariosEnRoles = (props) => {
  const { tercerosRol } = props;
  const { handleDeleteTerceroInRol } = props;
  return (
    <table className="min-w-full bg-white">
      <TheadUsuariosEnRoles />
      <TbodyUsuariosEnRoles
        tercerosRol={tercerosRol}
        handleDeleteTerceroInRol={handleDeleteTerceroInRol}
      />
    </table>
  );
};
export default TableUsuariosEnRoles;

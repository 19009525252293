import React, { useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import { CustomToast, OkToast } from '../../Toast';
import { fixDateForBE } from '../../../../utils/format';
import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import { ReactComponent as IconNew } from '../../../assets/svg/file-add.svg';
import { ReactComponent as IconEdit } from '../../../assets/svg/file-edit.svg';

export const ModalAgregarUnidadNegocio = (props) => {
  const { setModalModalAgregarUnidadNegocio } = props;
  const { validate } = props.agregarUnidadNegocio;
  const { createUnidadNegocio, updateUnidadNegocio } = props.endPoint;
  const storeState = store.getState();

  const editMode = storeState.UnidadNegocio.selUnidadNegocio === null ? false : true;

  const proxyTask = async (form) => {
    if (editMode) updateUnidadNegocio(form);
    else createUnidadNegocio(form);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        onSubmit={proxyTask}
        validate={validate}
        initialValues={editMode ? storeState.UnidadNegocio.selUnidadNegocio : {}}
        subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <h2 className="py-4 text-xl ">
                    {editMode ? <IconEdit className="inline-block h-8 w-8" /> : <IconNew className=" inline-block h-8 w-8" />}
                    GRUPO DE UNIDADES DE NEGOCIOS
                  </h2>

                  <FormStateToRedux form="frmAgregarRol" />
                  <div>
                    <Field name="NEGOCIO_ID">
                      {({ input, meta }) => (
                        <>
                          <p className="text-sm text-gray-800">Código</p>
                          <input
                            {...input}
                            type="text"
                            placeholder="nombre"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="NEGOCIO_NOMBRE">
                      {({ input, meta }) => (
                        <>
                          <p className="text-sm text-gray-800">Nombre</p>
                          <input
                            {...input}
                            type="text"
                            placeholder="nombre"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalModalAgregarUnidadNegocio(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Agregar
                  </ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = function (state) {
  return { UnidadNegocio: state.UnidadNegocio };
};

//export default Projects;
export default connect(mapStateToProps)(ModalAgregarUnidadNegocio);

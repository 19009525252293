import { moneyFormat } from '../../../../../utils/format';
import { TrashIcon } from '@heroicons/react/24/outline';

export const TableCotizacionTarifaTbody = ({ data, actions, isReadOnly }) => {
  console.log('data: ', data.items);
  return (
    <tbody className="">
      {data.items?.map((obj) => (
        <tr
          key={obj.ITEM_ID}
          className="border-b text-xs">
          <td className=" text-green-400">{obj.TARIFA_CONSECUTIVO}</td>
          <td className=" whitespace-nowrap   text-left">{obj.TARIFA_ITEM}</td>

          <td className="text-left">{`${obj.TARIFA_GRUPO} ${obj.TARIFA_NOMBRE}`}</td>
          <td className=" whitespace-nowrap   text-right">{obj.SOLICITUD_DETALLE_CANTIDAD}</td>
          <td className=" whitespace-nowrap   text-right">{moneyFormat(obj.SOLICITUD_DETALLE_VALOR)}</td>
          <td className=" whitespace-nowrap   text-right">{moneyFormat(obj.SOLICITUD_DETALLE_TOTAL)}</td>
          <td className=" whitespace-nowrap   text-left">
            {!isReadOnly && (
              <TrashIcon
                width={18}
                height={18}
                onClick={() => actions.handleDeleteDetalle(obj)}
              />
            )}
          </td>
          {/* <td
            className="cursor-pointer px-4 py-3 text-left hover:text-gray-400"
            onClick={() => handleClick(item.SOLICITUD_DETALLE_ID)}>
            <Trash />
          </td> */}
        </tr>
      ))}
    </tbody>
  );
};

import React, { useEffect, useState } from 'react';

import { useApiClient } from '../../../../clients/apiAuth';

import { Field, Form } from 'react-final-form';

import FormStateToRedux from '../../RFF/FormStateToRedux';

import Select from 'react-select';

const Filter = (props) => {
  const { get } = useApiClient();

  const { setFilters, auth } = props.data;

  const [contrato, setContrato] = useState([]);

  const getContratos = async () => {
    const response = await get(`/contrato?TERCERO_ID=${auth.data.selCliente.TERCERO_ID}`);

    const lst = response.data.map((row) => ({
      label: `${row.CONTRATO_NUMERO} - ${row.OPERACION_MODO_NOMBRE}`,
      value: row.CONTRATO_ID,
    }));

    setContrato(lst);

    // APLICA EL FILTRO CON EL PRIMER ELEMENTO DEL SELECT.

    setFilters([`CONTRATO_ID=${lst[0].value}`]);
  };

  const handleSubmmit = (form) => {};

  const contratoChange = (args, state, { setIn, changeValue }) => {
    const field = state.fields['current_state'];
    field.change(args[0].value);

    state.formState.submitFailed = true;

    //CAMBIA EL FILTRO.
    setFilters([`CONTRATO_ID=${args[0].value}`]);
  };

  useEffect(() => {
    getContratos();
  }, []);

  return (
    <Form
      onSubmit={handleSubmmit}
      initialValues={{}}
      subscription={{ submitting: true, pristine: true }}
      mutators={{ contratoChange }}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <FormStateToRedux form="frmZonasFilters" />

          <div className="pr-2">
            <div className="flex items-center gap-2 ">
              <h1 className="">Contrato</h1>
              <Field name="current_state">
                {({ input, meta }) => (
                  <>
                    {contrato.length > 0 && (
                      <Select
                        options={contrato}
                        defaultValue={contrato[0]}
                        name="selectNovedadTipoOptions"
                        onChange={form.mutators.contratoChange}
                        menuPortalTarget={document.body}
                        styles={{
                          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                            ...styles,
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: '20',
                          }),
                        }}
                      />
                    )}
                    <input
                      {...input}
                      type="hidden"
                    />
                  </>
                )}
              </Field>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
export default Filter;

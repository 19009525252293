import React from 'react';

import { TrashIcon, CheckBadgeIcon, PrinterIcon } from '@heroicons/react/24/outline';

import '@szhsin/react-menu/dist/index.css';

import { formatterPeso } from '../../../../utils/format';

const TableCotizacionTbody = (props) => {
  const { cotizacionData } = props.data;

  const { handleAprobar, handlePDF } = props;

  return (
    <tbody className="">
      {cotizacionData.data?.map((obj) => (
        <tr
          key={obj.DOCUMENTO_ID}
          className="border-b even:bg-gray-50">
          <td className="w-2 "></td>
          <td className="px-2 text-left">{obj.CLIENTE_NOMBRE}</td>
          <td className=" px-2 text-left">{obj.DOCUMENTO_CONSECUTIVO ? obj.DOCUMENTO_CONSECUTIVO : obj.DOCUMENTO_ID}</td>
          <td className=" px-2 text-right">{formatterPeso(obj.TOTAL_SOLICITUD)}</td>
          <td className="  px-2">{obj.COTIZACION_SALUDO}</td>
          <td className="  whitespace-nowrap px-2 text-right">{obj.CREATED_AT}</td>
          <td className="  whitespace-nowrap px-2 ">{obj.AUDITORIA_USUARIO}</td>
          {/* <td>
            <TrashIcon
              width={18}
              height={18}
              onClick={() => actions.handleDeleteDetalle(obj)}
            />{' '}
          </td> */}
          <td className="cursor-pointer">
            {obj.DOCUMENTO_CONSECUTIVO ? (
              <PrinterIcon
                width={18}
                height={18}
                onClick={() => {
                  handlePDF(obj);
                }}
              />
            ) : (
              <CheckBadgeIcon
                width={18}
                height={18}
                onClick={() => {
                  handleAprobar(obj);
                }}
              />
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableCotizacionTbody;

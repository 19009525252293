import React, { useState, useEffect } from 'react';
import { ModalContext } from './context';
import ModalRight from './ModalRight';

export const ModalProvider = ({ children }) => {
  const [modalChildren, setModalChildren] = useState([]);
  // Controls if the display css property
  const [toDisplay, setToDisplay] = useState(false);
  // Controls the trigger of the animation
  const [toAnimate, setToAnimate] = useState(toDisplay);
  const openModal = () => {
    setToDisplay(true);
    setImmediate(() => setToAnimate(true));
  };

  const closeModal = () => {
    setToAnimate(false);
    setTimeout(() => {
      setToDisplay(false);
      setModalChildren([]);
    }, 500);
  };

  useEffect(() => {
    if (toDisplay) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = 'static';
    }
  }, [toDisplay]);
  return (
    <ModalContext.Provider
      value={{
        children: modalChildren,
        setChildren: setModalChildren,
        openModal,
        closeModal,
      }}>
      <>
        <ModalRight
          {...{ toAnimate }}
          {...{ toDisplay }}>
          {modalChildren}
        </ModalRight>
        {children}
      </>
    </ModalContext.Provider>
  );
};

import { WrenchIcon, TruckIcon } from '@heroicons/react/24/outline';

import { ToolTipWrap } from './ToolTipWrap';
import { solicitudEstados } from './solicitudEstados';

export const ConfirmarIcon = ({ itemData, solicitudEstado, solicitud, handleConfirmarTipoVehiculo }) => {
  if (solicitudEstado !== solicitudEstados['Aprobada']) return;

  if (itemData.SOLICITUD_VEHICULO_TIPO_CONFIRMACION === 1)
    return (
      <ToolTipWrap
        itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
        componentPrefix={'rttConfirmar'}
        message={'Confirmado!'}>
        <TruckIcon
          data-tip
          data-for={`rttConfirmar${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
          className="h-5 w-5 transform cursor-pointer text-green-500 transition  duration-300 hover:scale-110"
          // onClick={() => handleConfirmarTipoVehiculo(itemData, solicitud)}
        />
      </ToolTipWrap>
    );

  if (solicitudEstado !== solicitudEstados['Aprobada']) return;

  return (
    <ToolTipWrap
      itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
      componentPrefix={'rttConfirmar'}
      message={'Confirmar'}>
      <WrenchIcon
        data-tip
        data-for={`rttConfirmar${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
        className="h-5 w-5 transform cursor-pointer text-yellow-500 transition  duration-300 hover:scale-110"
        onClick={() => handleConfirmarTipoVehiculo(itemData, solicitud)}
      />
    </ToolTipWrap>
  );
};

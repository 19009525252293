import React from 'react';

import { ButtonTable } from '../../../global/Styles/buttons';

import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';

const Filter = (props) => {
  const { handleSearch } = props.actions;

  return (
    <div className="w-1/2">
      <Form
        onSubmit={handleSearch}
        initialValues={{}}
        subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <FormStateToRedux form="frmTercerosFiltros" />
            <div className=" pr-2">
              <div className="flex flex-row gap-2">
                <div className="flex-auto ">
                  <Field name="SRC">
                    {({ input, meta }) => (
                      <>
                        <div className="relative block">
                          <span className="absolute inset-y-0 left-0 flex h-full items-center pl-2">
                            <svg
                              viewBox="0 0 24 24"
                              className="h-4 w-4 fill-current text-gray-500">
                              <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                            </svg>
                          </span>
                          <input
                            {...input}
                            placeholder="Search"
                            className="sm:rounded-l-none focus:outline-none block w-full appearance-none rounded-l rounded-r border border-b border-gray-400 bg-white py-2 pl-8 pr-6 text-sm text-gray-700 placeholder-gray-400 focus:bg-white focus:text-gray-700 focus:placeholder-gray-600"
                          />
                        </div>
                      </>
                    )}
                  </Field>
                </div>

                <div>
                  <ButtonTable
                    className="py-2"
                    type="submit"
                    disabled={submitting || pristine}>
                    Buscar
                  </ButtonTable>
                </div>
                <div>
                  <ButtonTable
                    className="py-2"
                    onClick={form.reset}>
                    Limpiar
                  </ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};
export default Filter;

const initialState = {
  isData: false,
  roles: [],
  selRoles: null,
  pagination: null,
};
const initialRolesState = {
  selRoles: null,
};
export const roles = (state = initialState, action) => {
  switch (action.type) {
    case 'setRoles': {
      if (action.value.status === 200) {
        const stateData = { roles: action.value.data, isData: true, pagination: action.value.pagination };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setRolesInit': {
      return initialState;
    }
    case 'setSelRoles': {
      const obj = {
        ...action.value,
      };
      const stateData = { selRoles: obj };
      return { ...state, ...stateData };
    }
    case 'setSelRolesInit': {
      return { ...state, ...initialRolesState };
    }
    default:
      return state;
  }
};

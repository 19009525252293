import React from 'react';

import { CheckParameter } from '../../../../utils/security';

const TableAnticipoThead = (props) => {
  return (
    <thead className="  sticky top-0 z-10 bg-gray-100  text-sm  uppercase  text-gray-700 ">
      <tr>
        <th className="w-2 "></th>
        <th
          scope="col"
          className="whitespace-nowrap px-2  text-left ">
          Cliente
        </th>
        <th
          scope="col"
          className="whitespace-nowrap px-2  text-left ">
          C. Int
        </th>

        <th
          scope="col"
          className="px-2  text-left">
          Tipo de servicio
        </th>
        <th
          scope="col"
          className="px-2   text-left">
          Tipo de vehículo
        </th>
        <th
          scope="col"
          className="px-2   text-right">
          Valor
        </th>
        <th
          scope="col"
          className="px-2   text-left">
          Ruta
        </th>

        <th
          scope="col"
          className="px-2   text-left">
          Fecha servicio
        </th>
        <th
          scope="col"
          className="whitespace-nowrap  px-2  text-left">
          Fecha Reg.
        </th>
        <th
          scope="col"
          className="whitespace-nowrap  px-2  text-left">
          Cont. Zona
        </th>
        {CheckParameter('PARAMETRO_ID', 2) && (
          <th
            scope="col"
            className="px-2   text-left">
            Aprobador
          </th>
        )}
        <th
          scope="col"
          className="px-2   text-center">
          Nota
        </th>
      </tr>
    </thead>
  );
};

//export default ViewProjects;

export default TableAnticipoThead;

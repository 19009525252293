const initialState = {
  isData: false,
  trailer: [],
  selTrailer: null,
  pagination: null,
};

const initialTrailerState = {
  selTrailer: null,
};

export const trailer = (state = initialState, action) => {
  switch (action.type) {
    case 'setTrailer': {
      if (action.value.status === 200) {
        const stateData = {
          trailer: action.value.data,
          isData: true,
          pagination: action.value.pagination,
        };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setTrailerInit': {
      return initialState;
    }
    case 'setSelTrailer': {
      const obj = {
        ...action.value,
      };

      const stateData = { selTrailer: obj };

      return { ...state, ...stateData };
    }
    case 'setSelTrailerInit': {
      return { ...state, ...initialTrailerState };
    }

    default:
      //
      return state;
  }
};

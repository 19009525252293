const initialState = {
  isData: false,
  tipoDocumento: [],
  selTipoDocumento: null,
  pagination: null,
};

const initialTipoDocumentoState = {
  selTipoDocumento: null,
};

export const tipoDocumento = (state = initialState, action) => {
  switch (action.type) {
    case 'setTipoDocumento': {
      if (action.value.status === 200) {
        const stateData = { tipoDocumento: action.value.data, isData: true, pagination: action.value.pagination };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setTipoDocumentoInit': {
      return initialState;
    }
    case 'setSelTipoDocumento': {
      const obj = {
        ...action.value,
      };

      const stateData = { selTipoDocumento: obj };

      return { ...state, ...stateData };
    }
    case 'setSelTipoDocumentoInit': {
      return { ...state, ...initialTipoDocumentoState };
    }

    default:
      //
      return state;
  }
};

import React from 'react';

import Nav from '../../../components/specific/Navigation';
import { Toaster } from 'react-hot-toast';
import { TitlesTables } from '../../../components/global/Styles/titles';
import LoadingTables from '../../../components/global/LoadingTables';

const TableDashboard = ({ data }) => {
  const TableItemCardDashboard = ({ data }) => {
    if (data) {
      return (
        <ul className=" grid grid-cols-6 gap-4">
          {Object.keys(data).map((item) => {
            return (
              <li
                key={item}
                className="overflow-hidden rounded-lg border bg-white">
                <div className="sm:p-6 px-4 py-5">
                  <div>
                    <div className="truncate text-sm font-medium text-gray-500">{data[item].CLIENTE_NOMBRE}</div>
                    <div className="mt-1 text-gray-900">🆕 Nuevas: {data[item].Nueva}</div>
                    <div className="mt-1 text-gray-900">👍 Aprobadas: {data[item].Aprobada}</div>
                    <div className="mt-1 text-gray-900">❌ Canceladas: {data[item].Cancelada}</div>
                    <div className="mt-1 text-gray-900">🚫 Rechazadas: {data[item].Rechazada}</div>
                    <div className="mt-1 text-gray-900">✅ Confirmadas: {data[item].Confirmada}</div>
                    <div className="mt-1 text-gray-900">🚧 En ejecución: {data[item].Ejecución}</div>
                    <div className="mt-1 text-gray-900">🏁 Finalizadas: {data[item].Finalizada}</div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }

    return (
      <li className="overflow-hidden rounded-lg border bg-white">
        <div className="sm:p-6 px-4 py-5">
          <div>
            {/* <div className="truncate text-sm font-medium text-gray-500">{data[item].CLIENTE_NOMBRE}</div> */}
            <div className="mt-1 text-gray-900">🆕 Nuevas:0</div>
            <div className="mt-1 text-gray-900">👍 Aprobadas:0</div>
            <div className="mt-1 text-gray-900">❌ Canceladas:0</div>
            <div className="mt-1 text-gray-900">🚫 Rechazadas:0</div>
            <div className="mt-1 text-gray-900">✅ Confirmadas:0</div>
            <div className="mt-1 text-gray-900">🚧 En ejecución:0</div>
            <div className="mt-1 text-gray-900">🏁 Finalizadas:0</div>
          </div>
        </div>
      </li>
    );
  };

  return (
    <>
      <ul className="sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 mx-2 mt-2 grid grid-cols-1 gap-4">
        {Object.keys(data).map((item) => {
          return (
            <li
              key={data[item].data.ANIO + '-' + data[item].data.MES}
              className="overflow-hidden rounded-lg bg-white shadow">
              <div className="sm:p-6 px-4 py-5">
                <div>
                  <div className="truncate text-sm font-medium text-gray-500"> {data[item].data.ANIO + '-' + data[item].data.MES}</div>
                  <TableItemCardDashboard data={data[item].solicitud} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const ViewDashboard = (props) => {
  const { isLoading, customerData } = props.data;

  const hasData = Object.keys(customerData).length > 0;

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'w-1/2',
        }}
      />
      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            {/* <TitlesTables>DASHBOARD</TitlesTables> */}
            <div className="flex-auto"></div>
          </div>

          {isLoading && <LoadingTables />}

          {hasData && <TableDashboard data={customerData} />}
        </div>
      </div>
    </div>
  );
};

export default ViewDashboard;

import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './Layout';
import { ModalProvider } from './contexts/ModalProvider';
import 'tailwindcss/tailwind.css';
import { connect } from 'react-redux';

function App() {
  return (
    // <Router basename="/transport">
    <Router>
      <ModalProvider>
        <Layout />
      </ModalProvider>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  authProps: state.auth,
});

const appRedux = connect(mapStateToProps)(App);
export default appRedux;

//export default App;

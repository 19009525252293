import React, { useEffect, useState } from 'react';
import { ButtonTable } from '../../../../global/Styles/buttons';

import TableVehiculoDetalles from './TableVehiculoDetalles';

// import Map from '../../../../../img/Map.jpg';

import TableNovedades from '../TableNovedades';
import { connect } from 'react-redux';
import Select from 'react-select';

import TableBono from '../TableBono';
import FormStateToRedux from '../../../RFF/FormStateToRedux';
import { Form, Field } from 'react-final-form';
import { useApiClient, validateSuccess2xx } from '../../../../../clients/apiAuth';
import { CustomToast, ErrorToast, OkToast } from '../../../Toast';
import { inputsSettingsFalse, inputsSettingsTrue } from '../../../../global/Styles/inputs';
import { ModalTitle } from '../../../../global/Styles/titles';
import toast from 'react-hot-toast';
import { ModalAsignarBono } from '../ModalAsignarBono';
import TableTarifa from '../TableTarifa';
import IndicatorServicioEstado from './IndicatorServicioEstado';
import TableEjecucion from '../TableEjecucion';
import TableManifiesto from '../TableManifiesto';
import { TableVehiculos } from './TableVehiculos';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TableNovedad } from './TableNovedad';
import { ModalNovedad } from '../ModalNovedad/ModalNovedad';

export const PanelVehiculoNovedades = ({ novedades, setNOVEDAD_TIPO_ID, modals, data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  const [servicioNovedades, setServicioNovedades] = useState([]);
  const [novedadTipoOptions, setNovedadTipoOptions] = useState([]);

  const { get, post } = useApiClient();

  const getServicioNovedades = async () => {
    const response = await get(`/solicitudNovedad?SOLICITUD_VEHICULO_TIPO_ID=${data.selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID}`);

    setServicioNovedades(response);
  };

  const getNovedadTipoOptions = async () => {
    try {
      const result = await get(`/novedadTipo`);

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      const lst = result.data.map((row) => ({
        label: `${row.NOVEDAD_TIPO_DESCRIPCION}`,
        value: row.NOVEDAD_TIPO_ID,
      }));
      setNovedadTipoOptions(lst);
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const createNovedad = async (form) => {
    try {
      const result = await post(
        '/solicitudNovedad',
        {
          json: JSON.stringify({
            NOVEDAD_TIPO_ID: form.NOVEDAD_TIPO_ID,
            SOLICITUD_VEHICULO_TIPO_ID: form.SOLICITUD_VEHICULO_TIPO_ID,
            SOLICITUD_NOVEDAD_FECHA: form.SOLICITUD_NOVEDAD_FECHA,
            TERCERO_ID: form.TERCERO_ID,
            ESTADO_ID: form.ESTADO_ID,
            SOLICITUD_NOVEDAD_OBSERVACION: form.SOLICITUD_NOVEDAD_OBSERVACION,
          }),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Novedad Agregada!' });
        getServicioNovedades();
      }
    } catch (error) {}
  };

  useEffect(() => {
    getNovedadTipoOptions();
  }, []);

  useEffect(() => {
    getServicioNovedades();
  }, [data.selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID]);

  const [isModalNovedadVisible, setIsModalNovedadVisible] = useState(false);

  const handleShowNovedadModal = (isVisible = true) => {
    setIsModalNovedadVisible(isVisible);
  };

  return (
    <div className="w-full">
      <TableNovedad
        modals={{ handleShowNovedadModal }}
        data={{ servicioNovedades }}
      />

      {isModalNovedadVisible && (
        <ModalNovedad
          modals={{ handleShowNovedadModal }}
          data={{
            selSolicitud: data.selSolicitud,
            novedadTipoOptions,
            selSolicitudVehiculoTipo: data.selSolicitudVehiculoTipo,
          }}
          endPoints={{ createNovedad }}
        />
      )}
    </div>
  );
};

import React, { useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import { CustomToast, OkToast } from '../../Toast';
import { fixDateForBE } from '../../../../utils/format';
import Select from 'react-select';
import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import { useEffect } from 'react';
import TableParametroCliente from './Table/index';

export const ModalAgregarCliente = (props) => {
  console.log('ModalAgregarCliente: ', ModalAgregarCliente);
  const { setModalAgregarCliente } = props.modal;
  const { updateCliente } = props.endPoint;

  const { post, get, put } = useApiClient();
  const storeState = store.getState();

  const [data, setData] = useState([]);

  const getParametroCliente = async () => {
    try {
      const response = await get(`/parametroCliente?TERCERO_ID=${storeState.Cliente.selCliente.TERCERO_ID}`);
      setData(response.data);
    } catch (error) {}
  };

  // +++ MUTADORES +++

  // --- MUTADORES ---

  //const editMode = storeState.trailer.selTrailer === null ? false : true;

  const getTrailerPropiofromState = (reg) => {
    const lstValues = [];

    if (reg === 1) lstValues.push('1');

    return lstValues;
  };

  const guardar = () => {
    let parametro = data.map((b) => {
      return {
        PARAMETRO_CLI_ID: b.PARAMETRO_CLI_ID,
        PARAMETRO_CLI_VALUE: b.PARAMETRO_CLI_VALUE,
      };
    });

    updateCliente({ parametro: parametro }, storeState.Cliente.selCliente.TERCERO_ID);
  };

  const lstCheckTrailerPropio = getTrailerPropiofromState(storeState.trailer.selTrailer?.TRAILER_PROPIO);

  useEffect(() => {
    getParametroCliente();
  }, []);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        onSubmit={guardar}
        mutators={{}}
        subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div
              style={{ width: '800px' }}
              className="relative mx-auto my-auto w-full rounded-xl bg-white p-5  shadow-lg ">
              <div className="">
                <div className="flex-auto justify-center p-1 text-left">
                  <h2 className="py-4 text-xl ">
                    PARAMETROS DEL CLIENTE :{' '}
                    {`${storeState.Cliente.selCliente.TERCERO_NOMBRE1 ?? ''} ${storeState.Cliente.selCliente.TERCERO_APELLIDO1 ?? ''} ${
                      storeState.Cliente.selCliente.TERCERO_RAZON_SOCIAL ?? ''
                    }`}
                  </h2>
                </div>

                <div className="overflow-hidden rounded border-b border-gray-200 shadow">
                  {data.length > 0 ? (
                    <TableParametroCliente
                      data={data}
                      actions={props.actions}></TableParametroCliente>
                  ) : (
                    ''
                  )}
                </div>

                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalAgregarCliente(false)}>Cancelar</ButtonTable>
                  <ButtonTable type="submit">Agregar</ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

//export default Projects;
export default ModalAgregarCliente;

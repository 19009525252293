import { ArchiveBoxIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

export const TableCotizacionTarifaThead = (props) => {
  const { isReadOnly } = props;
  return (
    <thead className="bg-gray-100 text-sm uppercase text-gray-700">
      <tr>
        <th
          scope="col"
          className=" whitespace-nowrap text-left ">
          No.
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap text-left">
          Item
        </th>
        <th
          scope="col"
          className="whitespace-nowrap text-left">
          Tarifa
        </th>
        <th
          scope="col"
          className="whitespace-nowrap text-right">
          Cantidad
        </th>
        <th
          scope="col"
          className="whitespace-nowrap text-right">
          Valor
        </th>
        <th
          scope="col"
          className="whitespace-nowrap text-right">
          Total
        </th>

        <th
          scope="col"
          className="w-6 cursor-pointer hover:text-green-600">
          {!isReadOnly && (
            <PlusCircleIcon
              width={18}
              height={18}
              onClick={() => props.modals.handleShowTarifaModal()}
            />
          )}
        </th>
      </tr>
    </thead>
  );
};

import React from 'react';
import { CheckParameter } from '../../../../../utils/security';
const TheadTableManifiesto = ({ auth }) => {
  return (
    <thead className="bg-gray-800 text-white">
      <tr>
        <th className=" whitespace-nowrap rounded-tl-lg px-2  text-left">C. Int</th>
        <th className=" whitespace-nowrap px-2 text-left">C. Cli</th>
        {CheckParameter('PARAMETRO_ID', 2) && <th className="w-1/3 whitespace-nowrap  px-2 text-left   uppercase">Solicita / Aprueba</th>}
        <th className="w-1/3 whitespace-nowrap px-2 text-left   uppercase">Descripción</th>
        <th className="w-1/3 whitespace-nowrap px-2 text-left   uppercase">Tipo servicio</th>
        <th className="w-1/3 whitespace-nowrap px-2 text-left   uppercase">Tipo Vehículo</th>
        <th className="w-1/3 whitespace-nowrap px-2 text-left   uppercase">Origen</th>
        <th className="w-1/3 whitespace-nowrap  text-left   uppercase">Destino</th>
        <th className="w-1/3 whitespace-nowrap rounded-tr-lg px-2 text-left   uppercase">Fecha</th>
      </tr>
    </thead>
  );
};
export default TheadTableManifiesto;

import React from 'react';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/Test/Filter';
import { ModalAgregarContrato } from '../../../components/specific/Contrato/Modales/agregarContrato';
import TableContrato from '../../../components/specific/Contrato/Table';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';

const ViewTest = (props) => {
  const { modalAgregarContrato, setModalAgregarContrato } = props.modal;
  const { createContrato, validate, updateContrato } = props.endPoint;
  const { getRoles } = props;
  const { loadingTables } = props.actions;
  return (
    <div>
      <Nav />
      <Toaster />
      <div className="md:px-20 w-full py-8">
        <div>
          <h2 className="text-2xl font-semibold leading-tight">CUBS</h2>
        </div>
        <Filter
          setModalAgregarContrato={setModalAgregarContrato}
          data={props.data}
          endPoint={props.endPoint}
        />
      </div>
    </div>
  );
};

export default ViewTest;

import React, { useState } from 'react';

import ViewDashboard from './ViewDashboard';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { authSetSelCliente } from '../../actions';
import { store } from '../../store';
import { useApiClient } from '../../clients/apiAuth';

const Dashboard = (props) => {
  const [customerData, setCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { get, post, put, DELETE } = useApiClient();

  const getDashboard = async () => {
    setIsLoading(true);
    const response = await get(`/dashboard`);
    console.log('response: ', response);
    setCustomerData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getDashboard();
  }, []);

  return <ViewDashboard data={{ customerData, isLoading }} />;
};

const mapStateToProps = function (state) {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(Dashboard);

import { TableVehiculosTbody } from './TableVehiculosTbody';
import { TableVehiculosThead } from './TableVehiculosThead';

export const TableVehiculos = (props) => {
  return (
    <table className="w-full table-auto text-sm">
      <TableVehiculosThead />
      <TableVehiculosTbody
        data={props.data}
        actions={props.actions}
      />
    </table>
  );
};

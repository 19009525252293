import { setEnvironment } from '../actions';
import { store } from '../store';

export const checkAPI_URL = () => {
  const storeState = store.getState();

  const env = process.env.REACT_APP_API_URL;

  if (env) {
    //all it's ok with the environment varible
    if (storeState.environment?.API_URL !== env) {
      console.info('environment varible <> state ', storeState.environment?.API_URL);
      console.info('stored: ', env);
      store.dispatch(setEnvironment({ API_URL: env }));
    }
    return env;
  } else {
    console.info('something is wrong with environment varible', env);

    if (storeState.environment.API_URL) {
      console.info('found API_URL: ', storeState.environment.API_URL);

      return storeState.environment.API_URL;
    }
  }
};

import React, { useState, useEffect } from 'react';
import ViewLugar from './ViewLugar';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { setSelLugar, setLugar, setContrato, showLoadingAnimation, hideLoadingAnimation } from '../../actions';
import toast from 'react-hot-toast';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';

const Lugar = (props) => {
  const auth = props.auth;
  const contrato = props.contrato;
  const lugar = props.lugar;

  const { get, post } = useApiClient();
  const [loadingTables, setLoadingTables] = useState(false);
  const [filterContrato, setFilterContrato] = useState('');

  const getFiltro = () => {
    const lstFiltros = [];

    if (filterContrato.length !== 0) {
      lstFiltros.push(`CONTRATO_ID=${filterContrato}`);
    }

    const firstElement = '?' + lstFiltros[0];

    if (lstFiltros.length === 1) {
      return firstElement;
    }

    lstFiltros.shift();

    if (lstFiltros.length === 1) {
      const restElements = '&' + lstFiltros[0];
      return firstElement.concat(restElements);
    }

    const restElements = lstFiltros.join('&');
    return firstElement + '&' + restElements;
  };

  const getLugar = async () => {
    if (filterContrato === '') {
      return;
    }
    const filtro = getFiltro();

    setLoadingTables(true);
    // const result = await get(`/lugar?CONTRATO_ID=${contratoID}&REFRESH=1`);
    const result = await get(`/lugar${filtro}&REFRESH=1`);
    store.dispatch(setLugar(result));
    setLoadingTables(false);
  };

  const handleDepurar = async () => {
    if (filterContrato === '') {
      return;
    }
    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar los lugares que no tienen servicios?</b>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => {
            toast.dismiss(t.id);
            depurarLugar(filterContrato);
          }}>
          Si
        </button>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </span>
    ));
  };

  const depurarLugar = async (rowID) => {
    store.dispatch(showLoadingAnimation());

    try {
      const result = await post(`/depurarLugares/${rowID}`);

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Lugares depurados!!!' });
        getLugar();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }

    store.dispatch(hideLoadingAnimation());
  };

  const getContrato = async () => {
    try {
      const response = await get(`/contrato?TERCERO_ID=${auth.data.selCliente.TERCERO_ID}`);
      store.dispatch(setContrato(response));
      setFilterContrato(response.data[0]['CONTRATO_ID']);
    } catch (error) {}
  };

  function handleEstado(obj) {}

  useEffect(() => {
    getContrato();
  }, []);

  useEffect(() => {
    getLugar();
  }, [filterContrato]);

  return (
    <ViewLugar
      endPoint={{ getLugar, getContrato }}
      data={{
        auth,
        contrato,
        lugar,
        filterContrato,
        setFilterContrato,
      }}
      actions={{ loadingTables, handleEstado, handleDepurar }}
    />
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    lugar: state.lugar,
    contrato: state.contrato,
  };
};

//export default Projects;
export default connect(mapStateToProps)(Lugar);

import React, { useState, useEffect } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import { CustomToast, OkToast } from '../../Toast';
import { fixDateForBE } from '../../../../utils/format';
import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import Select from 'react-select';
import { ModalTitle } from '../../../global/Styles/titles';

export const ModalAgregarTarifa = (props) => {
  const { auth, contrato } = props.data;
  const { setModalModalAgregarTarifa } = props;
  const { createTarifa, updateTarifa, validate } = props.endPoint;
  const { get } = useApiClient();

  const storeState = store.getState();

  const editMode = storeState.tarifa.selTarifa === null ? false : true;
  const [contratoOptions, setContratoOptions] = useState([]);

  const proxyTask = async (form) => {
    if (editMode) updateTarifa(form);
    else createTarifa(form);
  };

  useEffect(() => {}, []);

  const setCONTRATO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONTRATO_ID'];
    field.change(args[0].value);
  };

  const defaultValueContrato = () => {
    if (editMode) {
      const result = contrato.contrato.find((i) => i.CONTRATO_ID === storeState.tarifa.selTarifa?.CONTRATO_ID);
      return {
        label: `${result?.CONTRATO_NUMERO}`,
        value: result?.CONTRATO_ID,
      };
    }
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-2xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>{editMode ? 'EDITAR' : 'NUEVA'} TARIFA</ModalTitle>
        <Form
          onSubmit={proxyTask}
          initialValues={editMode ? storeState.tarifa.selTarifa : {}}
          validate={validate}
          subscription={{ submitting: true, pristine: true }}
          mutators={{
            setCONTRATO_ID,
          }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <FormStateToRedux form="frmAgregarTarifa" />
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <Field name="TARIFA_CONSECUTIVO">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Consecutivo</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Consecutivo"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="w-1/2">
                      <Field name="TARIFA_CATEGORIA">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Categoria</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Categoria"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="">
                    <Field name="TARIFA_GRUPO">
                      {({ input, meta }) => (
                        <>
                          <p className="text-sm text-gray-800">Grupo</p>
                          <input
                            {...input}
                            type="text"
                            placeholder="Grupo"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <Field name="TARIFA_UNIDAD">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Unidad</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Unidad"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="w-1/2">
                      <Field name="TARIFA_ITEM">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">No. Item</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="No. Item"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="">
                    <Field name="TARIFA_NOMBRE">
                      {({ input, meta }) => (
                        <>
                          <p className="text-sm text-gray-800">Nombre</p>
                          <input
                            {...input}
                            type="text"
                            placeholder="Nombre"
                            className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      {/* +++ SELECT DE CLASE +++ */}
                      <div className="flex-auto justify-center text-left">
                        <p className="text-sm text-gray-800">Contrato</p>

                        <Field name="CONTRATO_ID">
                          {({ input, meta }) => (
                            <>
                              {contrato.contrato.length > 0 ? (
                                <Select
                                  defaultValue={defaultValueContrato}
                                  onChange={form.mutators.setCONTRATO_ID}
                                  //options={contratoOptions}
                                  options={contrato.contrato.map((row) => ({
                                    label: `${row.CONTRATO_NUMERO}`,
                                    value: row.CONTRATO_ID,
                                  }))}
                                  name="selectContrato"
                                  className="mt-2"
                                />
                              ) : (
                                <div className=" flex items-center justify-center">
                                  <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                                </div>
                              )}
                              <input
                                {...input}
                                type="hidden"
                              />
                              {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="w-1/2">
                      <Field name="TARIFA_VALOR">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Valor</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="Valor"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <Field name="F470_ID_ITEM">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">SIESA ITEM ID</p>
                            <input
                              {...input}
                              type="number"
                              placeholder="ITEM ID"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="w-1/2">
                      <Field name="F470_ID_UN_MOVTO">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">SIESA UNIDAD NEGOCIO</p>
                            <input
                              {...input}
                              type="text"
                              placeholder="UNIDAD NEGOCIO"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalModalAgregarTarifa(false)}>Cancelar</ButtonTable>
                  <ButtonTable
                    type="submit"
                    disabled={submitting || pristine}>
                    Agregar
                  </ButtonTable>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    tarifa: state.tarifa,
  };
};

//export default Projects;
export default connect(mapStateToProps)(ModalAgregarTarifa);

import React from 'react';
import { connect } from 'react-redux';

import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { fixOnlyDateForTable } from '../../../../utils/format';
const TbodyTableTipoVehiculo = (props) => {
  const infoTipoVehiculo = props.tipoVehiculo.tipoVehiculo;

  const { handleDeleteTipoVehiculo, handleEdit } = props.actions;

  return (
    <tbody className="">
      {infoTipoVehiculo.map((item) => (
        <tr
          key={item.VEHICULO_TIPO_ID}
          className="border-b">
          <td className="px-1 text-left">{item.VEHICULO_TIPO_ID}</td>
          <td className=" px-1 text-left">{item.CLASE_DESCRIPCION}</td>
          <td className=" px-1 text-left">{item.VEHICULO_TIPO_DESCRIPCION}</td>
          <td className=" px-1 text-left">{item.VEHICULO_TIPO_BUS_GENERICO ? item.VEHICULO_TIPO_BUS_GENERICO : ''}</td>
          <td className=" px-1 text-left">{item.VEHICULO_TIPO_BUS_GRUPO ? item.VEHICULO_TIPO_BUS_GRUPO : ''}</td>
          <td className=" px-1 text-left">{fixOnlyDateForTable(item.UPDATED_AT)}</td>
          <td className=" px-1 text-left">{fixOnlyDateForTable(item.CREATED_AT)}</td>
          <td className=" ">
            <PencilIcon
              className="h-5 w-5 transform cursor-pointer  transition  duration-300 hover:scale-110"
              onClick={() => handleEdit(item)}
            />
          </td>
          <td className=" ">
            <TrashIcon
              className="h-5 w-5 transform cursor-pointer  transition  duration-300 hover:scale-110"
              onClick={() => handleDeleteTipoVehiculo(item.VEHICULO_TIPO_ID)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { tipoVehiculo: state.tipoVehiculo };
};

export default connect(mapStateToProps)(TbodyTableTipoVehiculo);

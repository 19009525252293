import React from 'react';
import Logo from '../../../img/Logo.png';

import LogoApp from '../../../img/logoApp.png';

import { Toaster } from 'react-hot-toast';

import LoadingBoard from '../../../components/global/LoadingBoard';
import { TruckIcon } from '@heroicons/react/24/solid';

const ViewLandingPage = ({ form, setForm, login, history, idiom, loadingBoard }) => {
  const handleEnter = (info) => {
    if (info.key === 'Enter') login();
  };

  return (
    <section className="flex h-screen items-center justify-center bg-gray-800">
      {loadingBoard ? <LoadingBoard /> : null}
      <Toaster />
      <div className="w-full max-w-md space-y-4 rounded bg-white p-6">
        <div className="flex justify-center">
          <img
            src={LogoApp}
            alt="Logo App"
            className="h-52"
          />
        </div>
        <div className="flex justify-center">
          <p className="flex gap-4 font-bold text-gray-700">
            Transport <TruckIcon className="h-6 w-6 text-blue-500" />
          </p>
        </div>
        <div>
          <input
            className="focus:outline-none w-full rounded border border-gray-200 bg-gray-50 p-4 text-sm text-gray-600"
            type="text"
            placeholder="Correo"
            onChange={(e) => setForm({ ...form, email: e.target.value })}
            onKeyPress={(e) => handleEnter(e)}
          />
        </div>
        <div>
          <input
            className="focus:outline-none w-full rounded border border-gray-200 bg-gray-50 p-4 text-sm text-gray-600"
            type="password"
            placeholder="Contraseña"
            onChange={(e) => setForm({ ...form, password: e.target.value })}
            onKeyPress={(e) => handleEnter(e)}
          />
        </div>
        <div>
          <button
            className="w-full rounded bg-blue-600 py-4 text-sm font-bold text-white transition duration-200 hover:bg-blue-700"
            onClick={() => login()}>
            Sign In
          </button>
        </div>
        <div className="flex justify-center">
          <div>
            <a
              className="text-sm text-blue-600 hover:underline"
              href="#">
              Forgot password?
            </a>
          </div>
        </div>

        <div className="flex justify-center">
          <img
            src={Logo}
            className="h-12"
            alt="MR Logo"
          />
        </div>
      </div>
    </section>
  );
};

export default ViewLandingPage;

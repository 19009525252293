import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Trash } from '../../../assets/svg/Trash.svg';
import { formatterPeso } from '../../../../utils/format';

const TbodyTableBono = (props) => {
  const infoBono = props.data?.Bono?.data;

  const { handleEdit, handleDelete } = props.actions;

  return (
    <tbody className="text-gray-700">
      {infoBono &&
        infoBono.map((item) => (
          <tr key={item.BONO_ID}>
            <td className="w-1/5 px-4 py-3 text-left">{item.BONO_NOMBRE}</td>
            <td className={'px-4 py-3 text-left'}>{item.ROL_NOMBRE}</td>
            <td className=" px-4 py-3 text-right">{formatterPeso(item.BONO_VALOR)}</td>
            <td className=" space-x-2 py-3 text-left">
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Edit
                  className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
                  onClick={() => handleEdit(item)}
                />
                <Trash
                  className="hover:fill-blue-500 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
                  onClick={() => handleDelete(item.BONO_ID)}
                />
              </div>
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TbodyTableBono;

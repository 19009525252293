const initialState = {
  loggedIn: false,
  data: undefined,
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'LOG_IN':
      const auth = { ...action.value, loggedIn: true };

      const clientes = action.value.data.clientes;

      if (clientes && clientes.length > 0) {
        const data = { ...auth.data, selCliente: clientes[0] };

        return { ...state, data: data };
      }

      return { ...state, ...auth };

    case 'authLoginRefresh':
      return { ...state };

    case 'authSetSelCliente':
      const newData = { ...state.data, selCliente: action.value };
      return { ...state, data: newData };

    case 'LOG_OUT':
      return { ...state, loggedIn: false, auth: undefined };
    case 'init':
      return { ...state, loggedIn: false, auth: undefined };
    default:
      //
      return state;
  }
};

import React from 'react';
import TbodyTableUnidadNegocio from './tbody';
import TheadTableUnidadNegocio from './thead';
const TableUnidadNegocio = (props) => {
  return (
    <table className="min-w-full bg-white">
      <TheadTableUnidadNegocio />
      <TbodyTableUnidadNegocio actions={props.actions} />
    </table>
  );
};
export default TableUnidadNegocio;

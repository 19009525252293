import React from 'react';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/Trailer/Filter';
import TableTrailer from '../../../components/specific/Trailer/Table';
import { ModalAgregarTrailer } from '../../../components/specific/Trailer/Modales/agregarTrailer';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';

const ViewTrailer = (props) => {
  const { modalAgregarTrailer } = props.modal;
  const { loadingTables } = props.actions;
  return (
    <div>
      <Nav />
      <Toaster />
      <div className="md:px-20 w-full py-8">
        <div>
          <h2 className="text-2xl font-semibold leading-tight">Trailers</h2>
        </div>
        <Filter modal={props.modal} />
        <div className="overflow-hidden rounded border-b border-gray-200 shadow">
          {loadingTables ? <LoadingTables /> : <TableTrailer actions={props.actions} />}
        </div>
      </div>
      {modalAgregarTrailer ? (
        <ModalAgregarTrailer
          modal={props.modal}
          actions={props.actions}
          endPoint={props.endPoint}
        />
      ) : null}
    </div>
  );
};

export default ViewTrailer;

import { ArchiveBoxIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

export const TableBonoThead = (props) => {
  console.log(' TableBonoThead props.modals: ', props.modals);
  return (
    <thead className="bg-gray-100 text-sm uppercase text-gray-700">
      <tr>
        <th
          scope="col"
          className=" whitespace-nowrap text-left ">
          Fecha
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap text-left">
          Tercero
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap text-left">
          Tipo
        </th>
        <th
          scope="col"
          className="whitespace-nowrap text-left">
          Causado
        </th>
        <th
          scope="col"
          className="text-right">
          Valor
        </th>
        <th
          scope="col"
          className="text-right">
          (%)
        </th>
        <th
          scope="col"
          className="w-6 cursor-pointer hover:text-green-600">
          <PlusCircleIcon
            width={18}
            height={18}
            onClick={() => props.modals.handleShowBonoModal()}
          />
        </th>
      </tr>
    </thead>
  );
};

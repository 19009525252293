import React from 'react';
const TheadTableTercero = () => {
  return (
    <thead className="  sticky top-0 z-10   bg-gray-100 text-sm  uppercase  text-gray-700 ">
      <tr>
        <th
          scope="col"
          className="w-2"></th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Id
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Nombre
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Tip. Doc.
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Documento
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Razon Social
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Correo
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Roles
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Telefonos
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Dirección
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Municipio
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Estado
        </th>
        <th
          scope="col"
          className="px-1 text-left text-sm font-semibold uppercase">
          Rev.
        </th>
      </tr>
    </thead>
  );
};
export default TheadTableTercero;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Trash } from '../../../assets/svg/Trash.svg';
import { ReactComponent as IconOn } from '../../../assets/svg/switch-on.svg';
import { ReactComponent as IconOff } from '../../../assets/svg/switch-off.svg';

const TbodyTableCentroOperacion = (props) => {
  const infoCentroOperacion = props.CentroOperacion.CentroOperacion;
  const { handleDeleteCentroOperacion, handleEdit, handleEstado } = props.actions;

  return (
    <tbody className="text-gray-700">
      {infoCentroOperacion?.map((obj, index) => (
        <tr key={index}>
          <td className="px-4 py-3 text-left">{obj.REGIONAL_NOMBRE}</td>
          <td className="px-4 py-3 text-left">{obj.CENTRO_ID}</td>

          <td className="px-4 py-3 text-left">
            {obj.MUNICIPIO_CODIGO}-{obj.MUNICIPIO_DESCRIPCION}
          </td>
          <td className="px-4 py-3 text-left">{obj.CENTRO_DIRECCION}</td>
          <td className="px-4 py-3 text-left">{obj.CENTRO_NOMBRE}</td>

          <td className="px-4 py-3 text-left">
            {obj.CENTRO_ESTADO === 1 ? (
              <IconOn
                className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
                onClick={() => handleEstado(obj)}
              />
            ) : (
              <IconOff
                className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
                onClick={() => handleEstado(obj)}
              />
            )}
          </td>
          <td className="flex space-x-2 px-4 py-3 text-left">
            <Edit
              className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleEdit(obj)}
            />
            <Trash
              className="hover:fill-blue-500 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleDeleteCentroOperacion(obj.CENTRO_ID)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { CentroOperacion: state.CentroOperacion };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableCentroOperacion);

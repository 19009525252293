import React from 'react';
import { connect } from 'react-redux';

import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { fixOnlyDateForTable } from '../../../../utils/format';

const TbodyTableVehiculo = (props) => {
  const infoVehiculo = props.vehiculo.vehiculo;

  const { handleEdit, handleDelete } = props.actions;

  return (
    <tbody>
      {infoVehiculo.map((vehiculos, index) => (
        <tr
          key={vehiculos.VEHICULO_ID}
          className="border-b">
          <td className="px-1 text-left">{vehiculos.VEHICULO_ID}</td>
          <td className="px-1 text-left">{vehiculos.MARCA_DESCRIPCION}</td>
          <td className="px-1 text-left">{vehiculos.VEHICULO_PLACA}</td>
          <td className="px-1 text-left">{vehiculos.VEHICULO_LINEA}</td>
          <td className="px-1 text-left">{vehiculos.VEHICULO_MODELO}</td>

          <td className="px-1 text-left">{vehiculos.CLASE_DESCRIPCION}</td>
          <td className="px-1 text-left">{vehiculos.VEHICULO_TIPO_DESCRIPCION}</td>
          <td className="px-1 text-left">{vehiculos.VEHICULO_EJES}</td>
          <td className={`px-1 text-left ${vehiculos.VEHICULO_PROPIO == '1' ? 'text-green-600' : 'text-red-500'}`}>
            {vehiculos.VEHICULO_PROPIO == '1' ? 'SI' : 'NO'}
          </td>
          <td className=" px-1 text-left">{vehiculos.TERCERO_PROPIETARIO_NOMBRE}</td>
          <td className=" px-1 text-left">{vehiculos.TERCERO_TENEDOR_NOMBRE}</td>
          <td className="px-1 text-left">{vehiculos.VEHICULO_CC}</td>
          <td className={`px-1 text-left ${vehiculos.VEHICULO_TRAILER == '1' ? 'text-green-600' : ''}`}>{vehiculos.VEHICULO_TRAILER == '1' ? 'SI' : 'NO'}</td>
          <td className="px-1 text-left">{fixOnlyDateForTable(vehiculos.UPDATED_AT)}</td>
          <td className="px-1 text-left">{fixOnlyDateForTable(vehiculos.CREATED_AT)}</td>

          <td className=" ">
            <PencilIcon
              className="h-5 w-5 transform cursor-pointer  transition  duration-300 hover:scale-110"
              onClick={() => handleEdit(vehiculos)}
            />
          </td>
          <td className=" ">
            <TrashIcon
              className="h-5 w-5 transform cursor-pointer  transition  duration-300 hover:scale-110"
              onClick={() => handleDelete(vehiculos.VEHICULO_ID)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { vehiculo: state.vehiculo };
};

export default connect(mapStateToProps)(TbodyTableVehiculo);

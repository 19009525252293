import React from 'react';
import TbodyTableContrato from './tbody';
import TheadTableContrato from './thead';
const TableContrato = (props) => {
  return (
    <table className="min-w-full bg-white">
      <TheadTableContrato />
      <TbodyTableContrato
        actions={props.actions}
        endPoint={props.endPoint}
      />
    </table>
  );
};
export default TableContrato;

import React from 'react';
import TheadTableSolicitudLogsResumen from './TheadTableSolicitudLogsResumen';
import TbodyTableSolicitudLogsResumen from './TbodyTableSolicitudLogsResumen';
const TableSolicitudLogsResumen = (props) => {
  return (
    <table className="min-w-full bg-white ">
      <TheadTableSolicitudLogsResumen />
      <TbodyTableSolicitudLogsResumen />
    </table>
  );
};
export default TableSolicitudLogsResumen;

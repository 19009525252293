import React from 'react';
const TheadTableMarcas = () => {
  return (
    <thead className="bg-gray-800 text-white">
      <tr>
        <th className="w-1/3 px-4 py-3 text-left text-sm font-semibold uppercase">Id</th>
        <th className="w-1/3 px-4 py-3 text-left text-sm font-semibold uppercase">Marca</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Fecha Actualización</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">Fecha Creación</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">{''}</th>
      </tr>
    </thead>
  );
};
export default TheadTableMarcas;

import { CheckCircleIcon } from '@heroicons/react/24/outline';

export const TableVehiculosThead = (props) => {
  return (
    <thead className="bg-gray-100 text-sm uppercase text-gray-700">
      <tr>
        <th
          scope="col"
          className=" whitespace-nowrap text-left ">
          Vehículo
        </th>
        <th
          scope="col"
          className="">
          <CheckCircleIcon
            width={18}
            height={18}
          />
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap text-left">
          Placa
        </th>
        <th
          scope="col"
          className="whitespace-nowrap text-left">
          Trailer
        </th>
        <th
          scope="col"
          className="text-left">
          Conductor
        </th>
        <th
          scope="col"
          className="text-left">
          Aparejador
        </th>
      </tr>
    </thead>
  );
};

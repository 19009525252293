import React from 'react';
import { connect } from 'react-redux';

import { PlusCircleIcon } from '@heroicons/react/24/outline';

const TableVehiculoDetalles = (props) => {
  const rowVehiculo = props.Solicitud.selSolicitud.vehiculo_tipo.map((row) => ({
    placa: `${row.pivot.VEHICULO_PLACA} [${row.VEHICULO_TIPO_DESCRIPCION}]`,
    trailer: row.TRAILER_PLACA,
    conductor: `${row.pivot.CONDUCTOR_NOMBRE} C.C.  ${row.pivot.CONDUCTOR_DOC} Cel: ${row.pivot.CONDUCTOR_CEL}`,
    provedor: row.pivot.PROVEEDOR_NOMBRE ? `${row.pivot.PROVEEDOR_NOMBRE} C.C.  ${row.pivot.PROVEEDOR_DOC} Cel: ${row.pivot.PROVEEDOR_CEL}` : '...',
    aparejador: row.pivot.APAREJADOR_NOMBRE ? `${row.pivot.APAREJADOR_NOMBRE} C.C.  ${row.pivot.APAREJADOR_DOC} Cel: ${row.pivot.APAREJADOR_CEL}` : '...',
    CONDUCTOR_TERCERO_ID: row.pivot.CONDUCTOR_TERCERO_ID,
    APAREJADOR_TERCERO_ID: row.pivot.APAREJADOR_TERCERO_ID,
  }));

  const { handleGPS, setItemUsuario } = props.actions;
  const { setModalAsignarBono } = props.modal;

  const setBono = (row, ROL_ID) => {
    let label = ROL_ID == 7 ? row.conductor : row.aparejador;
    let value = ROL_ID == 7 ? row.CONDUCTOR_TERCERO_ID : row.APAREJADOR_TERCERO_ID;

    setItemUsuario({ label: label, value: value, ROL_ID: ROL_ID });
    setModalAsignarBono(true);
  };

  return (
    <div>
      {rowVehiculo?.map((row) => (
        <table
          className="mb-2 w-full "
          key={row.placa}>
          <tbody>
            <tr>
              <th className="rounded-tl-lg bg-gray-800 text-xs text-white">Placa</th>
              <td
                className="rounded-tr-lg border text-center text-xs"
                onClick={() => handleGPS(row.placa)}>
                {row.placa}
              </td>
              <td className="rounded-tr-lg border text-center text-xs"></td>
            </tr>
            <tr>
              <th className="bg-gray-800 text-xs text-white">Trailer</th>
              <td className="rounded-tr-lg border text-center text-xs">{row.trailer}</td>
              <td className="rounded-tr-lg border text-center text-xs"></td>
            </tr>
            <tr>
              <th className="bg-gray-800 text-xs text-white">Conductor</th>
              <td className="rounded-tr-lg border text-center text-xs">{row.conductor}</td>
              <td className="rounded-tr-lg border text-center text-xs">
                {row.conductor != '...' ? (
                  <PlusCircleIcon
                    onClick={() => setBono(row, 7)}
                    width={18}
                    height={18}
                  />
                ) : (
                  ''
                )}
              </td>
            </tr>
            <tr>
              <th className="bg-gray-800 text-xs text-white">Provedor</th>
              <td className="rounded-tr-lg border text-center text-xs">{row.provedor}</td>
              <td className="rounded-tr-lg border text-center text-xs"></td>
            </tr>
            <tr>
              <th className="bg-gray-800 text-xs text-white">Aparejador</th>
              <td className="rounded-tr-lg border text-center text-xs">{row.aparejador}</td>
              <td className="rounded-tr-lg border text-center text-xs">
                {row.aparejador != '...' ? (
                  <PlusCircleIcon
                    onClick={() => setBono(row, 14)}
                    width={18}
                    height={18}
                  />
                ) : (
                  ''
                )}
              </td>
            </tr>
          </tbody>
        </table>
      ))}
    </div>
  );
};
const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    Solicitud: state.Solicitud,
  };
};

//export default Projects;
export default connect(mapStateToProps)(TableVehiculoDetalles);

import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { ButtonTable } from '../../../global/Styles/buttons';
import { inputSelectOk, inputSelectError } from '../../../global/Styles/inputs';

const EliminarTarifa = (props) => {
  const { contrato } = props.data;
  const { setModalEliminarTarifa, deleteTarifaContrato } = props.actions;
  const optionSelect = contrato.contrato.map((row) => ({
    label: `${row.CONTRATO_NUMERO}`,
    value: row.CONTRATO_ID,
  }));

  const proxyTask = async (form) => {
    console.log(form);
    deleteTarifaContrato(form.CONTRATO_ID);
    setModalEliminarTarifa(false);
  };

  const setCONTRATO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONTRATO_ID'];
    field.change(args[0].value);
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['CONTRATO_ID'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        validate={validate}
        onSubmit={proxyTask}
        subscription={{ submitting: true, pristine: true }}
        mutators={{
          setCONTRATO_ID,
        }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <h2 className="py-4 text-xl ">Eliminar Tarifas</h2>

                  <FormStateToRedux form="frmAgregarTarifa" />
                  <div className="flex space-x-4">
                    <div className="flex-auto justify-center text-left">
                      <p className="text-sm text-gray-800">Contrato</p>
                      <Field name="CONTRATO_ID">
                        {({ input, meta }) => (
                          <>
                            {contrato.contrato.length > 0 ? (
                              <Select
                                onChange={form.mutators.setCONTRATO_ID}
                                options={optionSelect}
                                name="selectContrato"
                                className={meta.error && meta.touched ? `${inputSelectOk}` : `${inputSelectError}`}
                              />
                            ) : (
                              <div className=" flex items-center justify-center">
                                <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                              </div>
                            )}
                            <input
                              {...input}
                              type="text"
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable type="submit">Eliminar</ButtonTable>
                  <ButtonTable onClick={() => setModalEliminarTarifa(false)}>Cancelar</ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    Company: state.Company,
  };
};

//export default Projects;
export default connect(mapStateToProps)(EliminarTarifa);

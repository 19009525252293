const PageHeading = (props) => {
  return (
    <div className="sm:px-6 lg:px-4 mx-6 mt-4">
      <div className="sm:flex-row my-2 flex flex-col justify-between">
        <div className="sm:mb-0 mb-1 flex   flex-row space-x-6">
          <div className="h-14 w-1/2 flex-none">
            <label className="text-2xl font-semibold leading-tight">{props.title}</label>
          </div>
          <div className="w-1/2 flex-initial"></div>
          <div className="flex w-1/2  flex-row-reverse">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageHeading;

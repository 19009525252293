import React, { useState, useEffect } from 'react';
import ViewUnidadNegocio from './ViewUnidadNegocio';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setUnidadNegocio, setSelUnidadNegocio, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const UnidadNegocio = (props) => {
  const { get, post, put, DELETE } = useApiClient();
  const [loadingTables, setLoadingTables] = useState(false);
  const [modalAgregarUnidadNegocio, setModalModalAgregarUnidadNegocio] = useState(false);
  const storeState = store.getState();

  const getUnidadNegocio = async () => {
    setLoadingTables(true);
    try {
      const result = await get(`/unidadNegocioGrupo`);

      store.dispatch(setUnidadNegocio(result));
    } catch (error) {}
    setLoadingTables(false);
  };

  const createUnidadNegocio = async (form) => {
    try {
      const result = await post(
        '/unidadNegocioGrupo',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalModalAgregarUnidadNegocio(false);
        OkToast({ result, message: 'UnidadNegocio Agregada!' });
        getUnidadNegocio();
      }
    } catch (error) {}
  };

  const updateUnidadNegocio = async (form) => {
    try {
      const result = await put(
        `/unidadNegocioGrupo/${storeState.UnidadNegocio.selUnidadNegocio.NEGOCIO_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalModalAgregarUnidadNegocio(false);
        OkToast({ result, message: 'Unidad de Negocio Actualizada!' });
        getUnidadNegocio();
      }
    } catch (error) {}
  };

  function handleEdit(obj) {
    store.dispatch(setSelUnidadNegocio(obj));
    setModalModalAgregarUnidadNegocio(true);
  }
  const deleteUnidadNegocio = async (rowID) => {
    store.dispatch(showLoadingAnimation());
    try {
      const result = await DELETE(`/unidadNegocioGrupo/${rowID}`);
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Unidad de Negocio Eliminada!' });
        getUnidadNegocio();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }
    store.dispatch(hideLoadingAnimation());
  };

  function handleDeleteUnidadNegocio(rowID) {
    toast((t) => (
      <span>
        <b>¿Está seguro de querer eliminar Unidad Negocio?</b>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => {
            toast.dismiss(t.id);
            deleteUnidadNegocio(rowID);
          }}>
          Sí
        </button>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </span>
    ));
  }

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['NEGOCIO_ID', 'NEGOCIO_NOMBRE'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  useEffect(() => {
    getUnidadNegocio();
  }, []);

  return (
    <ViewUnidadNegocio
      endPoint={{ getUnidadNegocio, createUnidadNegocio, updateUnidadNegocio }}
      modal={{
        modalAgregarUnidadNegocio,
        setModalModalAgregarUnidadNegocio,
        validate,
        createUnidadNegocio,
      }}
      actions={{ loadingTables, handleDeleteUnidadNegocio, handleEdit }}
    />
  );
};

const mapStateToProps = function (state) {
  return { UnidadNegocio: state.UnidadNegocio };
};

//export default Projects;
export default connect(mapStateToProps)(UnidadNegocio);

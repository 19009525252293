import React from 'react';
const TheadTableParametroGeneral = () => {
  return (
    <thead className="  sticky top-0 z-10   bg-gray-100 text-sm  uppercase  text-gray-700 ">
      <tr>
        <th
          scope="col"
          className="px-1 text-left   uppercase">
          Id
        </th>
        <th
          scope="col"
          className=" px-1 text-left   uppercase">
          Descripción
        </th>
        <th
          scope="col"
          className=" px-1 text-left   uppercase">
          Valor
        </th>
        <th
          scope="col"
          className=" px-1 text-left   uppercase">
          solo cliente
        </th>
        <th
          scope="col"
          className=" px-1 text-left   uppercase">
          Tipo
        </th>
        <th
          scope="col"
          className=" px-1 text-left   uppercase">
          Fecha Actualización
        </th>
        <th
          scope="col"
          className=" px-1 text-left   uppercase">
          Fecha Creación
        </th>
        <th
          scope="col"
          className="w-2"></th>
        <th
          scope="col"
          className="w-2"></th>
      </tr>
    </thead>
  );
};
export default TheadTableParametroGeneral;

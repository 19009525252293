import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Trash } from '../../../assets/svg/Trash.svg';
import { fixOnlyDateForTable } from '../../../../utils/format';

const TbodyTableTrailer = (props) => {
  const infoTrailer = props.trailer.trailer;

  const { handleEdit, handleDelete } = props.actions;

  return (
    <tbody className="text-gray-700">
      {infoTrailer.map((trailers, index) => (
        <tr key={index}>
          <td className="px-4 py-3 text-left">{trailers.TRAILER_ID}</td>
          <td className="w-1/5 px-4 py-3 text-left">{trailers.MARCA_DESCRIPCION}</td>
          <td className="px-4 py-3 text-left">{trailers.TRAILER_PLACA}</td>
          <td className={`px-4 py-3 text-left ${trailers.TRAILER_PROPIO == '1' ? '' : 'text-red-500'}`}>{trailers.TRAILER_PROPIO == '1' ? 'SI' : 'NO'}</td>
          <td className="px-4 py-3 text-left">{fixOnlyDateForTable(trailers.UPDATED_AT)}</td>
          <td className="px-4 py-3 text-left">{fixOnlyDateForTable(trailers.CREATED_AT)}</td>
          <td className="flex space-x-2 py-3 text-left">
            <Edit
              className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleEdit(trailers)}
            />
            <Trash
              className="hover:fill-blue-500 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleDelete(trailers.TRAILER_ID)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { trailer: state.trailer };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableTrailer);

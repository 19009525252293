import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ButtonSmallTable } from '../../../../global/Styles/buttons';
import Select from 'react-select';
import { FaUserTag } from 'react-icons/fa';
import { store } from '../../../../../store';
import { authSetSelCliente } from '../../../../../actions';
import { useHistory } from 'react-router-dom';

export const ModalSelectClient = (props) => {
  const { setModalSelectClient, setCliente } = props;
  const auth = props.auth;
  const [terceroID, setTerceroID] = useState(null);
  const history = useHistory();

  const onSelectClient = () => {
    const cliente = auth.data.clientes.find((row) => row.TERCERO_ID == terceroID);
    store.dispatch(authSetSelCliente(cliente));
    setCliente(cliente);
    history.push('/dashboard');
    setModalSelectClient(false);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>

      <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
        <div className="">
          <div className="flex-auto justify-center p-5 text-left">
            <h2
              style={{ display: 'flex' }}
              className="py-4 text-xl ">
              <span style={{ marginRight: '5px', marginTop: '5px' }}>
                <FaUserTag></FaUserTag>
              </span>
              Clientes
            </h2>
            <Select
              options={auth.data.clientes.map((row) => ({
                value: row.TERCERO_ID,
                label: row.TERCERO_RAZON_SOCIAL,
              }))}
              onChange={(e) => setTerceroID(e.value)}
              name="selectRol"
              className=""
            />
          </div>
          <div className="md:block mx-16 mt-2 space-x-4 text-center">
            <ButtonSmallTable onClick={() => setModalSelectClient(false)}>Cancelar</ButtonSmallTable>
            <ButtonSmallTable onClick={() => onSelectClient()}>Seleccionar</ButtonSmallTable>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
  };
};

//export default Projects;
export default connect(mapStateToProps)(ModalSelectClient);

import React from 'react';
import Nav from '../../../components/specific/Navigation';

import TableVehiculo from '../../../components/specific/Vehiculo/Table';
import { ModalAgregarVehiculo } from '../../../components/specific/Vehiculo/Modales/agregarVehiculo';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';

import { ButtonTable } from '../../../components/global/Styles/buttons';
import { store } from '../../../store';
import { setSelVehiculoInit } from '../../../actions';
import { TitlesTables } from '../../../components/global/Styles/titles';

const ViewVehiculo = (props) => {
  const { modalAgregarVehiculo, setModalAgregarVehiculo, createVehiculo, updateVehiculo, validate } = props.modal;
  const { loadingTables } = props.data;

  const handleClick = () => {
    store.dispatch(setSelVehiculoInit());
    setModalAgregarVehiculo(true);
  };

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'w-1/2',
        }}
      />
      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>VEHÍCULOS</TitlesTables>
            <div className="flex-auto"></div>

            <ButtonTable onClick={() => handleClick()}>Agregar</ButtonTable>
          </div>

          {loadingTables && <LoadingTables />}
          <TableVehiculo
            data={props.data}
            actions={props.actions}
          />
        </div>
      </div>

      {modalAgregarVehiculo && (
        <ModalAgregarVehiculo
          agregarVehiculo={{ createVehiculo, validate, updateVehiculo }}
          setModalAgregarVehiculo={setModalAgregarVehiculo}
        />
      )}
    </div>
  );
};

export default ViewVehiculo;

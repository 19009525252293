import React from 'react';
import { ReactComponent as Trash } from '../../../../assets/svg/Trash.svg';
const TableBody = (props) => {
  const data = props.data?.clienteUsuarios;
  const { handleDeleteUsuarioCliente } = props.actions;
  return (
    <tbody className="rounded-lg text-gray-700">
      {data?.map((row, index) => (
        <tr key={row.TERCERO_CLIENTE_ID}>
          <td className="w-1/3 px-4 py-3 text-left">{row?.TERCERO_NOMBRE_BUSQUEDA}</td>
          <td className="w-1/3 px-4 py-3 text-left">{row?.TERCERO_DOCUMENTO}</td>
          <td className="w-1/3 px-4 py-3 text-left">{row?.TERCERO_RAZON_SOCIAL}</td>
          <td className="w-1/3 px-4 py-3 text-left">{row?.TERCERO_MAIL}</td>
          <td className="flex w-1/3 py-3  text-center">
            <Trash
              className="hover:fill-blue-500 h-16 w-16 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleDeleteUsuarioCliente(row?.TERCERO_CLIENTE_ID)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};
export default TableBody;

import { CheckCircleIcon } from '@heroicons/react/24/solid';

const CheckedCell = ({ id, selId }) => {
  if (id === selId)
    return (
      <CheckCircleIcon
        width={18}
        height={18}
      />
    );
};

export const TableClienteTbody = (props) => {
  const dataRows = [];
  console.log('props: ', props);
  const clientData = props.data.selCliente;
  console.log('clientData: ', clientData);

  dataRows.push(clientData);

  return (
    <tbody className="">
      {dataRows?.map((obj) => (
        <tr
          key={obj.TERCERO_DOCUMENTO}
          className="border-b text-xs">
          <td className=" whitespace-nowrap   text-left">{obj.TERCERO_DOCUMENTO}</td>
          <td className="whitespace-nowrap">{obj.TERCERO_NOMBRE_BUSQUEDA}</td>
          <td className=" text-left">{obj.TERCERO_DIRECCION}</td>
          <td className=" text-left">{obj.MUNICIPIO_DESCRIPCION}</td>
          <td className=" text-left">{obj.TERCERO_TEL}</td>
        </tr>
      ))}
    </tbody>
  );
};

import React from 'react';
import Nav from '../../../components/specific/Navigation';

import { Toaster } from 'react-hot-toast';

import { TitlesTables } from '../../../components/global/Styles/titles';

import LoadingTables from '../../../components/global/LoadingTables';

import TableAnticipo from '../../../components/specific/Servicios/TableAnticipo/TableAnticipo';
import ModalAnticipo from '../../../components/specific/Servicios/Modales/ModalAnticipo';
import { ModalAnticipoConsulta } from '../../../components/specific/Servicios/Modales/ModalAnticipoConsulta';
import Pager from '../../../components/global/Pager';

const selectedTab =
  'relative block border-b-2 border-l border-r border-t-2  bg-gray-100 border-gray-200   p-4  font-medium text-gray-800 cursor-pointer rounded-t-md';
const noSelectedTab =
  ' border-l border-b-2 border-r border-t-2 border-gray-200  block p-4  font-medium text-gray-500 hover:text-gray-800 cursor-pointer rounded-t-md';
const checkIsTabSelected = (key, estadoAprobado) => {
  if (key === estadoAprobado) {
    return selectedTab;
  }
  return noSelectedTab;
};

const ViewAnticipos = (props) => {
  const { isModalAnticipoVisible, setIsModalAnticipoVisible, isModalAnticipoConsultaVisible, setIsModalAnticipoConsultaVisible } = props.modales;

  const {
    handleModalRechazar,
    handleEdit,
    handleChangeTarifaUnidad,
    handleAddTarifa,
    handleDeleteDetalle,
    loadingTables,
    handleTabClick,
    createAsignarBono,
    updateAsignarBono,
    getAsignarBono,
    handleEditBonoAsignado,
    handleDeleteBonoAsignado,
    setBonoAsignado,
    createSolicitudDetalle,
    setTipoSolicitud,
  } = props.actions;

  const { solicitud, auth, selectedTab } = props.data;

  const { handleNextPage, handlePreviusPage } = props.pager;

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'w-1/2',
        }}
      />
      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>ANTICIPOS</TitlesTables>
            <div className="flex-auto"></div>
          </div>

          {/* TABS */}
          <ul className=" flex text-center">
            <li className="flex-1">
              <div></div>
            </li>

            <li className="flex-1">
              <div></div>
            </li>
            <li className="flex-1">
              <div
                className={checkIsTabSelected(1, selectedTab)}
                onClick={() => handleTabClick(1)}>
                Por aprobar
              </div>
            </li>
            <li className="flex-1">
              <div
                className={checkIsTabSelected(2, selectedTab)}
                onClick={() => handleTabClick(2)}>
                Aprobados
              </div>
            </li>

            <li className="flex-1">
              <div
                className={checkIsTabSelected(3, selectedTab)}
                onClick={() => handleTabClick(3)}>
                Historico
              </div>
            </li>
          </ul>
          {/* </div> */}

          {loadingTables && <LoadingTables />}
          <TableAnticipo
            endPoint={props.endPoint}
            data={{ solicitud, auth, loadingTables }}
            modals={{
              isModalAnticipoVisible,
              setIsModalAnticipoVisible,
              isModalAnticipoConsultaVisible,
              setIsModalAnticipoConsultaVisible,
            }}
            handleEdit={handleEdit}
          />
          <Pager
            data={solicitud}
            pager={{ handleNextPage, handlePreviusPage }}
          />
        </div>
      </div>

      {isModalAnticipoVisible && (
        <ModalAnticipo
          setIsModalAnticipoVisible={setIsModalAnticipoVisible}
          data={props.data}
          endPoint={props.endPoint}
          actions={{ handleChangeTarifaUnidad, handleAddTarifa, handleDeleteDetalle }}
        />
      )}
      {isModalAnticipoConsultaVisible && (
        <ModalAnticipoConsulta
          setIsModalAnticipoConsultaVisible={setIsModalAnticipoConsultaVisible}
          data={props.data}
          endPoint={props.endPoint}
          actions={{ handleChangeTarifaUnidad, handleAddTarifa, handleDeleteDetalle }}
        />
      )}
    </div>
  );
};

//export default ViewProjects;

export default ViewAnticipos;

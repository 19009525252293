import React, { useState, useEffect } from 'react';

import { Form, Field } from 'react-final-form';
import { store } from '../../../../store';
import { useApiClient } from '../../../../clients/apiAuth';

import FormStateToRedux from '../../RFF/FormStateToRedux';
import Select from 'react-select';
import { inputsSettingsTrue, inputsSettingsFalse, inputTextAreaTrue, inputTextAreaFalse, inputSelectOk, inputSelectError } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import { validateEmails } from '../../../../utils/format';
import { hideLoadingAnimation, showLoadingAnimation } from '../../../../actions';
import { CheckParameter } from '../../../../utils/security';
import { HeroCheckIcon } from '../../../global/HeroCheck';
import { ModalTitle } from '../../../global/Styles/titles';

const FieldsRowContratoZona = ({ zonaOptions, centroOperacion, form, storeState }) => {
  return (
    <div className="flex space-x-4">
      <div className="mt-1 w-1/2">
        <p className="mt-3 text-sm text-gray-800">Contrato / Zona</p>
        {/* +++ SELECT +++ */}
        {zonaOptions.length > 0 ? (
          <Select
            options={zonaOptions}
            defaultValue={zonaOptions.find((i) => i.value === storeState.Solicitud?.selSolicitud?.ZONA_ID)}
            name="selectVehiculoTipo"
            onChange={form.mutators.setZONA_ID}
          />
        ) : (
          <div className=" flex items-center justify-center ">
            <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
          </div>
        )}
        <Field name="ZONA_ID">
          {({ input, meta }) => (
            <>
              <input
                {...input}
                type="hidden"
              />
              {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
            </>
          )}
        </Field>
        {/* --- SELECT --- */}
      </div>
      <div className="mt-1 w-1/2">
        <p className="mt-3 text-sm text-gray-800">Centro de operaciones</p>

        {centroOperacion.length > 0 ? (
          <Select
            options={centroOperacion}
            //defaultValue={lstVehiculoTipoOptions}
            name="selectCentroOperacion"
            onChange={form.mutators.setCENTRO_ID}
          />
        ) : (
          <div className=" flex items-center justify-center ">
            <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
          </div>
        )}
        <Field name="CENTRO_ID">
          {({ input, meta }) => (
            <>
              <input
                {...input}
                type="hidden"
              />
              {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
            </>
          )}
        </Field>
      </div>
    </div>
  );
};

const FieldsRowTipoCarga = ({ cargaTipoOptions, storeState, isRequiereManifiesto, handleCheckReqManifiestoClick, handleChangeCargaTipo }) => {
  return (
    <div className="flex space-x-4">
      <div className="mt-1 w-1/2">
        <p className="mt-3 text-sm text-gray-800">Tipo de carga</p>
        {/* +++ SELECT +++ */}
        {cargaTipoOptions.length > 0 ? (
          <Select
            options={cargaTipoOptions}
            defaultValue={cargaTipoOptions.find((i) => i.value === storeState.Solicitud?.selSolicitud?.CARGA_TIPO_ID)}
            name="selectVehiculoTipo"
            onChange={handleChangeCargaTipo}
          />
        ) : (
          <div className=" flex items-center justify-center ">
            <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
          </div>
        )}
        <Field name="CARGA_TIPO_ID">
          {({ input, meta }) => (
            <>
              <input
                {...input}
                type="hidden"
              />
              {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
            </>
          )}
        </Field>
        {/* --- SELECT --- */}
      </div>
      <div className="mt-1  w-1/2 ">
        <p className="mt-3 text-sm text-gray-800">Opciones</p>

        <p className="flex gap-2 pt-2 text-sm text-gray-800">
          <HeroCheckIcon
            enable={isRequiereManifiesto}
            handleClick={handleCheckReqManifiestoClick}
          />{' '}
          Requiere manifiesto
        </p>
        <Field name="SOLICITUD_REQ_MANIFIESTO">
          {({ input, meta }) => (
            <>
              <input
                {...input}
                type="hidden"
              />
              {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
            </>
          )}
        </Field>
      </div>
    </div>
  );
};

export const ModalNuevaSolicitud = (props) => {
  const { setNuevoServicio } = props.modales;

  const { createServicio } = props.endPoint;

  const { auth } = props.data;

  const { post, get, put } = useApiClient();
  const storeState = store.getState();

  const editMode = storeState.Solicitud?.selSolicitud === null ? false : true;

  const [selContratoID, setSelContratoID] = useState(null);
  const [selZonaID, setSelZonaID] = useState(null);
  const [isRequiereManifiesto, setIsRequiereManifiesto] = useState(true);

  //+++ ELEMENTOS PARA EL SELECT DE SOLICITUDES +++
  const [solicitudesOptions, setSolicitudesOptions] = useState([]); //12

  const getSolicitudes = async () => {
    store.dispatch(showLoadingAnimation());

    try {
      const response = await get(`/tercero?ROL_ID=12`);

      const lst = response.data.map((row) => ({
        label: `${row.TERCERO_NOMBRE_BUSQUEDA}`,
        value: row.TERCERO_ID,
      }));
      setSolicitudesOptions(lst);
    } catch (error) {}

    store.dispatch(hideLoadingAnimation());
  };

  const [aprobadorOptions, setAprobadorOptions] = useState([]); //2

  const getAprobador = async () => {
    store.dispatch(showLoadingAnimation());

    try {
      const response = await get(`/tercero?ROL_ID=2`);

      const lst = response.data.map((row) => ({
        label: `${row.TERCERO_NOMBRE_BUSQUEDA}`,
        value: row.TERCERO_ID,
      }));

      setAprobadorOptions(lst);
    } catch (error) {}

    store.dispatch(hideLoadingAnimation());
  };

  const [lugarOptions, setLugarOptions] = useState([]);

  const getLugar = async (lugarID = selContratoID) => {
    store.dispatch(showLoadingAnimation());

    try {
      const response = await get(`/lugar?CONTRATO_ID=${lugarID}`);

      const lst = response.data.map((row) => ({
        label: `${row.LUGAR_DESCRIPCION}`,
        value: row.LUGAR_ID,
      }));

      //
      setLugarOptions(lst);
    } catch (error) {}

    store.dispatch(hideLoadingAnimation());
  };

  const [zonaOptions, setZonaOptions] = useState([]);

  const getZona = async () => {
    store.dispatch(showLoadingAnimation());

    try {
      const response = await get(`/zona?TERCERO_ID=${auth.data.selCliente.TERCERO_ID}`);

      const lst = response.data.map((row) => ({
        label: `${row.CONTRATO_NUMERO} - ${row.ZONA_DESCRIPCION}`,
        value: row.ZONA_ID,
        contratoID: row.CONTRATO_ID,
        prefijo: row.ZONA_PREFIJO, //TODO Agregar prefijo al codigo del servicio
      }));

      setZonaOptions(lst);
      // getLugar(lst[0].CONTRATO_ID);

      // setSelContratoID(lst[0].contratoID);
    } catch (error) {}

    store.dispatch(hideLoadingAnimation());
  };

  const [vehiculoTipoOptions, setVehiculoTipoOptions] = useState([]);

  const getVehiculoTipo = async () => {
    store.dispatch(showLoadingAnimation());

    try {
      const response = await get(`/vehiculoTipo`);

      const lst = response.data.map((row) => ({
        label: `${row.VEHICULO_TIPO_DESCRIPCION}`,
        value: row.VEHICULO_TIPO_ID,
      }));

      //
      setVehiculoTipoOptions(lst);
    } catch (error) {}

    store.dispatch(hideLoadingAnimation());
  };

  const [unidadNegocio, setUnidadNegocio] = useState([]);
  const getUnidadNegocio = async () => {
    store.dispatch(showLoadingAnimation());

    try {
      const response = await get(`/unidadNegocioGrupo`);

      const lst = response.data.map((row) => ({
        label: `${row.NEGOCIO_NOMBRE}`,
        value: row.NEGOCIO_ID,
      }));

      //
      setUnidadNegocio(lst);
    } catch (error) {}

    store.dispatch(hideLoadingAnimation());
  };

  const [centroOperacion, setcentroOperacion] = useState([]);
  const getCentroOperacion = async () => {
    store.dispatch(showLoadingAnimation());

    try {
      const response = await get(`/centroOperacion`);

      const lst = response.data.map((row) => ({
        label: `${row.CENTRO_NOMBRE}`,
        value: row.CENTRO_ID,
      }));

      //
      setcentroOperacion(lst);
    } catch (error) {}

    store.dispatch(hideLoadingAnimation());
  };

  const [cargaTipoOptions, setCargaTipoOptions] = useState([]);
  const getCargaTipos = async () => {
    try {
      const response = await get(`/cargaTipo`);

      const lst = response.data.map((row) => ({
        label: `${row.CARGA_TIPO_NOMBRE}`,
        value: row.CARGA_TIPO_ID,
      }));

      //
      setCargaTipoOptions(lst);
    } catch (error) {}
  };

  // +++ MUTADORES +++
  const setLUGAR_ID_ORIGEN = (args, state, { setIn, changeValue }) => {
    const field = state.fields['LUGAR_ID_ORIGEN'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setLUGAR_ID_DESTINO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['LUGAR_ID_DESTINO'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };
  const setTERCERO_ID_S = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TERCERO_ID_S'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };
  const setTERCERO_ID_A = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TERCERO_ID_A'];

    field.change(args[0].value);
    state.formState.submitFailed = true;
  };
  const setZONA_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['ZONA_ID'];

    field.change(args[0].value);

    setSelContratoID(args[0].contratoID);
    state.formState.submitFailed = true;
  };

  const setSOLICITUD_REQ_MANIFIESTO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['SOLICITUD_REQ_MANIFIESTO'];

    field.change(!isRequiereManifiesto);

    setIsRequiereManifiesto(!isRequiereManifiesto);
    state.formState.submitFailed = true;
  };

  const setVEHICULO_TIPO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['VEHICULO_TIPO_ID'];

    field.change(args[0]);
    state.formState.submitFailed = true;
  };

  const setTERCERO_UNIDAD_NEGOCIO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TERCERO_UNIDAD_NEGOCIO'];

    field.change(Array.isArray(args[0]) ? args[0] : [args[0]]);

    state.formState.submitFailed = true;
  };

  const setCENTRO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CENTRO_ID'];

    field.change(args[0].value);

    state.formState.submitFailed = true;
  };

  const setCARGA_TIPO_ID = (args, state) => {
    const field = state.fields['CARGA_TIPO_ID'];

    field.change(args[0].value);

    state.formState.submitFailed = true;
  };

  const handleCheckReqManifiestoClick = (e) => {
    //
    setIsRequiereManifiesto(!isRequiereManifiesto);
  };

  //SE EJECUTA SIEMPRE UNA SOLA VEZ AL INICIAR EL COMPONENTE.
  useEffect(() => {
    getVehiculoTipo();
    getSolicitudes();
    getAprobador();
    getZona();
    getUnidadNegocio();
    getCentroOperacion();
    getCargaTipos();
  }, []);

  useEffect(() => {
    if (!selContratoID) return;
    getLugar();
  }, [selContratoID]);

  //SE DEBE CREAR UNA LISTA CON LOS VALORES EN EL OBJETO SELECCIONADO Y PASAR COMO defaultValue EN EL SELECT
  const lstVehiculoTipoOptions = storeState.Solicitud?.selSolicitud?.vehiculo_tipo.map((row) => ({
    label: `${row.VEHICULO_TIPO_DESCRIPCION}`,
    value: row.VEHICULO_TIPO_ID,
  }));

  const getSolicitudTipofromState = (solicitudTransporte, solicitudIzaje) => {
    const lstValues = [];

    if (solicitudTransporte === 1) lstValues.push('transporte');
    if (solicitudIzaje === 1) lstValues.push('izaje');

    return lstValues;
  };

  //COMO EL TIPO ESTA EN CAMPOS DIFERENTES EN LA BD SE DEBEN UNIR LOS VALORES PARA EL CAMPO SOLICITUD_TIPO EN FRONT
  const lstSolicitudIipo = getSolicitudTipofromState(
    storeState.Solicitud?.selSolicitud?.SOLICITUD_TRANSPORTE,
    storeState.Solicitud?.selSolicitud?.SOLICITUD_IZAJE
  );

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['SOLICITUD_DESCRIPCION'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });

    const mails = values['SOLICITUD_NOTIFICACION'];

    if (mails && mails.length > 0 && !validateEmails(mails)) {
      errors['SOLICITUD_NOTIFICACION'] = `*`;
    }

    return errors;
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-4xl rounded-xl bg-white p-5  shadow-lg ">
        <div className="">
          <div className="flex-auto justify-center p-5 text-left">
            {/* <h2 className="text-xl">{editMode ? 'Clonar' : 'Nuevo'} Servicio. </h2> */}
            <ModalTitle>{editMode ? 'EDITAR' : 'NUEVO'} SERVICIO</ModalTitle>
            <Form
              onSubmit={createServicio}
              validate={validate}
              initialValues={
                editMode
                  ? {
                      ...storeState.Solicitud?.selSolicitud,
                      VEHICULO_TIPO_ID: lstVehiculoTipoOptions,
                      SOLICITUD_TIPO: lstSolicitudIipo,
                    }
                  : {
                      TERCERO_ID: storeState.auth.data.user.TERCERO_ID,
                      SOLICITUD_REQ_MANIFIESTO: true,
                      SOLICITUD_FECHA_REQUERIMIENTO: new Date().toISOString().split('T')[0],
                      // CONSECUTIVO: CheckParameter('PARAMETRO_ID', 1) ? '' : 'Automático',
                    }
              }
              mutators={{
                setLUGAR_ID_ORIGEN,
                setLUGAR_ID_DESTINO,
                setVEHICULO_TIPO_ID,
                setTERCERO_ID_S,
                setTERCERO_ID_A,
                setZONA_ID,
                setTERCERO_UNIDAD_NEGOCIO,
                setCENTRO_ID,
                setSOLICITUD_REQ_MANIFIESTO,
                setCARGA_TIPO_ID,
              }}
              subscription={{ submitting: true, pristine: true }}>
              {({ handleSubmit, form, submitting, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <FormStateToRedux form="frmNuevaSolicitud" />
                  <div className="">
                    <div className="flex-auto justify-center p-5 text-left">
                      <div className="flex space-x-4">
                        <div className="w-1/2">
                          <p className="text-sm text-gray-800">Fecha y Hora Servicio</p>

                          <Field name="SOLICITUD_FECHA">
                            {({ input, meta }) => (
                              <>
                                <input
                                  {...input}
                                  type="datetime-local"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="w-1/2">
                          <p className="text-sm text-gray-800">Fecha Solicitud</p>
                          <Field name="SOLICITUD_FECHA_REQUERIMIENTO">
                            {({ input, meta }) => (
                              <>
                                <input
                                  {...input}
                                  type="date"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                          <Field name="SOLICITUD_CONSECUTIVO_INT">
                            {({ input, meta }) => (
                              <>
                                <input
                                  {...input}
                                  type="hidden"
                                  readOnly={!CheckParameter('PARAMETRO_ID', 1)}
                                  placeholder=""
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                        </div>
                      </div>

                      {/* +++ GRUPO DE DOS SELECT +++ */}
                      {CheckParameter('PARAMETRO_ID', 2) ? (
                        <div className="flex space-x-4">
                          <div className="w-1/2">
                            <p className="mt-3 text-sm text-gray-800">Quien solicita</p>

                            {/* +++ SELECT +++ */}
                            {solicitudesOptions.length > 0 ? (
                              <Select
                                options={solicitudesOptions}
                                defaultValue={solicitudesOptions.find((i) => i.label === storeState.Solicitud?.selSolicitud?.SOLICITA)}
                                name="selectOrigen"
                                onChange={form.mutators.setTERCERO_ID_S}
                              />
                            ) : (
                              <div className=" flex items-center justify-center ">
                                <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                              </div>
                            )}
                            <Field name="TERCERO_ID_S">
                              {({ input, meta }) => (
                                <>
                                  <input
                                    {...input}
                                    type="hidden"
                                  />
                                  {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                                </>
                              )}
                            </Field>
                            {/* --- SELECT --- */}
                          </div>
                          <div className="w-1/2">
                            <p className="mt-3 text-sm text-gray-800">Aprobador:</p>
                            {/* +++ SELECT +++ */}
                            {aprobadorOptions.length > 0 ? (
                              <Select
                                options={aprobadorOptions}
                                defaultValue={aprobadorOptions.find((i) => i.value === storeState.Solicitud?.selSolicitud?.TERCERO_ID_A)}
                                name="selectDestino"
                                onChange={form.mutators.setTERCERO_ID_A}
                              />
                            ) : (
                              <div className=" flex items-center justify-center ">
                                <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                              </div>
                            )}
                            <Field name="TERCERO_ID_A">
                              {({ input, meta }) => (
                                <>
                                  <input
                                    {...input}
                                    type="hidden"
                                  />
                                  {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                                </>
                              )}
                            </Field>
                            {/* --- SELECT --- */}
                          </div>
                        </div>
                      ) : null}
                      {/* --- GRUPO DE DOS SELECT --- */}

                      {/* +++ GRUPO DE DOS CAMPOS +++ */}
                      <div className="flex space-x-4">
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_CC">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Centro de Costo</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Centro de Costo"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_CONSECUTIVO">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Consecutivo Cliente</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Consecutivo Cliente"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                      {/* --- GRUPO DE DOS CAMPOS --- */}

                      <div className="flex space-x-4">
                        <div className="w-1/2">
                          <p className="mt-3 text-sm text-gray-800">Tipo Vehículo</p>
                          {/* +++ SELECT +++ */}
                          {vehiculoTipoOptions.length > 0 ? (
                            <Select
                              options={vehiculoTipoOptions}
                              defaultValue={lstVehiculoTipoOptions}
                              name="selectVehiculoTipo"
                              isMulti={true}
                              onChange={form.mutators.setVEHICULO_TIPO_ID}
                            />
                          ) : (
                            <div className=" flex items-center justify-center ">
                              <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                            </div>
                          )}
                          <Field name="VEHICULO_TIPO_ID">
                            {({ input, meta }) => (
                              <>
                                <input
                                  {...input}
                                  type="hidden"
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                          {/* --- SELECT --- */}
                        </div>
                        <div className="mt-1 w-1/2">
                          <label>Unidad de negocio</label>
                          {unidadNegocio.length > 0 ? (
                            <Select
                              options={unidadNegocio}
                              //defaultValue={lstVehiculoTipoOptions}
                              name="selectUnidadNegocio"
                              // isMulti={true}
                              onChange={form.mutators.setTERCERO_UNIDAD_NEGOCIO}
                            />
                          ) : (
                            <div className=" flex items-center justify-center ">
                              <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                            </div>
                          )}
                          <Field name="TERCERO_UNIDAD_NEGOCIO">
                            {({ input, meta }) => (
                              <>
                                <input
                                  {...input}
                                  type="hidden"
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                        </div>
                        {/*<div className="mt-1 w-1/2">
                          <label>Tipo de Servicio</label>
                          <div className="mt-1 flex space-x-4">
                            <label>
                              <Field name="SOLICITUD_TIPO" component="input" type="checkbox" value="transporte" /> Transporte
                            </label>
                            <label>
                              <Field name="SOLICITUD_TIPO" component="input" type="checkbox" value="izaje" /> Izaje
                            </label>
                          </div>
                            </div>*/}
                      </div>

                      <FieldsRowTipoCarga
                        cargaTipoOptions={cargaTipoOptions}
                        form={form}
                        storeState={storeState}
                        isRequiereManifiesto={isRequiereManifiesto}
                        handleCheckReqManifiestoClick={form.mutators.setSOLICITUD_REQ_MANIFIESTO}
                        handleChangeCargaTipo={form.mutators.setCARGA_TIPO_ID}
                      />

                      <FieldsRowContratoZona
                        zonaOptions={zonaOptions}
                        centroOperacion={centroOperacion}
                        form={form}
                        storeState={storeState}
                      />

                      {/* +++ GRUPO DE DOS SELECT +++ */}
                      <div className="flex space-x-4">
                        <div className="w-1/2">
                          <p className="mt-3 text-sm text-gray-800">Origen</p>

                          {/* +++ SELECT +++ */}

                          <Field name="LUGAR_ID_ORIGEN">
                            {({ input, meta }) => (
                              <>
                                {lugarOptions.length > 0 ? (
                                  <Select
                                    options={lugarOptions}
                                    defaultValue={lugarOptions.find((i) => i.value === storeState.Solicitud?.selSolicitud?.LUGAR_ID_ORIGEN)}
                                    name="selectOrigen"
                                    onChange={form.mutators.setLUGAR_ID_ORIGEN}
                                    className={meta.error && meta.touched ? `${inputSelectError}` : `${inputSelectOk}`}
                                  />
                                ) : (
                                  <div className=" flex items-center justify-center ">
                                    <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                                  </div>
                                )}
                                <input
                                  {...input}
                                  type="hidden"
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                          {/* --- SELECT --- */}
                        </div>
                        <div className="w-1/2">
                          <p className="mt-3 text-sm text-gray-800">Destino</p>
                          {/* +++ SELECT +++ */}
                          {lugarOptions.length > 0 ? (
                            <Select
                              options={lugarOptions}
                              defaultValue={lugarOptions.find((i) => i.value === storeState.Solicitud?.selSolicitud?.LUGAR_ID_DESTINO)}
                              name="selectDestino"
                              onChange={form.mutators.setLUGAR_ID_DESTINO}
                            />
                          ) : (
                            <div className=" flex items-center justify-center ">
                              <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                            </div>
                          )}
                          <Field name="LUGAR_ID_DESTINO">
                            {({ input, meta }) => (
                              <>
                                <input
                                  {...input}
                                  type="hidden"
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                          {/* --- SELECT --- */}
                        </div>
                      </div>
                      {/* --- GRUPO DE DOS SELECT --- */}

                      {/* +++ GRUPO DE DOS CAMPOS +++ */}
                      <div className="flex space-x-4">
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_DESCRIPCION">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Descripción</p>
                                <textarea
                                  {...input}
                                  placeholder="Descripción"
                                  className={meta.error && meta.touched ? `${inputTextAreaFalse}` : `${inputTextAreaTrue}`}
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_NOTIFICACION">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Notificación</p>
                                <textarea
                                  {...input}
                                  placeholder="Correos separados por ;"
                                  className={meta.error && meta.touched ? `${inputTextAreaFalse}` : `${inputTextAreaTrue}`}
                                />
                                {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                      {/* --- GRUPO DE DOS CAMPOS --- */}
                    </div>
                    <div className=" md:block mt-2 space-x-4 text-center">
                      <ButtonTable
                        type="button"
                        onClick={() => setNuevoServicio(false)}>
                        Cancelar
                      </ButtonTable>
                      <ButtonTable
                        type="submit"
                        disabled={submitting || pristine}>
                        {editMode ? 'Clonar' : 'Agregar'}
                      </ButtonTable>
                    </div>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

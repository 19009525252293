import React, { useState, useEffect } from 'react';

import ViewBono from './view';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setSelBono, setBono, showLoadingAnimation, setSelAsignarBono } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const Bono = (props) => {
  const { get, post, DELETE, put } = useApiClient();
  const [modalAgregarBono, setModalAgregarBono] = useState(false);
  const [isLoadingTables, setIsLoadingTables] = useState(false);

  const getBono = async () => {
    setIsLoadingTables(true);
    try {
      const result = await get(`/bono`);

      store.dispatch(setBono(result));
    } catch (error) {}
    setIsLoadingTables(false);
  };

  const createBono = async (form) => {
    try {
      const result = await post(
        '/bono',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarBono(false);
        OkToast({ result, message: 'Bono Agregado!' });
        getBono();
      }
    } catch (error) {}
  };

  const updateBono = async (form) => {
    try {
      const result = await put(
        `/bono/${form.BONO_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalAgregarBono(false);
        OkToast({ result, message: 'Bono actualizado!' });
        getBono();
      }
    } catch (error) {}
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['BONO_NOMBRE', 'BONO_VALOR', 'ROL_ID'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  function handleEdit(obj) {
    store.dispatch(setSelBono(obj));
    setModalAgregarBono(true);
  }

  function handleDelete(rowID) {
    //const projectsResult = (`/projects/${projectID}/task-managements/${taskManagementID}/tasks/${rowID}`);
    //
    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar el Bono?</b>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => {
            toast.dismiss(t.id);
            deleteBono(rowID);
          }}>
          Si
        </button>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </span>
    ));
  }

  const deleteBono = async (rowID) => {
    store.dispatch(showLoadingAnimation());

    try {
      const result = await DELETE(`/bono/${rowID}`);

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Bono eliminado!' });
        getBono();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }

    store.dispatch(hideLoadingAnimation());
  };

  useEffect(() => {
    getBono();
  }, []);

  const Bono = props.Bono;

  return (
    <ViewBono
      modal={{ modalAgregarBono, setModalAgregarBono }}
      endPoint={{ createBono, updateBono }}
      data={{ Bono }}
      actions={{ handleEdit, handleDelete, isLoadingTables, validate }}
    />
  );
};

const mapStateToProps = function (state) {
  return { Bono: state.Bono };
};

//export default Projects;
export default connect(mapStateToProps)(Bono);

import React from 'react';
import TbodyTableParametroCliente from './tbody';
import TheadTableParametroCliente from './thead';

const TableParametroCliente = (props) => {
  return (
    <table className="min-w-full bg-white">
      <TheadTableParametroCliente />
      <TbodyTableParametroCliente
        data={props.data}
        actions={props.actions}
      />
    </table>
  );
};
export default TableParametroCliente;

import React from 'react';
import TbodyTableCentroOperacion from './tbody';
import TheadTableCentroOperacion from './thead';
const TableCentroOperacion = (props) => {
  return (
    <table className="min-w-full bg-white">
      <TheadTableCentroOperacion />
      <TbodyTableCentroOperacion actions={props.actions} />
    </table>
  );
};
export default TableCentroOperacion;

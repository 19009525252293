import styled from 'styled-components';

export const ButtonTable = styled.button.attrs({
  className: 'hover:bg-gray-800 bg-gray-600 p-2 px-10 mb-8 text-white rounded',
})``;

export const ButtonSearch = styled.button.attrs({
  className: 'hover:bg-gray-800 bg-gray-600 p-2 px-2 mb-8 text-white rounded',
})``;

export const ButtonSettings = styled.button.attrs({
  className: 'mb-2 px-20 md:mb-0 border text-lg shadow-sm tracking-wider text-white bg-gray-600 rounded hover:bg-gray-800',
})``;

export const ButtonTool = styled.button.attrs({
  className:
    'px-8 absolute bottom-0 right-0 py-2 md:mb-0 border px-5 py-2 text-lg shadow-sm font-medium tracking-wider text-white rounded hover:bg-gray-800 bg-gray-600',
})``;

export const ButtonToolTaskCard = styled.button.attrs({
  className: 'hover:bg-gray-800 bg-gray-600 p-2 mb-8 rounded',
})``;

export const ButtonToolFalse = styled.div.attrs({
  className: 'px-8 absolute bottom-0 bg-gray-500 right-0 py-2 md:mb-0 border px-5 py-2 text-lg shadow-sm font-medium tracking-wider text-white rounded',
})``;

export const ButtonSmallTable = styled.button.attrs({
  className: 'hover:bg-gray-800 bg-gray-600 p-1 px-10 mb-8 text-white rounded',
})``;

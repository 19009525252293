import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';

import Select from 'react-select';
import { useEffect } from 'react';
import { store } from '../../../../store';
import { ButtonTable } from '../../../global/Styles/buttons';
import { inputsSettingsTrue, inputsSettingsFalse, inputTextAreaTrue, inputTextAreaFalse } from '../../../global/Styles/inputs';
import { useApiClient } from '../../../../clients/apiAuth';
import { ModalTitle } from '../../../global/Styles/titles';
import Skeleton from 'react-loading-skeleton';

export const ModalAsignarBono = (props) => {
  const { createBono, updateBono } = props.endPoint;
  const { vehiculoUsuarioOptions, selSolicitudVehiculoTipo } = props.data;
  const [tipoBono, setTipoBono] = useState([]);

  const [selTercero, setSelTercero] = useState('');

  const listCausado = [
    { label: 'Si', value: 1 },
    { label: 'No', value: 0 },
  ];
  let defaulTipoBono = [];

  const storeState = store.getState();

  const { get } = useApiClient();

  const editMode = storeState.AsignarBono.selAsignarBono === null ? false : true;

  const proxyTask = async (form) => {
    const withAllValues = {
      ...form,
      TERCERO_ID_AUD: storeState.auth.data.user.TERCERO_ID,
      SOLICITUD_VEHICULO_TIPO_ID: selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID,
      ROL_ID: selTercero.rolID,
      ESTADO_ID: 1,
    };

    if (editMode) {
      // updateBono(storeState.AsignarBono.selAsignarBono.SOLICITUD_BONO_ID, form);
    } else {
      createBono(withAllValues);
    }
  };

  const validate = (values) => {
    const errors = {};

    const requiredFields = ['SOLICITUD_BONO_FECHA', 'BONO_ID', 'SOLICITUD_BONO_OBSERVACION', 'SOLICITUD_BONO_VALOR'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  const getTipoBonoOptions = async () => {
    if (selTercero === '') {
      const emptyItem = [
        {
          label: 'Tercero sin seleccionar',
          value: 0,
        },
      ];

      setTipoBono(emptyItem);
      return;
    }

    const response = await get(`/bono?ROL_ID=${selTercero.rolID}`);

    const lst = response.data.map((row) => ({
      label: `${row.BONO_NOMBRE}`,
      value: row.BONO_ID,
      price: row.BONO_VALOR,
    }));

    setTipoBono(lst);

    // if (editMode) {
    //   let val = storeState?.AsignarBono?.selAsignarBono?.SOLICITUD_BONO_VALOR;
    //   setValor(val ?? 0);
    // }
  };

  const setBONO_ID = (args, state, { setIn, changeValue }) => {
    // const field = state.fields['BONO_ID'];
    // field.change(args[0].value);
    // state.formState.submitFailed = true;
    // let item = tipoBono.find((b) => b.BONO_ID === args[0].value);
    // setValor(item.BONO_VALOR);

    const field = state.fields['BONO_ID'];
    const fieldBonoValor = state.fields['SOLICITUD_BONO_VALOR'];

    field.change(args[0].value);
    fieldBonoValor.change(args[0].price);
  };

  const setSOLICITUD_BONO_CAUSADO = (args, state, { setIn, changeValue }) => {
    const field = state.fields['SOLICITUD_BONO_CAUSADO'];
    field.change(args[0].value);
  };

  const setTERCERO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TERCERO_ID'];
    field.change(args[0].value);
    setSelTercero(args[0]);
  };

  const handleCloseModal = () => {
    props.modals.handleShowBonoModal(false);
    //store.dispatch(setAsignarSelBonoInit());
  };

  useEffect(() => {
    getTipoBonoOptions();
  }, [selTercero]);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-2xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>{editMode ? 'Editar' : 'Nuevo'} Bono</ModalTitle>
        <Form
          onSubmit={proxyTask}
          validate={editMode ? null : validate}
          initialValues={
            editMode
              ? { ...storeState.AsignarBono.selAsignarBono }
              : {
                  SOLICITUD_BONO_CAUSADO: 0,
                  SOLICITUD_BONO_VALOR: 0,
                  SOLICITUD_BONO_FECHA: new Date().toISOString().split('T')[0],
                }
          }
          mutators={{
            setBONO_ID,
            setTERCERO_ID,
          }}
          subscription={{ submitting: true, pristine: true }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <div className="flex flex-col ">
                    <p className="text-sm text-gray-800">Tercero</p>
                    {vehiculoUsuarioOptions.length > 0 ? (
                      <Field name="TERCERO_ID">
                        {({ input, meta }) => (
                          <>
                            {vehiculoUsuarioOptions && (
                              <Select
                                options={vehiculoUsuarioOptions}
                                // defaultValue={vehiculoUsuarioOptions[0]}
                                name="selectNovedadTipoOptions"
                                onChange={form.mutators.setTERCERO_ID}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    marginTop: 6,
                                    borderColor: meta.error && meta.touched ? 'red' : null,
                                  }),
                                }}
                              />
                            )}
                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    ) : (
                      <div className="mt-1 flex-grow pb-1">
                        <Skeleton
                          height={34}
                          width="100%"
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex space-x-4">
                    <div className="mt-1 w-1/2">
                      <Field name="SOLICITUD_BONO_FECHA">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Fecha</p>
                            <input
                              {...input}
                              type="date"
                              placeholder="Fecha"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 flex w-1/2 flex-col">
                      <p className="text-sm text-gray-800">Bono</p>
                      {tipoBono.length > 0 ? (
                        <Field name="BONO_ID">
                          {({ input, meta }) => (
                            <>
                              {tipoBono && (
                                <Select
                                  options={tipoBono}
                                  // defaultValue={tipoBono[0]}
                                  name="selectTipoBono"
                                  onChange={form.mutators.setBONO_ID}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      marginTop: 6,
                                      borderColor: meta.error && meta.touched ? 'red' : null,
                                    }),
                                  }}
                                />
                              )}
                              <input
                                {...input}
                                type="hidden"
                              />
                            </>
                          )}
                        </Field>
                      ) : (
                        <div className="mt-1 flex-grow pb-1">
                          <Skeleton
                            height="100%"
                            width="100%"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex space-x-4">
                    <div className="mt-1 w-1/2">
                      <p className=" text-sm text-gray-800">Causado</p>

                      <Select
                        menuIsOpen={false}
                        defaultValue={listCausado.find((i) => i.value === 0)}
                        onChange={form.mutators.setSOLICITUD_BONO_CAUSADO}
                        options={listCausado}
                        className="mt-2"
                        name="selectCausado"
                      />

                      <Field name="SOLICITUD_BONO_CAUSADO">
                        {({ input, meta }) => (
                          <input
                            {...input}
                            type="hidden"
                          />
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 w-1/2">
                      <Field name="SOLICITUD_BONO_VALOR">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Valor</p>
                            <input
                              {...input}
                              type="number"
                              placeholder="Valor"
                              // onChange={(e) => setValor(e.target.value)}
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  <Field name="SOLICITUD_BONO_OBSERVACION">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Observación</p>
                        <textarea
                          {...input}
                          rows={6}
                          className={meta.error && meta.touched ? `${inputTextAreaFalse}` : `${inputTextAreaTrue} `}
                        />
                      </>
                    )}
                  </Field>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => handleCloseModal()}>Cancelar</ButtonTable>
                  {/* disabled={submitting || pristine} */}
                  <ButtonTable type="submit">{editMode ? 'Actualizar' : 'Agregar'}</ButtonTable>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default ModalAsignarBono;

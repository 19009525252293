import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { moneyFormat } from '../../../../../utils/format';
import { ReactComponent as Trash } from '../../../../assets/svg/Trash.svg';

const TbodyTableManifiesto = (props) => {
  const { solicitudDetalle } = props.data;
  const { handleDeleteDetalle } = props.actions;

  const handleClick = (SOLICITUD_DETALLE_ID) => {
    handleDeleteDetalle(SOLICITUD_DETALLE_ID);
  };

  return (
    <tbody className="rounded-lg text-gray-700">
      {solicitudDetalle.map((item) => (
        <tr key={item.SOLICITUD_DETALLE_ID}>
          <td className="px-4 py-3 text-left">{item.TARIFA_CONSECUTIVO}</td>
          <td className="px-4 py-3 text-left">{item.TARIFA_ITEM}</td>
          <td className="px-4 py-3 text-left">{`${item.TARIFA_GRUPO} ${item.TARIFA_NOMBRE}`}</td>
          <td className="px-4 py-3 text-right">{item.SOLICITUD_DETALLE_CANTIDAD}</td>
          <td className="px-4 py-3 text-right">{moneyFormat(item.SOLICITUD_DETALLE_VALOR)}</td>
          <td className="px-4 py-3 text-right">{moneyFormat(item.SOLICITUD_DETALLE_TOTAL)}</td>
          <td
            className="cursor-pointer px-4 py-3 text-left hover:text-gray-400"
            onClick={() => handleClick(item.SOLICITUD_DETALLE_ID)}>
            <Trash />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { Solicitud: state.Solicitud };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableManifiesto);

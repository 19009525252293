import React from 'react';
export const TheadTableVehiculo = () => {
  return (
    <thead className="  sticky top-0 z-10   bg-gray-100 text-sm  uppercase  text-gray-700 ">
      <tr>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Id
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Marca
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Placa
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Linea
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Modelo
        </th>

        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Clase
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Tipo
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Ejes
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Propio
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Propietario
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Tenedor
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          C.C.
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Trailer
        </th>

        <th
          scope="col"
          className=" px-1 text-left uppercase">
          Rev.
        </th>
        <th
          scope="col"
          className=" px-1 text-left uppercase"></th>
        <th
          scope="col"
          className="w-2"></th>
        <th
          scope="col"
          className="w-2"></th>
      </tr>
    </thead>
  );
};

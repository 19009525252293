import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//Inicio de REDUX
import { Provider } from 'react-redux';
import { store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
//Fin REDUX

// Redux Persist
import { persistStore } from 'redux-persist';

let persistor = persistStore(store);

const MainApp = (
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// ReactDOM.render(newApp, document.getElementById('root'));

const root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
root.render(MainApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.

serviceWorker.unregister();

import React from 'react';

import { Form, Field } from 'react-final-form';

import FormStateToRedux from '../../RFF/FormStateToRedux';

import { inputsSettingsTrue, inputsSettingsFalse, inputTextAreaTrue, inputTextAreaFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';

export const ModalAddItem = (props) => {
  const { setModaladdItmen } = props.modales;

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-4xl rounded-xl bg-white p-5  shadow-lg ">
        <div className="">
          <div className="flex-auto justify-center p-5 text-left">
            <h2 className="text-xl">Añadir Item</h2>
            <Form
              onSubmit="lol"
              validate=""
              subscription={{ submitting: true, pristine: true }}>
              {({ handleSubmit, form, submitting, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <FormStateToRedux form="frmNuevaSolicitud" />
                  <div className="">
                    <div className="flex-auto justify-center p-5 text-left">
                      <div className="flex space-x-4">
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_CC">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Código activo/otro</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Código activo/otro"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_AFE">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Cantidad</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Cantidad"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_CC">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Altura de cargue max (cms)</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Altura de cargue max  (cms)"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_AFE">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Largo de cargue max (cms)</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Largo de cargue max  (cms"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_CC">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Ancho de cargue max (cms)</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Ancho de cargue max  (cms)"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_AFE">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Capacidad de cargue max (Kg)</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Capacidad de cargue max  (Kg)"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_CC">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Radio de cargue max (Kg)</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Radio de cargue max  (Kg)"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-1/2">
                          <Field name="SOLICITUD_AFE">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Descripción</p>
                                <input
                                  {...input}
                                  type="text"
                                  placeholder="Descripción"
                                  className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="">
                        <div className="mt-1 w-full">
                          <Field name="SOLICITUD_DESCRIPCION">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Descripción técnica</p>
                                <textarea
                                  {...input}
                                  placeholder="Descripción técnica"
                                  className={meta.error && meta.touched ? `${inputTextAreaFalse}` : `${inputTextAreaTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="mt-1 w-full">
                          <Field name="SOLICITUD_DESCRIPCION">
                            {({ input, meta }) => (
                              <>
                                <p className="text-sm text-gray-800">Observaciones</p>
                                <textarea
                                  {...input}
                                  placeholder="Observaciones"
                                  className={meta.error && meta.touched ? `${inputTextAreaFalse}` : `${inputTextAreaTrue}`}
                                />
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className=" md:block mt-2 space-x-4 text-center">
                      <ButtonTable
                        type="submit"
                        disabled={submitting || pristine}>
                        Agregar
                      </ButtonTable>
                      <ButtonTable
                        type="button"
                        onClick={() => setModaladdItmen(false)}>
                        Cancelar
                      </ButtonTable>
                    </div>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

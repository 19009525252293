import { TableBonoTbody } from './TableBonoTbody';
import { TableBonoThead } from './TableBonoThead';

export const TableBono = (props) => {
  return (
    <table className="w-full table-auto text-sm">
      <TableBonoThead
        modals={props.modals}
        actions={props.actions}
      />
      <TableBonoTbody
        data={props.data}
        actions={props.actions}
      />
    </table>
  );
};

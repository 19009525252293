import React, { useState, useEffect } from 'react';
import { ButtonTable } from '../../../../global/Styles/buttons';

import TableVehiculoDetalles from './TableVehiculoDetalles';

// import Map from '../../../../../img/Map.jpg';

import TableNovedades from '../TableNovedades';
import { connect } from 'react-redux';
import Select from 'react-select';

import TableBono from '../TableBono';
import FormStateToRedux from '../../../RFF/FormStateToRedux';
import { Form, Field } from 'react-final-form';
import { useApiClient, validateSuccess2xx } from '../../../../../clients/apiAuth';
import { CustomToast, ErrorToast, OkToast } from '../../../Toast';
import { inputsSettingsFalse, inputsSettingsTrue } from '../../../../global/Styles/inputs';
import { ModalTitle } from '../../../../global/Styles/titles';
import toast from 'react-hot-toast';
import { ModalAsignarBono } from '../ModalAsignarBono';
import TableTarifaOld from '../TableTarifa';
import { TableTarifa } from './TableTarifa';
import IndicatorServicioEstado from './IndicatorServicioEstado';
import TableEjecucion from '../TableEjecucion';
import TableManifiesto from '../TableManifiesto';
import { TableVehiculos } from './TableVehiculos';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ModalTarifa } from '../ModalTarifa/ModalTarifa';
import { store } from '../../../../../store';
import { setSelSolicitud, setSelTipoVehiculo } from '../../../../../actions';

export const PanelVehiculoTarifa = ({ handleAddTarifa, handleChangeTarifaUnidad, data, isReadOnly = false }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [isModalTarifaVisible, setIsModalTarifaVisible] = useState(false);

  const [solicitudDetalle, setSolicitudDetalle] = useState([]);

  const { get, post, put, DELETE } = useApiClient();

  const handleShowTarifaModal = (isVisible = true) => {
    setIsModalTarifaVisible(isVisible);
  };

  const getSolicitudDetalle = async () => {
    const result = await get(`/solicitudDetalle?SOLICITUD_VEHICULO_TIPO_ID=${data.selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID}`);

    if (!validateSuccess2xx(result)) return CustomToast({ result });
    setSolicitudDetalle(result.data);
  };

  const createSolicitudDetalle = async (form) => {
    try {
      const result = await post(
        '/solicitudDetalle',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      OkToast({ result, message: 'Item Agregado!' });

      store.dispatch(setSelTipoVehiculo(result.solicitudVehiculoTipo[0]));

      // store.dispatch(setSelSolicitud(objSolicitud));

      getSolicitudDetalle();
      handleShowTarifaModal(false);
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };
  const deleteSolicitudDetalle = async (rowID) => {
    try {
      const result = await DELETE(`/solicitudDetalle/${rowID}`);

      if (!validateSuccess2xx(result)) {
        return CustomToast({ result });
      }

      OkToast({ result, message: 'Item eliminado!' });

      store.dispatch(setSelTipoVehiculo(result.solicitudVehiculoTipo[0]));

      getSolicitudDetalle();
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const handleDeleteDetalle = (item) => {
    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar el registro?</b>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => {
            toast.dismiss(t.id);
            deleteSolicitudDetalle(item.SOLICITUD_DETALLE_ID);
          }}>
          Si
        </button>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </span>
    ));
  };

  useEffect(() => {
    getSolicitudDetalle();
  }, [data.selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID]);

  return (
    <>
      <TableTarifa
        data={{ solicitudDetalle }}
        modals={{ handleShowTarifaModal }}
        actions={{ handleAddTarifa, handleChangeTarifaUnidad, handleDeleteDetalle }}
        isReadOnly={isReadOnly}></TableTarifa>

      {/* <TableTarifaOld
        data={data}
        actions={{ handleAddTarifa, handleChangeTarifaUnidad, handleDeleteDetalle }}></TableTarifaOld> */}
      {isModalTarifaVisible ? (
        <ModalTarifa
          modals={{ handleShowTarifaModal }}
          endPoints={{ createSolicitudDetalle }}
          solicitud={data.selSolicitud}
          data={{ selSolicitudVehiculoTipo: data.selSolicitudVehiculoTipo, tarifaUnidadOptions: data.tarifaUnidadOptions }}
        />
      ) : null}
    </>
  );
};

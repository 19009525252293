import React, { useState, useEffect } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import Select from 'react-select';
import { store } from '../../../../store';

export const ModalAgregarContrato = (props) => {
  const { setModalAgregarContrato } = props;
  const { createContrato, validate, updateContrato } = props.endPoint;
  const { get } = useApiClient();
  const storeState = store.getState();

  const [operacionModo, setOperacionModo] = useState([]);

  const editMode = storeState.contrato.selContrato === null ? false : true;

  const getOperacionModo = async () => {
    try {
      const response = await get(`/operacionModo`);
      const lst = response.data.map((row) => ({
        label: `${row.OPERACION_MODO_NOMBRE} `,
        value: row.OPERACION_MODO_ID,
      }));
      setOperacionModo(lst);
    } catch (error) {}
  };

  const setTERCERO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TERCERO_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const setOPERACION_MODO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['OPERACION_MODO_ID'];
    field.change(args[0].value);
  };

  const proxyTask = async (form) => {
    if (editMode) {
      updateContrato(form);
    } else {
      createContrato(form);
    }
  };

  useEffect(() => {
    //getTercero();
    getOperacionModo();
  }, []);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        onSubmit={proxyTask}
        initialValues={editMode ? storeState.contrato.selContrato : {}}
        validate={validate}
        subscription={{ submitting: true, pristine: true }}
        mutators={{ setTERCERO_ID, setOPERACION_MODO_ID }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
              <div className="">
                <div className="flex-auto justify-center space-y-4 p-5 text-left">
                  <h2 className="py-2 text-xl ">{editMode ? 'Editar' : 'Nuevo'} Contrato</h2>
                  <FormStateToRedux form="frmAgregarContrato" />

                  <Field name="CONTRATO_NUMERO">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Número de contrato</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="Número de contrato"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>

                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <Field name="CONTRATO_FECHA_INICIO">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Fecha de inicio</p>
                            <input
                              {...input}
                              type="date"
                              placeholder="nombre"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="w-1/2">
                      <Field name="CONTRATO_FECHA_FINAL">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Fecha final</p>
                            <input
                              {...input}
                              type="date"
                              placeholder="nombre"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div>
                    <p className="mt-3 text-sm text-gray-800">Operación modo</p>
                    {operacionModo.length > 0 ? (
                      <Select
                        defaultValue={operacionModo.find((i) => i.value == storeState.contrato.selContrato?.OPERACION_MODO_ID)}
                        onChange={form.mutators.setOPERACION_MODO_ID}
                        options={operacionModo}
                        name="selectOperacion"
                        className="mt-2"
                      />
                    ) : (
                      <div className=" flex items-center justify-center">
                        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                      </div>
                    )}
                    <Field name="OPERACION_MODO_ID">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>

                  <Field name="CONTRATO_PRESUPUESTO">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Presupuesto</p>
                        <input
                          {...input}
                          type="number"
                          placeholder="$"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalAgregarContrato(false)}>Cancelar</ButtonTable>
                  <ButtonTable type="submit">
                    {' '}
                    {/*disabled={submitting || pristine} */}
                    Agregar
                  </ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    roles: state.roles,
  };
};

//export default Projects;
export default connect(mapStateToProps)(ModalAgregarContrato);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { EllipsisVerticalIcon, PencilIcon, TrashIcon, KeyIcon } from '@heroicons/react/24/outline';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { fixOnlyDateForTable } from '../../../../utils/format';

const TbodyTableTercero = (props) => {
  const infoTercero = props.tercero.tercero;

  const { handleEdit, handleDelete } = props.actions;
  const { envioContraseña } = props.endPoint;

  useEffect(() => {}, []);

  return (
    <tbody className="h-32 overflow-y-auto">
      {infoTercero.map((tercero, index) => (
        <tr
          className="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
          key={index}>
          <td className="w-2 px-1">
            <Menu
              menuButton={
                <MenuButton>
                  <EllipsisVerticalIcon className="h-5 w-5 transform cursor-pointer  transition  duration-300 hover:scale-110"></EllipsisVerticalIcon>
                </MenuButton>
              }
              transition>
              <MenuItem onClick={() => handleEdit(tercero)}>
                <PencilIcon className="h-5 w-5 pr-2" />
                Editar
              </MenuItem>
              <MenuItem onClick={() => envioContraseña(tercero.TERCERO_ID)}>
                <KeyIcon className="h-5 w-5 pr-2" />
                Enviar contraseña
              </MenuItem>
              <MenuItem onClick={() => handleDelete(tercero.TERCERO_ID)}>
                <TrashIcon className="h-5 w-5 pr-2" />
                Eliminar
              </MenuItem>
            </Menu>
          </td>
          <td className="px-1 text-left">{tercero.TERCERO_ID}</td>

          <td
            scope="row"
            className="px-1 text-left">
            {`${tercero.TERCERO_NOMBRE1 ? tercero.TERCERO_NOMBRE1 : ''} ${tercero.TERCERO_NOMBRE2 ? tercero.TERCERO_NOMBRE2 : ''} ${
              tercero.TERCERO_APELLIDO1 ? tercero.TERCERO_APELLIDO1 : ''
            } ${tercero.TERCERO_APELLIDO2 ? tercero.TERCERO_APELLIDO2 : ''}`}
          </td>
          <td className="px-1 text-left">{tercero.DOCUMENTO_TIPO_DESCRIPCION ? tercero.DOCUMENTO_TIPO_DESCRIPCION : ''}</td>
          <td className="px-1 text-left">{tercero.TERCERO_DOCUMENTO}</td>
          <td className="px-1 text-left">{tercero.TERCERO_RAZON_SOCIAL}</td>
          <td className="px-1 text-left">{tercero.TERCERO_MAIL}</td>
          <td className="px-1 text-left">
            {tercero.rol
              ? tercero.rol.map((r, index) => {
                  return (
                    <p
                      key={index}
                      className="whitespace-nowrap text-xs">
                      {r.ROL_NOMBRE}
                    </p>
                  );
                })
              : ''}
          </td>
          <td className="px-1 text-left">{`${tercero.TERCERO_TEL ? tercero.TERCERO_TEL : ''}  ${tercero.TERCERO_CEL ? tercero.TERCERO_CEL : ''} `}</td>
          <td className="px-1 text-left">{tercero.TERCERO_DIRECCION ? tercero.TERCERO_DIRECCION : ''}</td>
          <td className="px-1 text-left">{tercero.MUNICIPIO_DESCRIPCION ? tercero.MUNICIPIO_DESCRIPCION : ''}</td>
          <td className="px-1 text-left">{tercero.TERCERO_ESTADO ? tercero.TERCERO_ESTADO : ''}</td>
          <td className="px-1 text-left">
            <a className="hover:text-blue-500">{fixOnlyDateForTable(tercero.UPDATED_AT)}</a>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { tercero: state.tercero };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableTercero);

import React from 'react';
import TbodyTableTercero from './tbody';
import TheadTableTercero from './thead';
// import '../../../../styles/table.css';

const TableTercero = (props) => {
  const { loadingTables } = props.data;
  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="w-full table-auto text-sm">
          <TheadTableTercero />
          <TbodyTableTercero
            actions={props.actions}
            endPoint={props.endPoint}
          />
        </table>
      )}
    </div>
  );
};
export default TableTercero;

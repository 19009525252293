import React from 'react';
import Nav from '../../../components/specific/Navigation';

import { Toaster } from 'react-hot-toast';

import { TitlesTables } from '../../../components/global/Styles/titles';

import LoadingTables from '../../../components/global/LoadingTables';

import Pager from '../../../components/global/Pager';
import { ButtonTable } from '../../../components/global/Styles/buttons';
import ModalCotizacion from '../../../components/specific/Servicios/Modales/ModalCotizacion/ModalCotizacion';
import TableCotizacion from '../../../components/specific/Servicios/TableCotizacion/TableCotizacion';
import ModalAprobarCotizacion from '../../../components/specific/Servicios/Modales/ModalCotizacion/ModalAprobarCotizacion';

const selectedTab =
  'relative block border-b-2 border-l border-r border-t-2  bg-gray-100 border-gray-200   p-4  font-medium text-gray-800 cursor-pointer rounded-t-md';
const noSelectedTab =
  ' border-l border-b-2 border-r border-t-2 border-gray-200  block p-4  font-medium text-gray-500 hover:text-gray-800 cursor-pointer rounded-t-md';
const checkIsTabSelected = (key, estadoAprobado) => {
  if (key === estadoAprobado) {
    return selectedTab;
  }
  return noSelectedTab;
};

const ViewCotizacion = (props) => {
  const { isModalCotizacionVisible, setIsModalCotizacionVisible, isModalAprobarVisible, setIsModalAprobarVisible } = props.modales;

  const { handleAprobar, handlePDF, handleAddTarifa, handleNewCotizacion, handleDeleteDetalle, loadingTables, handleTabClick } = props.actions;

  const { auth, selectedTab, cotizacionData } = props.data;

  const { handleNextPage, handlePreviusPage } = props.pager;

  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'w-1/2',
        }}
      />
      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>COTIZACIONES</TitlesTables>
            <div className="flex-auto"></div>
            <ButtonTable onClick={() => handleNewCotizacion()}>Nueva Cotización</ButtonTable>
          </div>

          {/* TABS */}
          <ul className=" flex text-center">
            <li className="flex-1">
              <div></div>
            </li>

            <li className="flex-1">
              <div></div>
            </li>
            <li className="flex-1">
              <div
                className={checkIsTabSelected(1, selectedTab)}
                onClick={() => handleTabClick(1)}>
                Por aprobar
              </div>
            </li>
            <li className="flex-1">
              <div
                className={checkIsTabSelected(2, selectedTab)}
                onClick={() => handleTabClick(2)}>
                Aprobadas
              </div>
            </li>

            <li className="flex-1">
              <div
                className={checkIsTabSelected(3, selectedTab)}
                onClick={() => handleTabClick(3)}>
                Rechazadas
              </div>
            </li>
          </ul>
          {/* </div> */}

          {loadingTables && <LoadingTables />}
          <TableCotizacion
            endPoint={props.endPoint}
            data={{ cotizacionData, auth, loadingTables }}
            actions={{ handleAprobar, handlePDF }}
          />
          <Pager
            data={cotizacionData}
            pager={{ handleNextPage, handlePreviusPage }}
          />
        </div>
      </div>

      {isModalCotizacionVisible && (
        <ModalCotizacion
          setIsModalCotizacionVisible={setIsModalCotizacionVisible}
          data={props.data}
          endPoint={props.endPoint}
          actions={{ handleAddTarifa, handleDeleteDetalle }}
        />
      )}
      {isModalAprobarVisible && (
        <ModalAprobarCotizacion
          setIsModalAprobarVisible={setIsModalAprobarVisible}
          data={props.data}
          endPoint={props.endPoint}
          actions={{ handleAddTarifa, handleDeleteDetalle }}
        />
      )}
    </div>
  );
};

export default ViewCotizacion;

import React from 'react';
const Pager = (props) => {
  const { data } = props;

  const { handleNextPage, handlePreviusPage } = props.pager;
  const pagerData = data.pagination;

  const numbers = Array.from({ length: pagerData?.totalPages }, (_, i) => i + 1);

  return (
    <div className="mt-4 flex justify-end space-x-12">
      <div className="flex space-x-4">{}</div>
      <div className="flex space-x-4">
        {}
        <h2 className="text-sm text-gray-600">Páginas</h2>

        <h2 className="text-sm text-gray-800">
          {numbers.map((number) =>
            number === pagerData?.currentPage ? (
              <span
                key={number}
                className="cursor-not-allowed">
                <strong>{number} </strong>
              </span>
            ) : (
              <span
                key={number}
                className="cursor-pointer"
                onClick={() => handleNextPage(number)}>
                {number}{' '}
              </span>
            )
          )}
          {/* <strong>{pagerData?.currentPage}</strong> */}
        </h2>

        {/* <h2 className="text-sm text-gray-600">Total de páginas </h2>
        <h2 className="text-sm text-gray-800">
          <strong>{pagerData?.totalPages}</strong>
        </h2> */}

        <h2 className="text-sm text-gray-600">Regs: </h2>
        <h2 className="text-sm text-gray-800">
          <strong>{pagerData?.totalRecords}</strong>
        </h2>
        <h3
          className="cursor-pointer text-sm text-gray-600"
          onClick={() => handlePreviusPage()}>{`<`}</h3>
        <h3
          className="cursor-pointer text-sm text-gray-600"
          onClick={() => handleNextPage()}>
          {' '}
          {`>`}
        </h3>
      </div>
    </div>
  );
};

export default Pager;

import React from 'react';

const SideNavigation = ({ children, toDisplay, toAnimate }) => (
  <div className={`fixed inset-0 left-auto z-50 w-screen ${!toDisplay ? 'hidden' : ''}`}>
    <div
      className="fixed inset-0 w-full transition-opacity"
      aria-hidden="false">
      <div className="absolute inset-0 bg-gray-500 opacity-75" />
    </div>
    <div className={`flex h-full max-h-screen transform-gpu flex-col items-end ${!toAnimate ? 'translate-x-full' : ''} transition duration-500`}>
      {children}
    </div>
  </div>
);

export default SideNavigation;

import React from 'react';
import TheadTableBonoAsignado from './thead';
import TbodyTableBonoAsignado from './tbody';
import './style.css';

const TableAsignarBono = (props) => {
  return (
    <div className="w-full">
      <br />
      <table className="myFormat min-w-full">
        <TheadTableBonoAsignado />
        <TbodyTableBonoAsignado
          data={props.data}
          actions={props.actions}
        />
      </table>
    </div>
  );
};

export default TableAsignarBono;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ViewMarca from './ViewMarca';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setMarca, setSelMarca, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const Marca = (props) => {
  const { get, post, put, DELETE } = useApiClient();
  const [loadingTables, setLoadingTables] = useState(false);
  const [modalAgregarMarca, setModalModalAgregarMarca] = useState(false);

  const getMarca = async () => {
    setLoadingTables(true);
    try {
      const result = await get(`/marca`);

      store.dispatch(setMarca(result));
    } catch (error) {}
    setLoadingTables(false);
  };

  const createMarca = async (form) => {
    try {
      const result = await post(
        '/marca',
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalModalAgregarMarca(false);
        OkToast({ result, message: 'Marca Agregada!' });
        getMarca();
      }
    } catch (error) {}
  };

  const updateMarca = async (form) => {
    try {
      const result = await put(
        `/marca/${form.MARCA_ID}`,
        {
          json: JSON.stringify(form),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalModalAgregarMarca(false);
        OkToast({ result, message: 'Marca Actualizada!' });
        getMarca();
      }
    } catch (error) {}
  };

  function handleEdit(obj) {
    console.log('handleEdit: ', obj);

    store.dispatch(setSelMarca(obj));
    setModalModalAgregarMarca(true);
  }
  const deleteMarca = async (rowID) => {
    store.dispatch(showLoadingAnimation());
    try {
      const result = await DELETE(`/marca/${rowID}`);
      if (String(result.status).substr(0, 1) !== '2') {
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Marca Eliminada!' });
        getMarca();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }
    store.dispatch(hideLoadingAnimation());
  };

  function handleDeleteMarca(rowID) {
    toast((t) => (
      <span>
        <b>¿Está seguro de querer eliminar marca?</b>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => {
            toast.dismiss(t.id);
            deleteMarca(rowID);
          }}>
          Sí
        </button>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </span>
    ));
  }

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['MARCA_DESCRIPCION'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  useEffect(() => {
    getMarca();
  }, []);

  return (
    <ViewMarca
      endPoint={{ getMarca, createMarca, updateMarca }}
      modal={{
        modalAgregarMarca,
        setModalModalAgregarMarca,
        validate,
        createMarca,
      }}
      actions={{ loadingTables, handleDeleteMarca, handleEdit }}
    />
  );
};

const mapStateToProps = function (state) {
  return { marca: state.marca };
};

//export default Projects;
export default connect(mapStateToProps)(Marca);

import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import { store } from '../../../../../store';
import { connect } from 'react-redux';
import { inputsSettingsFalse, inputsModales } from '../../../../global/Styles/inputs';
import TableReporte from '../TableReporte';
import { ButtonTable } from '../../../../global/Styles/buttons';
import { setSelTipoVehiculo } from '../../../../../actions';

const TableTarifa = (props) => {
  const storeState = store.getState();
  const { tarifaUnidadOptions, tarifaOptions } = props.data;
  const { handleChangeTarifaUnidad, handleAddTarifa } = props.actions;
  const [tipoVehiculo, setTipoVehiculo] = useState(null);

  const handleAdd = (forms) => {
    handleAddTarifa(props.frm.values.SOLICITUD_VEHICULO_TIPO_ID, forms.TARIFA_ID, forms.SOLICITUD_DETALLE_CANTIDAD);
  };

  const onSetTARIFA_UNIDAD = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TARIFA_UNIDAD'];
    field.change(args[0].value);
    state.formState.submitFailed = true;

    handleChangeTarifaUnidad(props.tipoVehiculo.selTipoVehiculo.VEHICULO_TIPO_ID, args[0].value);
  };

  const onTARIFA_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TARIFA_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['SOLICITUD_DETALLE_CANTIDAD', 'TARIFA_UNIDAD', 'TARIFA_ID'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });

    return errors;
  };

  const getTipoVehiculo = () => {
    const result = props.Solicitud.selSolicitud.vehiculo_tipo.map((row) => ({
      value: row.VEHICULO_TIPO_ID,
      label: row.VEHICULO_TIPO_DESCRIPCION,
    }));

    if (props.Solicitud.selSolicitud.vehiculo_tipo) {
      store.dispatch(setSelTipoVehiculo(props.Solicitud.selSolicitud.vehiculo_tipo[0]));
    }

    setTipoVehiculo(result);
  };

  useEffect(() => {
    getTipoVehiculo();
  }, []);

  return (
    <div>
      <Form
        onSubmit={handleAdd}
        validate={validate}
        mutators={{ onSetTARIFA_UNIDAD, onTARIFA_ID }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-6 mt-4 flex space-x-4">
              <div className="mt-1 w-1/4">
                <p className="mt-3 text-sm text-gray-800">Tipo de vehiculo</p>
                <Field name="TIPO_VEHICULO">
                  {({ input, meta }) => (
                    <>
                      {tipoVehiculo && (
                        <Select
                          options={tipoVehiculo}
                          defaultValue={tipoVehiculo[0]}
                          name="selectTipoVehiculo"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: meta.error && meta.touched ? 'red' : null,
                            }),
                          }}
                        />
                      )}
                      <input
                        {...input}
                        type="hidden"
                      />
                    </>
                  )}
                </Field>
              </div>

              <div className="mt-1 w-1/4">
                <p className="mt-3 text-sm text-gray-800">Unidad</p>
                {tarifaUnidadOptions.length > 0 ? (
                  <Field name="TARIFA_UNIDAD">
                    {({ input, meta }) => (
                      <>
                        <Select
                          options={tarifaUnidadOptions}
                          defaultValue={tarifaUnidadOptions.find((i) => i.value === storeState.tipoVehiculo?.selTipoVehiculo?.VEHICULO_ID)}
                          name="selectUnidad"
                          onChange={form.mutators.onSetTARIFA_UNIDAD}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: meta.error && meta.touched ? 'red' : null,
                            }),
                          }}
                        />
                        <input
                          {...input}
                          type="hidden"
                        />
                      </>
                    )}
                  </Field>
                ) : (
                  <div className=" flex items-center justify-center ">
                    <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                  </div>
                )}
              </div>

              <div className="mt-1 w-1/2">
                <p className="mt-3 text-sm text-gray-800">Tarifa</p>
                {
                  <Field name="TARIFA_ID">
                    {({ input, meta }) => (
                      <>
                        {tarifaOptions.length > 0 ? (
                          <Select
                            options={tarifaOptions}
                            defaultValue={tarifaOptions.find((i) => i.value === storeState.tipoVehiculo?.selTipoVehiculo?.VEHICULO_ID)}
                            name="selectTarifa"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: meta.error && meta.touched ? 'red' : null,
                              }),
                            }}
                            onChange={form.mutators.onTARIFA_ID}
                          />
                        ) : (
                          <Select
                            options={[{ value: '', label: 'Escoge la unidad' }]}
                            defaultValue={{ value: '', label: 'Escoge la unidad' }}
                          />
                        )}
                        <input
                          {...input}
                          type="hidden"
                        />
                      </>
                    )}
                  </Field>
                }
              </div>

              <div className="w-1/8 mt-2">
                <Field name="SOLICITUD_DETALLE_CANTIDAD">
                  {({ input, meta }) => (
                    <>
                      <p className="text-sm text-gray-800">Cantidad</p>
                      <input
                        {...input}
                        type="text"
                        placeholder="Cantidad"
                        className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsModales}`}
                      />
                    </>
                  )}
                </Field>
              </div>

              <div className="w-1/8 ml-4 mt-9">
                <ButtonTable type="submit">Agregar</ButtonTable>
              </div>
            </div>
            {/* <div className="mb-6 mt-4 flex space-x-4">
              <TableReporte
                data={props.data}
                actions={props.actions}
              />
            </div> */}
          </form>
        )}
      </Form>
    </div>
  );
};
const mapStateToProps = function (state) {
  return {
    roles: state.roles,
    tipoVehiculo: state.tipoVehiculo,
    frm: state.finalForm.frmReporte,
    Solicitud: state.Solicitud,
  };
};

//export default Projects;
export default connect(mapStateToProps)(TableTarifa);

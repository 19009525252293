export const TableNovedadTbody = (props) => {
  const { servicioNovedades } = props.data;
  console.log('servicioNovedades: ', servicioNovedades);

  return (
    <tbody className="">
      {servicioNovedades.data?.map((obj) => (
        <tr
          key={obj.nombre}
          className="border-b text-xs">
          <td className=" text-green-400">{obj.NOVEDAD_TIPO_DESCRIPCION}</td>
          <td className=" whitespace-nowrap   text-left">{obj.ESTADO_DESCRIPCION}</td>

          <td className="text-left">{obj.SOLICITUD_NOVEDAD_FECHA}</td>
        </tr>
      ))}
    </tbody>
  );
};

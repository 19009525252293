const initialState = {
  isData: false,
  Regional: [],
  selRegional: null,
  pagination: null,
};

const initialRegionalState = {
  selRegional: null,
};

export const Regional = (state = initialState, action) => {
  switch (action.type) {
    case 'setRegional': {
      if (action.value.status === 200) {
        const stateData = { Regional: action.value.data, isData: true, pagination: action.value.pagination };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setRegionalInit': {
      return initialState;
    }
    case 'setSelRegional': {
      const obj = {
        ...action.value,
      };

      const stateData = { selRegional: obj };

      return { ...state, ...stateData };
    }
    case 'setSelRegionalInit': {
      return { ...state, ...initialRegionalState };
    }

    default:
      //
      return state;
  }
};

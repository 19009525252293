import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg';
import { ReactComponent as Trash } from '../../../assets/svg/Trash.svg';
const TbodyTableRegional = (props) => {
  const infoRegional = props.Regional.Regional;
  const { handleDeleteRegional, handleEdit } = props.actions;

  return (
    <tbody className="text-gray-700">
      {infoRegional?.map((obj, index) => (
        <tr key={index}>
          <td className="w-1/3 px-4 py-3 text-left">{obj.REGIONAL_ID}</td>
          <td className="w-1/3 px-4 py-3 text-left">{obj.REGIONAL_NOMBRE}</td>
          <td className="w-1/3 px-4 py-3 text-left">{obj.REGIONAL_ESTADO}</td>
          {/*<td className="flex space-x-2 px-4 py-3 text-left">
            <Edit className="hover:fill-blue-500 mt-1 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110" onClick={() => handleEdit(obj)} />
            <Trash
              className="hover:fill-blue-500 w-8 transform  cursor-pointer transition  duration-700 hover:scale-110"
              onClick={() => handleDeleteRegional(obj.Regional_ID)}
            />
          </td>*/}
        </tr>
      ))}
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return { Regional: state.Regional };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableRegional);

import toast from 'react-hot-toast';
//https://react-hot-toast.com/

//Aplana el arreglo de errores del validator de laravel.
const checkForMoreError = (errs) => {
  var lstErrors = [];

  if (typeof errs == 'string') {
    lstErrors.push(errs);
    return lstErrors;
  }

  for (var key in errs) {
    var value = errs[key];

    if (Object.keys(value).length > 1) {
      //cuando el error contiene mas errores.
      for (var internalKey in value) {
        lstErrors.push(value[internalKey]);
      }
    } else {
      lstErrors.push(value[0]);
    }
  }
  return lstErrors;
};

export const CustomToast = (data) => {
  const { result } = data;

  const lstErrors = result.exception ? [`Error del backend: ${result.message} ${result.exception}`] : checkForMoreError(result.errors);

  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } pointer-events-auto flex w-full max-w-xl rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}>
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">
              Errors! [{result.status}] : {result.message}
            </p>
            {lstErrors.map((row) => (
              <p className="mt-1 text-sm text-gray-500">{row}</p>
            ))}
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="focus:outline-none flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:ring-2 focus:ring-indigo-500">
          Close
        </button>
      </div>
    </div>
  ));
};

export const CustomToastUploadTask = (data) => {
  const { result } = data;

  const lstErrors = result.error.state_manager.errors;
  const slicedError = lstErrors.slice(0, 5);

  toast.custom((t) => (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}>
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">Errors! {result.status}</p>
            {slicedError.map((row, index) => (
              <div key={index}>
                <p className="mt-1 text-sm text-gray-800">{row.message}</p>
                <p className="mt-1 text-xs text-gray-500">{row.throwable}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="focus:outline-none flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:ring-2 focus:ring-indigo-500">
          Close
        </button>
      </div>
    </div>
  ));
};

export const OkToast = (data) => {
  const { message } = data;

  toast.success(`${message}`);
};

export const InfoToast = (data) => {
  const { title, message } = data;

  toast(`${title} ${message}`, {
    icon: '📨',
    duration: 3000,
  });
};

export const ErrorToast = (title, message) => {
  toast.error(`${title} ${message}`);
};

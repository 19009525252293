import React, { useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';

import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';

import { store } from '../../../../store';

import Select from 'react-select';
import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import { useEffect } from 'react';

export const ModalAgregarBono = (props) => {
  const { setModalAgregarBono } = props.modal;
  const { createBono, updateBono } = props.endPoint;
  const [rolOptions, setRolOptions] = useState([]);

  const { get } = useApiClient();

  const storeState = store.getState();

  //--- ELEMENTOS PARA EL SELECT DE ROLES SOLO PARA BONOS  ---
  const getRol = async () => {
    try {
      const result = await get(`/rol?ROL_BONO=1`);

      const lst = result.data.map((row) => ({
        label: `${row.ROL_NOMBRE}`,
        value: row.ROL_ID,
      }));

      setRolOptions(lst);
    } catch (error) {}
  };

  // +++ MUTADORES +++
  const setROL_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['ROL_ID'];
    field.change(args[0].value);
    state.formState.submitFailed = true;
  };

  // --- MUTADORES ---
  const editMode = storeState.Bono.selBono === null ? false : true;

  const defaultRol = editMode
    ? rolOptions.filter((i) => {
        return i.value === storeState.Bono.selBono?.ROL_ID;
      })[0]
    : null;

  const proxyTask = async (form) => {
    if (editMode) {
      updateBono(form);
    } else {
      createBono(form);
    }
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['BONO_NOMBRE', 'BONO_VALOR', 'ROL_ID'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  useEffect(() => {
    getRol();
  }, []);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <Form
        onSubmit={proxyTask}
        validate={validate}
        initialValues={editMode ? { ...storeState.Bono.selBono } : {}}
        mutators={{
          setROL_ID,
        }}
        subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="relative mx-auto my-auto w-full max-w-lg rounded-xl bg-white p-5  shadow-lg ">
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <h2 className="py-4 text-xl ">{editMode ? 'Editar' : 'Nuevo'} Bono...</h2>
                  <FormStateToRedux form="frmAgregarRol" />
                  {/**/}
                  <Field name="BONO_NOMBRE">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Bono</p>
                        <input
                          {...input}
                          type="text"
                          placeholder="Bono"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                  {/**/}
                  <Field name="BONO_VALOR">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Valor</p>
                        <input
                          {...input}
                          type="number"
                          placeholder="Valor"
                          className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                  {/* +++ SELECT DE MARCAS +++ */}
                  <div className="flex-auto justify-center text-left">
                    <p className="text-sm text-gray-800">Cargo</p>
                    {rolOptions.length > 0 ? (
                      <Select
                        defaultValue={rolOptions.find((i) => i.value === defaultRol?.value)}
                        onChange={form.mutators.setROL_ID}
                        options={rolOptions}
                        name="selectRol"
                        className=""
                      />
                    ) : (
                      <div className=" flex items-center justify-center">
                        <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
                      </div>
                    )}

                    <Field name="ROL_ID">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="hidden"
                          />
                          {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                        </>
                      )}
                    </Field>
                  </div>
                  {/* --- SELECT DE MARCAS --- */}
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => setModalAgregarBono(false)}>Cancelar</ButtonTable>
                  {/* disabled={submitting || pristine} */}
                  <ButtonTable type="submit">{editMode ? 'Actualizar' : 'Agregar'}</ButtonTable>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

//export default Projects;
export default ModalAgregarBono;

import React from 'react';
const TheadTableRoles = () => {
  return (
    <thead className="  sticky top-0 z-10   bg-gray-100 text-sm  uppercase  text-gray-700 ">
      <tr>
        <th className="px-1 text-left text-sm font-semibold uppercase">Id</th>
        <th className="px-1 text-left text-sm font-semibold uppercase">Nombre del rol</th>
        <th className="px-1 text-left font-semibold uppercase">Fecha Actualización</th>
        <th className="px-1 text-left font-semibold uppercase">Fecha Creación</th>
        <th className="px-1 text-left font-semibold uppercase">Usuarios</th>
      </tr>
    </thead>
  );
};
export default TheadTableRoles;

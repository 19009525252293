import { ExclamationCircleIcon, EnvelopeIcon, DocumentTextIcon, ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline';

import { ToolTipWrap } from './ToolTipWrap';
import { solicitudEstados } from './solicitudEstados';
export const ManifiestoIcon = ({ itemData, solicitudEstado }) => {
  //
  if (solicitudEstado < 4) return;

  if (itemData.SOLICITUD_VEHICULO_REPORTE_ESTADO === 0) return;

  if (itemData.SOLICITUD_VEHICULO_MANIFIESTO_ESTADO === 0)
    return (
      <ToolTipWrap
        itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
        componentPrefix={'tooltipManifiesto'}
        message={itemData.MANIFIESTO_ESTADO_DESCRIPCION}>
        <ExclamationCircleIcon
          data-tip
          data-for={`tooltipManifiesto${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
          className="hover:fill-blue-500 h-5 w-5 transform cursor-pointer  text-red-500 transition  duration-300 hover:scale-110"
        />
      </ToolTipWrap>
    );
  if (itemData.SOLICITUD_VEHICULO_MANIFIESTO_ESTADO === 1)
    return (
      <ToolTipWrap
        itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
        componentPrefix={'tooltipManifiesto'}
        message={itemData.MANIFIESTO_ESTADO_DESCRIPCION + ' - Consecutivo: ' + itemData.MANIFIESTO_CONSECUTIVO_NOTIFICA}>
        <EnvelopeIcon
          data-tip
          data-for={`tooltipManifiesto${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
          className="h-5 w-5 text-yellow-500"
        />
      </ToolTipWrap>
    );
  if (itemData.SOLICITUD_VEHICULO_MANIFIESTO_ESTADO === 2)
    return (
      <ToolTipWrap
        itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
        componentPrefix={'tooltipManifiesto'}
        message={
          'Consecutivo: ' +
          itemData.MANIFIESTO_CONSECUTIVO +
          ' Nro. Acept. Remesa: ' +
          itemData.MANIFIESTO_RNDC +
          ' Remesa: ' +
          itemData.MANIFIESTO_REMESA +
          ' Cant: ' +
          itemData.MANIFIESTO_CANTIDAD +
          ' Unidad: ' +
          itemData.MANIFIESTO_UNIDAD +
          ' Tipo Serv.: ' +
          itemData.MANIFIESTO_TIPO_SERVICIO
        }>
        <DocumentTextIcon
          data-tip
          data-for={`tooltipManifiesto${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
          className="h-5 w-5 text-green-500"
        />
      </ToolTipWrap>
    );

  return (
    <ToolTipWrap
      itemID={itemData.SOLICITUD_VEHICULO_TIPO_ID}
      componentPrefix={'tooltipManifiesto'}
      message={itemData.MANIFIESTO_ESTADO_DESCRIPCION}>
      <ArchiveBoxXMarkIcon
        data-tip
        data-for={`tooltipManifiesto${itemData.SOLICITUD_VEHICULO_TIPO_ID}`}
        className="h-5 w-5 text-green-500"
      />
    </ToolTipWrap>
  );
};

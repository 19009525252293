import React from 'react';
const TheadTableEjecucion = () => {
  return (
    <thead className="bg-gray-800 text-white">
      <tr>
        <th className="w-1/3 rounded-tl-lg px-4 py-3 text-left text-xs font-semibold uppercase ">Quien solicita</th>
        <th className="w-1/3 px-4 py-3 text-left text-xs font-semibold uppercase ">Tipo servicio</th>
        <th className="w-1/3 px-4 py-3 text-left text-xs font-semibold uppercase ">Origen</th>
        <th className="w-1/3 rounded-tr-lg px-4 py-3 text-left text-xs font-semibold uppercase ">Destino</th>
        <th className="w-1/3 rounded-tr-lg px-4 py-3 text-left text-xs font-semibold uppercase "></th>
      </tr>
    </thead>
  );
};
export default TheadTableEjecucion;

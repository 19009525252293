import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { setAsignarSelBonoInit } from '../../../../../actions';
import Select from 'react-select';
import { useEffect } from 'react';
import { store } from '../../../../../store';
import { ButtonTable } from '../../../../global/Styles/buttons';
import { inputsSettingsTrue, inputsSettingsFalse, inputTextAreaTrue, inputTextAreaFalse } from '../../../../global/Styles/inputs';
import { useApiClient } from '../../../../../clients/apiAuth';
import { ModalTitle } from '../../../../global/Styles/titles';
import Skeleton from 'react-loading-skeleton';
import FormStateToRedux from '../../../RFF/FormStateToRedux';

export const ModalNovedad = (props) => {
  const storeState = store.getState();

  const novedadTipoOptions = props.data.novedadTipoOptions;

  const editMode = storeState.AsignarBono.selAsignarBono === null ? false : true;

  const proxyTask = async (form) => {
    //

    form['TERCERO_ID'] = storeState.auth.data.user.TERCERO_ID;
    form['SOLICITUD_VEHICULO_TIPO_ID'] = props.data.selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID;
    form['ESTADO_ID'] = 1;

    if (editMode) {
      // updateAsignarBono(storeState.AsignarBono.selAsignarBono.SOLICITUD_BONO_ID, form);
    } else {
      props.endPoints.createNovedad(form);
    }
  };

  const validate = (values) => {
    const errors = {};

    const requiredFields = ['SOLICITUD_NOVEDAD_FECHA', 'NOVEDAD_TIPO_ID', 'SOLICITUD_NOVEDAD_OBSERVACION'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  const setNOVEDAD_TIPO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['NOVEDAD_TIPO_ID'];

    field.change(args[0].value);
  };

  const handleCloseModal = () => {
    props.modals.handleShowNovedadModal(false);
  };

  useEffect(() => {}, []);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-2xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>{editMode ? 'EDITAR' : 'NUEVA'} NOVEDAD</ModalTitle>
        <Form
          onSubmit={proxyTask}
          validate={editMode ? null : validate}
          initialValues={
            editMode
              ? { ...storeState.AsignarBono.selAsignarBono }
              : {
                  SOLICITUD_NOVEDAD_FECHA: new Date().toISOString().split('T')[0],
                }
          }
          mutators={{
            setNOVEDAD_TIPO_ID,
          }}
          subscription={{ submitting: true, pristine: true }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <FormStateToRedux form="frmAgregarServicioNovedad" />

              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <div className="flex space-x-4">
                    <div className="mt-1 w-1/2">
                      <Field name="SOLICITUD_NOVEDAD_FECHA">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Fecha</p>
                            <input
                              {...input}
                              type="date"
                              placeholder="Fecha"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 flex w-1/2 flex-col">
                      <p className="text-sm text-gray-800">Novedad</p>
                      <Field name="NOVEDAD_TIPO_ID">
                        {({ input, meta }) => (
                          <>
                            {novedadTipoOptions && (
                              <Select
                                options={novedadTipoOptions}
                                defaultValue={novedadTipoOptions[0]}
                                name="selectNovedadTipoOptions"
                                onChange={form.mutators.setNOVEDAD_TIPO_ID}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    marginTop: 6,
                                    borderColor: meta.error && meta.touched ? 'red' : null,
                                  }),
                                }}
                              />
                            )}
                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  <Field name="SOLICITUD_NOVEDAD_OBSERVACION">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Observación</p>
                        <textarea
                          {...input}
                          rows={6}
                          className={meta.error && meta.touched ? `${inputTextAreaFalse}` : `${inputTextAreaTrue} `}
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => handleCloseModal()}>Cancelar</ButtonTable>
                  {/* disabled={submitting || pristine} */}
                  <ButtonTable type="submit">{editMode ? 'Actualizar' : 'Agregar'}</ButtonTable>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { ButtonTable } from '../../../../global/Styles/buttons';

import { connect } from 'react-redux';

import { useApiClient, validateSuccess2xx } from '../../../../../clients/apiAuth';
import { CustomToast, OkToast } from '../../../Toast';

import { ModalTitle, TitlesTables } from '../../../../global/Styles/titles';
import toast from 'react-hot-toast';

import { TableVehiculos } from '../ModalSeguimiento/TableVehiculos';

import { PanelCotizacionTarifa } from '../ModalTarifa/PanelCotizacionTarifa';
import { TableCliente } from './TableCliente';
import { Form, Field } from 'react-final-form';
import { inputTextAreaFalse, inputTextAreaTrue } from '../../../../global/Styles/inputs';

const ModalAprobarCotizacion = (props) => {
  const { setIsModalAprobarVisible } = props;

  const { approveCotizacion, getSolicitudDetalle } = props.endPoint;
  const { handleChangeTarifaUnidad, createSolicitudDetalle, handleDeleteDetalle } = props.actions;

  const { auth, selCotizacion } = props.data;

  const [parametros, setParametros] = useState({});
  const [items, setItems] = useState(selCotizacion.documento_borrador_detalle || []);
  const [total, setTotal] = useState(selCotizacion.TOTAL_SOLICITUD);

  const { get } = useApiClient();

  const handleAddTarifa = (SOLICITUD_VEHICULO_TIPO_ID, TARIFA_ID, SOLICITUD_DETALLE_CANTIDAD) => {
    const frm = {
      SOLICITUD_VEHICULO_TIPO_ID: SOLICITUD_VEHICULO_TIPO_ID,
      TARIFA_ID: TARIFA_ID,
      SOLICITUD_DETALLE_CANTIDAD: SOLICITUD_DETALLE_CANTIDAD,
    };

    createSolicitudDetalle(frm);
  };

  const getParametros = async () => {
    const response = await get(`/parametro?MODE=COTIZACION`);
    //

    setParametros(response.data);
  };

  useEffect(() => {
    getParametros();
  }, []);

  const onTarifaTap = () => {
    getSolicitudDetalle(props.tipoVehiculo.selTipoVehiculo?.pivot?.SOLICITUD_VEHICULO_TIPO_ID);
    // setActiveTab('tarifa');
  };

  const handleAprobarCotizacion = async (DOCUMENTO_ID, ESTADO_ID) => {
    // console.log('DOCUMENTO_ID, ESTADO_ID: ', DOCUMENTO_ID, ESTADO_ID);
    approveCotizacion({ DOCUMENTO_ID, ESTADO_ID });
  };

  const clienteCotizacion = {
    TERCERO_DOCUMENTO: selCotizacion.CLIENTE_DOCUMENTO,
    TERCERO_NOMBRE_BUSQUEDA: selCotizacion.CLIENTE_NOMBRE,
    TERCERO_DIRECCION: selCotizacion.CLIENTE_DIRECCION,
    MUNICIPIO_DESCRIPCION: selCotizacion.CLIENTE_MUNICIPIO,
    TERCERO_TEL: selCotizacion.CLIENTE_TEL,
  };

  const cliente = {
    selCliente: clienteCotizacion,
  };

  console.log('selCotizacion: ', cliente, auth.data);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5  shadow-lg ">
        <TitlesTables>APROBAR COTIZACIÓN - CLIENTE</TitlesTables>
        <TableCliente data={cliente} />
        <hr />
        <TitlesTables>Parámetros</TitlesTables>

        <FormFields data={{ selCotizacion, items, setIsModalAprobarVisible }} />

        <PanelCotizacionTarifa
          handleAddTarifa={handleAddTarifa}
          handleChangeTarifaUnidad={handleChangeTarifaUnidad}
          handleDeleteDetalle={handleDeleteDetalle}
          data={{ items, setItems, total, setTotal, auth }}
          isReadOnly={true}
          setIsModalAprobarVisible={setIsModalAprobarVisible}
        />
        <div className="w-full space-x-4">
          <div className="md:block mx-16 mt-2 space-x-4 text-center">
            <ButtonTable
              onClick={() => {
                handleAprobarCotizacion(selCotizacion.DOCUMENTO_ID, 1);
              }}>
              Aprobar
            </ButtonTable>
            <ButtonTable
              onClick={() => {
                handleAprobarCotizacion(selCotizacion.DOCUMENTO_ID, 2);
              }}>
              Rechazar
            </ButtonTable>
            <ButtonTable
              onClick={() => {
                setIsModalAprobarVisible(false);
              }}>
              Cerrar
            </ButtonTable>
          </div>
        </div>
      </div>
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Requerido';
    }
  });

  console.log('validate errors: ', errors);
  return errors;
};

const getParametrosValues = (parametros) => {
  const p41 = parametros.find((p) => p.PARAMETRO_ID === 41);
  const p42 = parametros.find((p) => p.PARAMETRO_ID === 42);

  return { p41, p42 };
};

const FormFields = ({ data }) => {
  const items = data.items;
  const selCotizacion = data.selCotizacion;
  console.log('selCotizacion: ', selCotizacion);

  const handleCreateCotizacion = (form) => {
    console.log('form: ', form);
  };

  const editMode = false;
  return (
    <>
      <Form
        onSubmit={handleCreateCotizacion}
        validate={validate}
        initialValues={
          editMode
            ? {}
            : {
                COTIZACION_SALUDO: selCotizacion.COTIZACION_SALUDO,
                COTIZACION_NOTA: selCotizacion.COTIZACION_NOTA,
              }
        }
        mutators={{}}
        subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex space-x-4">
              <div className="mt-1 w-1/2">
                <Field name="COTIZACION_SALUDO">
                  {({ input, meta }) => (
                    <>
                      <p className="text-sm text-gray-800">Cordial Saludo</p>
                      <textarea
                        {...input}
                        readOnly
                        placeholder="Saludo..."
                        className={meta.error && meta.touched ? `${inputTextAreaFalse}` : `${inputTextAreaTrue}`}
                      />
                      {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                    </>
                  )}
                </Field>
              </div>
              <div className="mt-1 w-1/2">
                <Field name="COTIZACION_NOTA">
                  {({ input, meta }) => (
                    <>
                      <p className="text-sm text-gray-800">Nota</p>
                      <textarea
                        {...input}
                        readOnly
                        placeholder="Nota..."
                        className={meta.error && meta.touched ? `${inputTextAreaFalse}` : `${inputTextAreaTrue}`}
                      />
                      {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                    </>
                  )}
                </Field>
              </div>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};

export default ModalAprobarCotizacion;

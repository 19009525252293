import React from 'react';
const TheadTableUnidadNegocio = () => {
  return (
    <thead className="bg-gray-800 text-white">
      <tr>
        <th className="w-1/3 px-4 py-3 text-left text-sm font-semibold uppercase">Código</th>
        <th className="w-1/3 px-4 py-3 text-left text-sm font-semibold uppercase">Nombre</th>
        <th className="w-1/3 px-4 py-3 text-left text-sm font-semibold uppercase">Estado</th>
        <th className="px-4 py-3 text-left text-sm font-semibold uppercase">{''}</th>
      </tr>
    </thead>
  );
};
export default TheadTableUnidadNegocio;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ReactTooltip from 'react-tooltip';
import { ReactComponent as TextIcon } from '../../../../assets/svg/text.svg';

const TbodyTableEjecucion = (props) => {
  const row = props.Solicitud.selSolicitud;
  const rowTipoVehiculo = props.tipoVehiculo.selTipoVehiculo;
  return (
    <tbody className="rounded-lg text-gray-700">
      <tr key={row.SOLICITUD_ID}>
        <td className="w-1/3 px-4 py-3 text-left text-xs">{row?.SOLICITA}</td>
        <td className="w-1/3 px-4 py-3 text-left text-xs">
          {`${row?.SOLICITUD_IZAJE === 1 ? 'Izaje' : ''} ${row?.SOLICITUD_TRANSPORTE === 1 ? '- Transporte' : ''}`}
        </td>
        <td className="w-1/3 px-4 py-3 text-left text-xs">{row?.ORIGEN}</td>
        <td className="w-1/3 py-3 text-left  text-xs">{row?.DESTINO}</td>

        <td className="w-1/3 px-4 py-3 text-left">
          <div className="flex space-x-2">
            <TextIcon
              width={18}
              height={18}
              data-tip
              data-for={`tooltipDescripcion${row.SOLICITUD_ID}`}
              className="h-8 w-8 transform cursor-pointer  transition duration-300  hover:scale-110"></TextIcon>
            <ReactTooltip
              id={`tooltipDescripcion${row.SOLICITUD_ID}`}
              type="dark">
              <div style={{ width: 600, margin: 10, padding: 10 }}>
                <p>{row?.SOLICITUD_DESCRIPCION}</p>
              </div>
            </ReactTooltip>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

const mapStateToProps = function (state) {
  return {
    Solicitud: state.Solicitud,
    tipoVehiculo: state.tipoVehiculo,
  };
};

//export default Projects;
export default connect(mapStateToProps)(TbodyTableEjecucion);

import React from 'react';
import Nav from '../../../components/specific/Navigation';
import Filter from '../../../components/specific/Marca/Filter';
import { ModalAgregarMarca } from '../../../components/specific/Marca/Modales/agregarMarca';
import TableMarcas from '../../../components/specific/Marca/Table';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';

const ViewMarca = (props) => {
  const { modalAgregarMarca, setModalModalAgregarMarca, createMarca, validate } = props.modal;
  const { loadingTables } = props.actions;

  const { getRoles } = props;
  return (
    <div>
      <Nav />
      <Toaster />
      <div className="sm:px-6 lg:px-4 mx-6 py-12">
        <div>
          <label className="text-2xl font-semibold leading-tight">MARCAS</label>
        </div>
        <Filter setModalModalAgregarMarca={setModalModalAgregarMarca} />
        <div className="overflow-hidden rounded border-b border-gray-200 shadow">
          {loadingTables ? <LoadingTables /> : <TableMarcas actions={props.actions} />}
        </div>
      </div>
      {modalAgregarMarca ? (
        <ModalAgregarMarca
          endPoint={props.endPoint}
          agregarMarca={{ createMarca, validate }}
          setModalModalAgregarMarca={setModalModalAgregarMarca}
          getRoles={getRoles}
        />
      ) : null}
    </div>
  );
};

export default ViewMarca;

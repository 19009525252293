import React from 'react';
import TbodyTableBono from './tbody';
import TheadTableBono from './thead';

const TableBono = ({ actions, data }) => {
  return (
    <table className="min-w-full bg-white">
      <TheadTableBono />
      <TbodyTableBono
        actions={actions}
        data={data}
      />
    </table>
  );
};
export default TableBono;

import React, { useState } from 'react';
import { useApiClient } from '../../../../clients/apiAuth';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import FormStateToRedux from '../../RFF/FormStateToRedux';
import { connect } from 'react-redux';
import { store } from '../../../../store';
import { CustomToast, OkToast } from '../../Toast';
import Select from 'react-select';

import { inputsSettingsTrue, inputsSettingsFalse } from '../../../global/Styles/inputs';
import { ButtonTable } from '../../../global/Styles/buttons';
import TableEjecucion from './TableEjecucion';
import TableManifiesto from './TableManifiesto';
import TableSolicitudLogs from './TableSolicitudLogs';
import TableSolicitudLogsResumen from './TableSolicitudLogsResumen';
import Rating from 'react-rating';

const FormularioEvaluacion = ({ onSubmit, editMode, closeForm, onRatingChange, solicitud }) => {
  const storeState = store.getState();

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['p1', 'p2', 'p3', 'p4'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });

    return errors;
  };

  const setP1 = (args, state, { setIn, changeValue }) => {
    const field = state.fields['p1'];

    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setP2 = (args, state, { setIn, changeValue }) => {
    const field = state.fields['p2'];

    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setP3 = (args, state, { setIn, changeValue }) => {
    const field = state.fields['p3'];

    field.change(args[0]);
    state.formState.submitFailed = true;
  };
  const setP4 = (args, state, { setIn, changeValue }) => {
    const field = state.fields['p4'];

    field.change(args[0]);
    state.formState.submitFailed = true;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ p1: 1, p2: 2, p3: 3, p4: 4 }}
      validate={validate}
      subscription={{ submitting: true, pristine: true }}
      mutators={{ setP1, setP2, setP3, setP4 }}>
      {({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <FormStateToRedux form="frmEvaluacion" />
          <div className="">
            <div className="flex-auto justify-center p-5 text-left">
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <p className="mt-3  text-gray-800">Logística y Coordinación del Servicio</p>
                  <Rating
                    initialRating={solicitud?.p1}
                    onChange={form.mutators.setP1}
                    emptySymbol={<span className="icon-text px-1 text-2xl">-</span>}
                    fullSymbol={[1, 2, 3, 4, 5].map((n) => (
                      <span className="icon-text px-1 text-2xl">{n}</span>
                    ))}
                  />
                  <Field name="p1">
                    {({ input, meta }) => (
                      <>
                        <input
                          {...input}
                          type="hidden"
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                </div>
                <div className="w-1/2">
                  <p className="mt-3  text-gray-800">Tiempo de Respuesta</p>
                  <Rating
                    initialRating={solicitud?.p2}
                    onChange={form.mutators.setP2}
                    emptySymbol={<span className="icon-text px-1 text-2xl">-</span>}
                    fullSymbol={[1, 2, 3, 4, 5].map((n) => (
                      <span className="icon-text px-1 text-2xl">{n}</span>
                    ))}
                  />
                  <Field name="p2">
                    {({ input, meta }) => (
                      <>
                        <input
                          {...input}
                          type="hidden"
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="w-1/2 ">
                  <p className="mt-3  text-gray-800">Desempeño en HSEQ</p>
                  <Rating
                    initialRating={solicitud?.p3}
                    onChange={form.mutators.setP3}
                    emptySymbol={<span className="icon-text px-1 text-2xl">-</span>}
                    fullSymbol={[1, 2, 3, 4, 5].map((n) => (
                      <span className="icon-text px-1 text-2xl">{n}</span>
                    ))}
                  />
                  <Field name="p3">
                    {({ input, meta }) => (
                      <>
                        <input
                          {...input}
                          type="hidden"
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                </div>
                <div className="w-1/2">
                  <p className="mt-3  text-gray-800">Calidad del Servicio</p>
                  <Rating
                    initialRating={solicitud?.p4}
                    onChange={form.mutators.setP4}
                    emptySymbol={<span className="icon-text px-1 text-2xl">-</span>}
                    fullSymbol={[1, 2, 3, 4, 5].map((n) => (
                      <span className="icon-text px-1 text-2xl">{n}</span>
                    ))}
                  />
                  <Field name="p4">
                    {({ input, meta }) => (
                      <>
                        <input
                          {...input}
                          type="hidden"
                        />
                        {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                      </>
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className=" md:block mt-2 space-x-4 text-center">
              {/* <ButtonTable type="button" onClick={() => setNuevoServicio(false)}>
                Cancelar
              </ButtonTable> */}

              <ButtonTable onClick={closeForm}>Cerrar</ButtonTable>
              {solicitud.EVAL_AT === null && (
                <ButtonTable
                  type="submit"
                  disabled={submitting || pristine}>
                  Enviar
                </ButtonTable>
              )}
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export const ModalEvaluacion = (props) => {
  const { setIsModalEvaluacionVisible } = props;

  const { postEvaluacion } = props.endPoint;

  const editMode = props.Solicitud.selSolicitud.EVAL_AT !== null;

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5  shadow-lg ">
        <TableEjecucion />

        <FormularioEvaluacion
          onRatingChange={(e) => {}}
          onSubmit={postEvaluacion}
          closeForm={() => setIsModalEvaluacionVisible(false)}
          solicitud={props.Solicitud.selSolicitud}
        />
        {editMode && <div className="bg-white px-2 pt-2 text-green-500 ">Evaluacion realizada : {props.Solicitud.selSolicitud.EVAL_AT}</div>}
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return { roles: state.roles, tipoVehiculo: state.tipoVehiculo, Solicitud: state.Solicitud };
};

export default connect(mapStateToProps)(ModalEvaluacion);

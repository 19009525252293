const initialState = {
  isData: false,
  UnidadNegocio: [],
  selUnidadNegocio: null,
  pagination: null,
};

const initialUnidadNegocioState = {
  selUnidadNegocio: null,
};

export const UnidadNegocio = (state = initialState, action) => {
  switch (action.type) {
    case 'setUnidadNegocio': {
      if (action.value.status === 200) {
        const stateData = { UnidadNegocio: action.value.data, isData: true, pagination: action.value.pagination };
        return { ...state, ...stateData };
      } else {
        return initialState;
      }
    }
    case 'setUnidadNegocioInit': {
      return initialState;
    }
    case 'setSelUnidadNegocio': {
      const obj = {
        ...action.value,
      };

      const stateData = { selUnidadNegocio: obj };

      return { ...state, ...stateData };
    }
    case 'setSelUnidadNegocioInit': {
      return { ...state, ...initialUnidadNegocioState };
    }

    default:
      return state;
  }
};

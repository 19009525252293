import React from 'react';
import TbodyTableMarcas from './tbody';
import TheadTableMarcas from './thead';
const TableMarcas = (props) => {
  return (
    <table className="min-w-full bg-white">
      <TheadTableMarcas />
      <TbodyTableMarcas actions={props.actions} />
    </table>
  );
};
export default TableMarcas;

import { TableTarifaTbody } from './TableTarifaTbody';
import { TableTarifaThead } from './TableTarifaThead';
import { TableTarifaTfoot } from './TableTarifaTfoot';

export const TableTarifa = (props) => {
  const { isReadOnly } = props;

  return (
    <table className="w-full table-auto text-sm">
      <TableTarifaThead
        isReadOnly={isReadOnly}
        modals={props.modals}
        actions={props.actions}
      />
      <TableTarifaTbody
        isReadOnly={isReadOnly}
        data={props.data.solicitudDetalle}
        actions={props.actions}
      />
      <TableTarifaTfoot data={props.data.solicitudDetalle} />
    </table>
  );
};

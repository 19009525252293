import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ViewTarifa from './ViewTarifa';
import { useApiClient } from '../../clients/apiAuth';
import { connect } from 'react-redux';
import { store } from '../../store';
import { hideLoadingAnimation, setContrato, setSelTarifa, setTarifa, showLoadingAnimation } from '../../actions';
import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';
import toast from 'react-hot-toast';

const Tarifa = (props) => {
  const auth = props.auth;
  const contrato = props.contrato;
  const { get, post, put, DELETE } = useApiClient();
  const [modalAgregarTarifa, setModalModalAgregarTarifa] = useState(false);
  const [loadingTables, setLoadingTables] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [listaError, setListaError] = useState(false);
  const [modalEliminarTarifa, setModalEliminarTarifa] = useState(false);

  const [filterTipoVehiculo, setFilterTipoVehiculo] = useState('');
  const [filterUnidad, setFilterUnidad] = useState('');
  const [filterContrato, setFilterContrato] = useState('');
  const [tipovehiculo, setTipoVehiculo] = useState([]);

  const getTipoVehiculo = async () => {
    const response = await get(`/vehiculoTipo`);

    const lst = response.data.map((row) => ({
      label: `${row.VEHICULO_TIPO_DESCRIPCION}`,
      value: row.VEHICULO_TIPO_ID,
    }));

    setTipoVehiculo(lst);

    //CAMBIA EL FILTRO.
    setFilterTipoVehiculo(lst[0].value);
  };

  const [tarifaUnidad, setTarifaUnidad] = useState([]);

  const getFiltro = () => {
    const lstFiltros = [];

    if (filterUnidad.length !== 0) {
      lstFiltros.push(`TARIFA_UNIDAD=${filterUnidad}`);
    }
    if (filterTipoVehiculo.length !== 0) {
      lstFiltros.push(`VEHICULO_TIPO_ID=${filterTipoVehiculo}`);
    }
    if (filterContrato.length !== 0) {
      lstFiltros.push(`CONTRATO_ID=${filterContrato}`);
    }

    const firstElement = '?' + lstFiltros[0];
    if (lstFiltros.length === 1) {
      return firstElement;
    }

    lstFiltros.shift();

    if (lstFiltros.length === 1) {
      const restElements = '&' + lstFiltros[0];
      return firstElement.concat(restElements);
    }

    const restElements = lstFiltros.join('&');
    return firstElement + '&' + restElements;
  };

  const getTarifaUnidad = async () => {
    const response = await get(`/tarifaUnidad`);

    const lst = response.data.map((row) => ({
      label: `${row.TARIFA_UNIDAD}`,
      value: row.TARIFA_UNIDAD,
    }));

    if (lst.length > 0) {
      setTarifaUnidad(lst);

      //CAMBIA EL FILTRO.
      setFilterUnidad(lst[0].value);
      return;
    }

    const noItems = [
      {
        label: 'Sin Datos',
        value: '',
      },
    ];
    setTarifaUnidad(noItems);
  };

  const getTarifa = async () => {
    if (filterTipoVehiculo === '' || filterUnidad === '' || filterContrato === '') {
      return;
    }

    const filtro = getFiltro();
    setLoadingTables(true);
    const result = await get(`/tarifa${filtro}`);
    store.dispatch(setTarifa(result));

    setLoadingTables(false);
  };

  const getTarifaTodas = async () => {
    setLoadingTables(true);
    const result = await get(`/tarifa?CONTRATO_ID=${filterContrato}`);
    store.dispatch(setTarifa(result));
    setLoadingTables(false);
  };

  const deleteTarifaContrato = async (contratoId) => {
    try {
      const result = await DELETE(`/borrarTarifas`, { CONTRATO_ID: contratoId });
      OkToast({ result, message: result.message });
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }
  };

  const createTarifa = async (form) => {
    const newForm = {
      ...form,
      USUARIO_ID: auth.data.user.TERCERO_ID,
    };
    try {
      const result = await post(
        '/tarifa',
        {
          json: JSON.stringify(newForm),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalModalAgregarTarifa(false);
        OkToast({ result, message: 'Tarifa Agregada!' });
        getTarifa();
      }
    } catch (error) {}
  };
  const updateTarifa = async (form) => {
    const newForm = {
      ...form,
      USUARIO_ID: auth.data.user.TERCERO_ID,
    };

    try {
      const result = await put(
        `/tarifa/${form.TARIFA_ID}`,
        {
          json: JSON.stringify(newForm),
        },
        'application/json'
      );

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        setModalModalAgregarTarifa(false);
        OkToast({ result, message: 'Tarifa Actualizada!' });
        getTarifa();
      }
    } catch (error) {}
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = ['TARIFA_CONSECUTIVO', 'TARIFA_CATEGORIA', 'TARIFA_GRUPO', 'TARIFA_UNIDAD', 'TARIFA_ITEM', 'TARIFA_NOMBRE', 'TARIFA_VALOR'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });
    return errors;
  };

  function handleDelete(rowID) {
    //const projectsResult = (`/projects/${projectID}/task-managements/${taskManagementID}/tasks/${rowID}`);
    //

    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar la tarifa?</b>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => {
            toast.dismiss(t.id);
            deleteTarifa(rowID);
          }}>
          Si
        </button>
        <button
          className="ml-2 rounded-full  border px-4 hover:bg-gray-200 "
          onClick={() => toast.dismiss(t.id)}>
          No
        </button>
      </span>
    ));
  }

  const deleteTarifa = async (rowID) => {
    store.dispatch(showLoadingAnimation());

    try {
      const result = await DELETE(`/tarifa/${rowID}`);

      if (String(result.status).substr(0, 1) !== '2') {
        //TODO MENOS LAS RESPUESTAS BUENAS.
        CustomToast({ result });
      } else {
        OkToast({ result, message: 'Tarifa eliminada!' });

        getTarifa();
      }
    } catch (error) {
      ErrorToast({ title: 'Error', message: error });
    }

    store.dispatch(hideLoadingAnimation());
  };

  function handleEdit(obj) {
    store.dispatch(setSelTarifa(obj));

    setModalModalAgregarTarifa(true);
  }

  const getContrato = async () => {
    try {
      const response = await get(`/contrato?TERCERO_ID=${auth.data.selCliente.TERCERO_ID}`);
      store.dispatch(setContrato(response));
      setFilterContrato(response.data[0]['CONTRATO_ID']);
    } catch (error) {}
  };

  useEffect(() => {
    getTarifa();
  }, [filterTipoVehiculo, filterUnidad, filterContrato]);

  return (
    <ViewTarifa
      endPoint={{ getTarifa, createTarifa, updateTarifa, getTipoVehiculo, getTarifaUnidad, getContrato, deleteTarifaContrato }}
      data={{
        tipovehiculo,
        setTipoVehiculo,
        tarifaUnidad,
        setTarifaUnidad,
        filterTipoVehiculo,
        setFilterTipoVehiculo,
        filterUnidad,
        setFilterUnidad,
        auth,
        contrato,
        filterContrato,
        setFilterContrato,
      }}
      modal={{
        modalAgregarTarifa,
        setModalModalAgregarTarifa,
        validate,
        uploadFile,
        setUploadFile,
        listaError,
        setListaError,
        modalEliminarTarifa,
        setModalEliminarTarifa,
      }}
      actions={{ loadingTables, handleEdit, handleDelete, getTarifaTodas }}
    />
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    tarifa: state.tarifa,
    contrato: state.contrato,
  };
};

//export default Projects;
export default connect(mapStateToProps)(Tarifa);

import React, { useState, useEffect } from 'react';

import ViewZona from './ViewZona';

import { connect } from 'react-redux';
import { store } from '../../store';
import { setSelZona, setSelZonaInit, setZona } from '../../actions';

import toast from 'react-hot-toast';
import { useZones } from '../../modules/Zona/application/useZones';

const Zona = (props) => {
  const auth = props.auth;

  const [isModalAgregarZonaVisible, setIsModalAgregarZonaVisible] = useState(false);

  const [filters, setFilters] = useState('');

  const updateStore = (data) => {
    store.dispatch(setZona(data));
  };

  function handleEdit(obj) {
    store.dispatch(setSelZona(obj));
    setIsModalAgregarZonaVisible(true);
  }

  const deleteZona = async (rowID) => {
    removeZone(rowID);
  };

  function handleDelete(rowID) {
    toast((t) => (
      <span className="flex gap-2">
        <b>Seguro quiere eliminar zona?</b>
        <button
          className="rounded-full border px-2 hover:bg-gray-300 hover:text-gray-500 "
          onClick={() => {
            toast.dismiss(t.id);
            deleteZona(rowID);
          }}>
          Borrar
        </button>
        <button
          className="rounded-full border px-2 hover:bg-gray-300 hover:text-gray-500"
          onClick={() => toast.dismiss(t.id)}>
          Cancelar
        </button>
      </span>
    ));
  }

  const handleShowAddNewModal = (show = true) => {
    store.dispatch(setSelZonaInit());
    setIsModalAgregarZonaVisible(show);
  };

  const { data: zonesData, isLoading: loadingTables, reload, remove: removeZone } = useZones({ filters });

  useEffect(() => {
    updateStore(zonesData);
  }, [zonesData]);

  return (
    <ViewZona
      modal={{ isModalAgregarZonaVisible }}
      actions={{ handleEdit, handleDelete, reload, handleShowAddNewModal }}
      data={{ setFilters, auth, zona: props.zona, loadingTables }}
    />
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    zona: state.zona,
  };
};

export default connect(mapStateToProps)(Zona);

import React, { useState, useEffect } from 'react';

import { useApiClient, validateSuccess2xx } from '../../../../../clients/apiAuth';
import { CustomToast } from '../../../Toast';
import { TableVehiculoAnticipo } from './TableVehiculoAnticipo';
import 'react-loading-skeleton/dist/skeleton.css';

export const PanelVehiculoAnticipos = ({ handleAddTarifa, handleShowSiesaLog, handleChangeTarifaUnidad, handleDeleteDetalle, data, endPoint }) => {
  // const [isModalTarifaVisible, setIsModalTarifaVisible] = useState(false);

  const [panelRows, setPanelRows] = useState([]);

  const { get } = useApiClient();

  // const handleShowTarifaModal = (isVisible = true) => {
  //   setIsModalTarifaVisible(isVisible);
  // };

  const getVehiculoAnticipos = async () => {
    const result = await get(`/solicitudAnticipo?SOLICITUD_VEHICULO_TIPO_ID=${data.SOLICITUD_VEHICULO_TIPO_ID}`);

    if (!validateSuccess2xx(result)) {
      setPanelRows([]);
      // return CustomToast({ result });
      return;
    }
    setPanelRows(result.data);
  };

  const refrestVehiculoAnticipos = () => {
    getVehiculoAnticipos();
  };

  useEffect(() => {
    getVehiculoAnticipos();
  }, [data.SOLICITUD_VEHICULO_TIPO_ID]);

  return (
    <>
      <TableVehiculoAnticipo
        data={panelRows}
        endPoint={endPoint}
        // modals={{ handleShowTarifaModal }}
        actions={{ handleAddTarifa, handleShowSiesaLog, handleChangeTarifaUnidad, handleDeleteDetalle, refrestVehiculoAnticipos }}></TableVehiculoAnticipo>
    </>
  );
};

import ReactTooltip from 'react-tooltip';

export const ToolTipWrap = ({ itemID, componentPrefix, message, children }) => {
  return (
    <>
      <ReactTooltip
        className="margin-10"
        id={`${componentPrefix}${itemID}`}
        type="dark"
        effect="float">
        <p>{message}</p>
      </ReactTooltip>
      {children}
    </>
  );
};

import React from 'react';
import Nav from '../../../components/specific/Navigation';

import TableCliente from '../../../components/specific/Cliente/Table';
import { ModalAgregarCliente } from '../../../components/specific/Cliente/Modal/agregarCliente';
import { ModalAgregarUsuarios } from '../../../components/specific/Cliente/Modal/agregarUsuarios';
import { Toaster } from 'react-hot-toast';
import LoadingTables from '../../../components/global/LoadingTables';
import { TitlesTables } from '../../../components/global/Styles/titles';

const ViewCliente = (props) => {
  const { modalAgregarCliente, modalAgregarUsuarios } = props.modal;
  const { isLoadingTables } = props.actions;
  return (
    <div className="flex max-h-screen min-h-screen flex-col overflow-hidden ">
      <Nav />
      <Toaster
        toastOptions={{
          className: 'w-1/2',
        }}
      />

      <div className="flex flex-grow flex-col overflow-hidden ">
        <div className="m-2 flex flex-grow flex-col overflow-hidden   ">
          <div className="flex ">
            <TitlesTables>PARAMETROS GENERALES - CLIENTES</TitlesTables>
            <div className="flex-auto"></div>
          </div>

          {isLoadingTables && <LoadingTables />}
          <TableCliente
            actions={props.actions}
            data={props.data}
          />
        </div>
      </div>

      {modalAgregarCliente && (
        <ModalAgregarCliente
          modal={props.modal}
          actions={props.actions}
          endPoint={props.endPoint}
        />
      )}
      {modalAgregarUsuarios && (
        <ModalAgregarUsuarios
          modal={props.modal}
          actions={props.actions}
          endPoint={props.endPoint}
          data={props.data}
        />
      )}
    </div>
  );
};

export default ViewCliente;

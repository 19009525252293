import React from 'react';
import TbodyTableRegional from './tbody';
import TheadTableRegional from './thead';
const TableRegional = (props) => {
  return (
    <table className="min-w-full bg-white">
      <TheadTableRegional />
      <TbodyTableRegional actions={props.actions} />
    </table>
  );
};
export default TableRegional;

import React from 'react';
import TbodyTableTarifas from './tbody';
import TheadTableTarifas from './thead';
const TableTarifas = (props) => {
  const { loadingTables } = props.data;
  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="w-full table-auto text-sm">
          <TheadTableTarifas />
          <TbodyTableTarifas actions={props.actions} />
        </table>
      )}
    </div>
  );
};
export default TableTarifas;

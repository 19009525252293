import { PlusCircleIcon } from '@heroicons/react/24/outline';

export const TableNovedadThead = (props) => {
  return (
    <thead className="bg-gray-100 text-sm uppercase text-gray-700">
      <tr>
        <th
          scope="col"
          className=" whitespace-nowrap text-left ">
          Novedad
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap text-left">
          Descripción
        </th>
        <th
          scope="col"
          className="whitespace-nowrap text-left">
          Fecha
        </th>

        <th
          scope="col"
          className="w-6 cursor-pointer hover:text-green-600">
          <PlusCircleIcon
            width={18}
            height={18}
            onClick={() => props.modals.handleShowNovedadModal()}
          />
        </th>
      </tr>
    </thead>
  );
};
